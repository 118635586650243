import React, { useState } from 'react';
import './AccountType.css';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { useAuth } from '../contexts/AuthProvider'; // Ensure the correct path

const AccountType = () => {
  const navigate = useNavigate();
  const { profile } = useAuth(); // ✅ Get profile from AuthContext
  const [showInfoBox, setShowInfoBox] = useState(false);

  // Handle Personal Plan (Standard) Upgrade
  const handleUpgradeStandard = async () => {
    try {
      console.log('Attempting to handle Standard User upgrade...');

      // 1. Check if we have an accountType in the profile
      if (!profile?.accountType) {
        console.error('No account type found in profile');
        alert('Only Free users can upgrade to Personal Plan. Please sign in or create a free user account.');
        return;
      }

      // 2. Only "FreeUser" can upgrade
      if (profile.accountType !== 'FreeUser') {
        console.error('Only Free users can upgrade to Personal Plan');
        alert('Only Free users can upgrade to Personal Plan. Please create a Free User account to upgrade.');
        return;
      }

      // 3. Get current Firebase user for the ID token
      const currentUser = getAuth().currentUser;
      if (!currentUser) {
        console.error('User is not authenticated');
        alert('Please sign in again or create a Free User account to upgrade.');
        return;
      }

      // 4. Force token refresh
      const idToken = await currentUser.getIdToken(true);
      console.log('User ID Token:', idToken);

      // 5. Call HTTPS function to create a Stripe Checkout session
      console.log('Calling HTTPS function to create a Stripe Checkout session...');
      const response = await fetch('https://createcheckoutsession-rhi2cwsv5a-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`, // Use backticks for string interpolation
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        console.error('HTTP error! Status:', response.status);
        alert('Failed to create checkout session. Please try again later.');
        return;
      }

      const responseData = await response.json();
      if (responseData && responseData.url) {
        console.log('Redirecting to Stripe Checkout:', responseData.url);
        window.location.href = responseData.url; // Redirect user to the Stripe Checkout page
      } else {
        console.error('Failed to create a Stripe checkout session.');
        alert('Could not initiate checkout. Please contact support.');
      }
    } catch (error) {
      console.error('Error upgrading to standard account:', error);
      alert('An unexpected error occurred. Please try again later.');
    }
  };

  // Handle Team Plan (Manager) Upgrade
  const handleUpgradeManager = async () => {
    try {
      console.log('Attempting to handle Manager upgrade...');

      // 1. Check if we have an accountType in the profile
      if (!profile?.accountType) {
        console.error('No account type found in profile');
        alert('Only Free users can upgrade to a Team Plan. Please sign in or create a free user account.');
        return;
      }

      // 2. Only "FreeUser" can upgrade
      if (profile.accountType !== 'FreeUser') {
        console.error('Only Free users can upgrade to Manager');
        alert('Only Free users can upgrade to a Team Plan. Please create a Free User account to upgrade.');
        return;
      }

      // 3. Get current Firebase user for the ID token
      const currentUser = getAuth().currentUser;
      if (!currentUser) {
        console.error('User is not authenticated');
        alert('Please sign in again or create a Free User account to upgrade.');
        return;
      }

      // 4. Force token refresh
      const idToken = await currentUser.getIdToken(true);
      console.log('User ID Token:', idToken);

      // 5. Call HTTPS function to create a Stripe Checkout session for manager account
      console.log('Calling HTTPS function to create a Manager Stripe Checkout session...');
      const response = await fetch('https://createmanagercheckoutsession-rhi2cwsv5a-uc.a.run.app', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`, // Use backticks for string interpolation
        },
        body: JSON.stringify({}),
      });

      if (!response.ok) {
        console.error('HTTP error! Status:', response.status);
        alert('Failed to create checkout session. Please try again later.');
        return;
      }

      const responseData = await response.json();
      if (responseData && responseData.url) {
        console.log('Redirecting to Stripe Checkout:', responseData.url);
        window.location.href = responseData.url; // Redirect user to the Stripe Checkout page
      } else {
        console.error('Failed to create a Stripe checkout session.');
        alert('Could not initiate checkout. Please contact support.');
      }
    } catch (error) {
      console.error('Error upgrading to manager account:', error);
      alert('An unexpected error occurred. Please try again later.');
    }
  };

  // Render
  return (
    <div className="account-type-container">
      <h1 className="account-type-title">Choose the right plan for You</h1>

      <div className="account-options">
        {/* Personal Plan */}
        <div className="account-box">
          <h2 className="account-box-title">Personal Plan</h2>
          <p className="account-box-subtitle">For individual users</p>
          <h3 className="account-price">One Year Access £69.99</h3>
          <p className="account-subtext">One-off payment.</p>
          <button className="account-button" onClick={handleUpgradeStandard}>
            Upgrade →
          </button>
          <ul className="account-benefits-list">
            <li>Access to All Courses</li>
            <li>Track Progress</li>
            <li>Link Account to Manager (Optional)</li>
            <li>Certification on Completion</li>
            <li>Access for One Year</li>
          </ul>
        </div>

        {/* Team Plan */}
        <div className="account-box" style={{ position: 'relative' }}>
          <h2 className="account-box-title">Team Plan</h2>
          <p className="account-box-subtitle">For Managers</p>
          <h3 className="account-price">One Year Access £89.99</h3>
          <p className="account-subtext">Billed Annually.</p>
          <button className="account-button" onClick={handleUpgradeManager}>
            Upgrade →
          </button>
          <ul className="account-benefits-list">
            <li>Access to All Courses</li>
            <li>Track Progress</li>
            <li>Link to Employee Accounts</li>
            <li>Certification on Completion</li>
            <li>Track Employee Progress</li>
            <li>Receive Threat Reports from Employees</li>
            <li>Threat Reports Compiled into Useful Statistics</li>
            <li>Add Company Logo for Increased Personalisation</li>
          </ul>
        </div>
      </div>
    </div>
    
  );
};

export default AccountType;
