import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, addDoc, serverTimestamp, getDoc, doc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthProvider';
import { firestore } from '../../firebase';
import DOMPurify from 'dompurify';
import './AddReport.css';

const MAX_TITLE_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 250;

const AddReport = () => {
  const { currentUser } = useAuth();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [threatType, setThreatType] = useState('Phishing');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [titleWarning, setTitleWarning] = useState('');
  const [descriptionWarning, setDescriptionWarning] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (location.hash === '#ReportAThreatHeading') {
      const scrollTimeout = setTimeout(() => {
        const element = document.querySelector('h1');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);
      return () => clearTimeout(scrollTimeout);
    }
  }, [location.hash]);

  const handleTitleChange = (e) => {
    const inputValue = e.target.value;
    setTitle(inputValue);
    setTitleWarning(
      inputValue.length > MAX_TITLE_LENGTH ? `Title cannot exceed ${MAX_TITLE_LENGTH} characters.` : ''
    );
  };

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    setDescription(inputValue);
    setDescriptionWarning(
      inputValue.length > MAX_DESCRIPTION_LENGTH ? `Description cannot exceed ${MAX_DESCRIPTION_LENGTH} characters.` : ''
    );
  };

  const createReport = async (title, description, threatType) => {
    if (!currentUser) {
      setError('You must be logged in to report a threat.');
      return;
    }

    try {
      const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
      const userProfile = userDoc.data();
      let managerId = currentUser.uid;

      if (userProfile && userProfile.profile.accountType === 'standard') {
        managerId = userProfile.profile.managerId;
      }

      const sanitizedTitle = DOMPurify.sanitize(title);
      const sanitizedDescription = DOMPurify.sanitize(description);

      const reportRef = collection(firestore, 'threatReports');
      await addDoc(reportRef, {
        reportedBy: currentUser.uid,
        reportedByEmail: currentUser.email,
        managerId: managerId,
        title: sanitizedTitle,
        description: sanitizedDescription,
        threatType,
        timestamp: serverTimestamp(),
        comments: [],
      });

      setSuccess('Report successfully submitted.');
      setTitle('');
      setDescription('');
      setThreatType('Phishing');
    } catch (err) {
      setError('Failed to submit the report. Please try again.');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (title.length > MAX_TITLE_LENGTH || description.length > MAX_DESCRIPTION_LENGTH) {
      setError('Please ensure the title and description meet the character limits.');
      return;
    }
    setError(null);
    setSuccess(null);
    createReport(title, description, threatType);
  };

  return (
    <div className="add-report-container">
      <h1>Report a Threat</h1>
      <form onSubmit={handleSubmit} className="add-report-form">
        <div className="form-group">
        <label htmlFor="report-title" className="report-label">
  <strong>Title (max {MAX_TITLE_LENGTH} characters)</strong>
</label>

          <input
            type="text"
            id="report-title"
            value={title}
            onChange={handleTitleChange}
            maxLength={MAX_TITLE_LENGTH}
            required
            aria-describedby={titleWarning ? 'title-warning' : undefined}
          />
          {titleWarning && <p id="title-warning" className="warning-text2" role="alert">{titleWarning}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="report-description" className="report-label"><strong>Description (max {MAX_DESCRIPTION_LENGTH} characters)</strong></label>
          <textarea
            id="report-description"
            value={description}
            onChange={handleDescriptionChange}
            maxLength={MAX_DESCRIPTION_LENGTH}
            required
            aria-describedby={descriptionWarning ? 'description-warning' : undefined}
          />
          {descriptionWarning && <p id="description-warning" className="warning-text2" role="alert">{descriptionWarning}</p>}
        </div>
        <div className="form-group">
          <label htmlFor="threat-type" className="report-label"><strong>Threat Type</strong></label>
          <select
            id="threat-type"
            value={threatType}
            onChange={(e) => setThreatType(e.target.value)}
            required
            aria-label="Select the type of threat"
          >
            <option value="Phishing">Phishing</option>
            <option value="Spear Phishing">Spear Phishing</option>
            <option value="Malware">Malware</option>
            <option value="Ransomware">Ransomware</option>
            <option value="Smishing">Smishing</option>
            <option value="Vishing">Vishing</option>
            <option value="Social Engineering">Social Engineering</option>
            <option value="Insider Threat">Insider Threat</option>
            <option value="Other">Other</option>
          </select>
        </div>
        
        {error && <p className="error-text" role="alert" aria-live="assertive">{error}</p>}
        {success && <p className="success-text" role="status" aria-live="polite">{success}</p>}

        <button type="submit" className="submit-button22" aria-label="Submit threat report">
          Submit
        </button>
      </form>
    </div>
  );
};

export default AddReport;
