import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, sendEmailVerification, signOut } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../firebase';

const VerificationNotice = () => {
  const navigate = useNavigate();
  const auth = getAuth();
  const [message, setMessage] = useState('');
  const [isResent, setIsResent] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  // Check email verification status on load
  useEffect(() => {
    const checkVerificationStatus = async () => {
      try {
        if (auth.currentUser) {
          console.log("🔄 Reloading user...");
          await auth.currentUser.reload(); // Ensure latest auth state
          console.log("✅ Reloaded user:", auth.currentUser.emailVerified);
  
          if (auth.currentUser.emailVerified) {
            console.log("✅ User's email is verified! Updating Firestore...");
            
            // ✅ Step 1: Update Firestore **before** signing out
            const userDocRef = doc(firestore, 'users', auth.currentUser.uid);
            await updateDoc(userDocRef, { isEmailVerified: true });
  
            console.log("✅ Firestore successfully updated!");
  
            // ✅ Step 2: Only sign out after update completes
            console.log("🔄 Signing out to refresh session...");
            await signOut(auth);
  
            // ✅ Step 3: Redirect after delay
            setTimeout(() => navigate('/login'), 2000);
          } else {
            console.log("❌ User is still not verified.");
            setMessage('Please verify your email to proceed.');
          }
        }
      } catch (error) {
        console.error("❌ Error checking verification status:", error);
        setMessage('An error occurred while verifying your email.');
      }
    };
  
    checkVerificationStatus();
  }, [auth, navigate]);
  
  
  const handleResendEmail = async () => {
    if (auth.currentUser && !auth.currentUser.emailVerified) {
      try {
        await sendEmailVerification(auth.currentUser);
        setMessage('📧 A new verification email has been sent! Please check your inbox.');
        setIsResent(true);
      } catch (error) {
        console.error('Error resending verification email:', error);
        setMessage('❌ Could not resend verification email. Try again later.');
      }
    }
  };

  return (
    <main className="verification-container2" role="main">
      <div className="verification-box">
        <h1 tabIndex="0">Email verification sent. Please check your inbox </h1>
        <p role="alert" aria-live="assertive">
          {message}
        </p>

        {!isVerified && (
          <>
            <button
              onClick={() => navigate('/login')}
              className="verification-button"
              aria-label="Go to login page"
            >
              Go to Login
            </button>

            {!isResent && (
              <button
                onClick={handleResendEmail}
                className="resend-button"
                aria-label="Resend verification email"
              >
                Resend Verification Email
              </button>
            )}
          </>
        )}
      </div>
    </main>
  );
};

export default VerificationNotice;
