import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './Profile.css';
import Footer from '../Footer/Footer';
import { v4 as uuidv4 } from 'uuid'; // Import UUID for generating secure reference numbers
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization
import confetti from 'canvas-confetti'; // Import confetti library
import { Timestamp } from "firebase/firestore";

const Profile = () => {
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [authChecked, setAuthChecked] = useState(false);
  const [showCertificateButton, setShowCertificateButton] = useState(false);
  const [certificateGenerated, setCertificateGenerated] = useState(false);
  const [linkedToManager, setLinkedToManager] = useState(false); // State to check if user is linked to a manager
  const [accountType, setAccountType] = useState(''); // State to hold the account type
  const [downgradeDate, setDowngradeDate] = useState(''); // State to hold the downgrade date
  const certificateRef = useRef();
  const auth = getAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Sanitize input by escaping special characters
  const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input);
  };

  const fetchUserData = async (user) => {
    try {
      const docRef = doc(firestore, 'users', user.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const userData = docSnap.data();
        setUserData(userData);

        // Check if the user is linked to a manager
        if (userData.profile?.managerId) {
          setLinkedToManager(true); // User is linked to a manager
        }

        // Set the account type (e.g., 'standard', 'manager', 'admin')
        setAccountType(userData.profile?.accountType || '');

        console.log('Account Type: ', userData.profile?.accountType);

        // Set the downgrade date if the user is a standard user
        if (userData.profile?.accountType === 'standard' && userData.profile?.paymentDate) {
          const paymentDate = new Date(userData.profile.paymentDate);
          const downgradeDate = new Date(paymentDate);
          downgradeDate.setFullYear(downgradeDate.getFullYear() + 1);
          setDowngradeDate(downgradeDate.toLocaleDateString());
        }

        // Check if the user already has a certificate
        if (userData.profile?.certificateReference) {
          setCertificateGenerated(true); // Prevent certificate regeneration
          setShowCertificateButton(true); // Still allow download access
        }
      } else {
        console.warn('User document does not exist in Firestore.');
      }
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };

  useEffect(() => {
    const initializeAuth = async () => {
      try {
        await setPersistence(auth, browserLocalPersistence);
      } catch (error) {
        console.error('Error setting persistence:', error.message);
      }
    };

    initializeAuth();

    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log('User detected:', user);
        await fetchUserData(user);

        // Verify payment session if `session_id` exists
        const sessionId = searchParams.get('session_id');
        if (sessionId) {
          console.log('Verifying payment session for session ID:', sessionId);
          await verifyPaymentSession(sessionId, user);
        }
      } else {
        console.error('No user detected, redirecting to login.');
        navigate('/login');
      }
      setLoading(false);
      setAuthChecked(true);
    });

    return () => unsubscribe();
  }, [auth, navigate, searchParams]);

  // Function to verify payment session and handle account upgrade
  const verifyPaymentSession = async (sessionId, user) => {
    try {
      console.log('Verifying payment session for session ID:', sessionId);

      // Make the request to verify the payment session
      const response = await fetch(`https://verifysession-rhi2cwsv5a-uc.a.run.app/?session_id=${sessionId}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error(`Failed to fetch session details: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();

      if (data.success) {
        console.log(`Payment succeeded for user ${user.uid}. Upgrading to standard account...`);

        // Update user's account type in Firestore if payment was successful
        let retries = 3;
        while (retries > 0) {
          try {
            const userDocRef = doc(firestore, 'users', user.uid);

            // Get the current date
            const paymentDate = new Date().toISOString();

            // Update Firestore with the new account type and payment date
            await updateDoc(userDocRef, {
              'profile.accountType': 'standard',
              'profile.paymentDate': paymentDate,
            });

            setAccountType('standard');
            const downgradeDate = new Date(paymentDate);
            downgradeDate.setFullYear(downgradeDate.getFullYear() + 1);
            setDowngradeDate(downgradeDate.toLocaleDateString());

            // Trigger confetti effect
            confetti({
              particleCount: 150,
              spread: 70,
              origin: { y: 0.6 },
            });

            // Show a success popup
            alert('Congratulations! You have successfully upgraded to a Personal User account and now have full access for one year.');

            console.log(`User ${user.uid} upgraded to Personal User account on ${paymentDate}.`);
            break; // Exit loop on success
          } catch (error) {
            retries--;
            console.error(`Retry ${3 - retries} - Error updating user account:`, error);
          }
        }
      } else {
        console.warn('Payment was not completed successfully.');
      }
    } catch (error) {
      console.error('Failed to verify payment session:', error.message);
    }
  };

  // Check if all modules are completed
  useEffect(() => {
    if (userData && !certificateGenerated) {
      const totalModules = 8 + 9 + 14; // Total modules in all courses
      const completedModules =
        Object.values(userData.progress?.course1 || {}).filter(Boolean).length +
        Object.values(userData.progress?.course2 || {}).filter(Boolean).length +
        Object.values(userData.progress?.course3 || {}).filter(Boolean).length;

      if (completedModules === totalModules) {
        setShowCertificateButton(true);
      }
    }
  }, [userData, certificateGenerated]);

  // Function to generate certificate on canvas
const generateCertificate = async () => {
  if (certificateGenerated) return; // Prevent generating a new certificate

  const canvas = certificateRef.current;
  if (!canvas) {
    console.error("Canvas element is not found.");
    return;
  }

  const ctx = canvas.getContext('2d');
  if (!ctx) {
    console.error("Canvas context is not available.");
    return;
  }

  const date = new Date().toLocaleDateString();

  // ✅ Set expiry date as Firestore Timestamp
  const expiryDate = new Date();
  expiryDate.setFullYear(expiryDate.getFullYear() + 1); // Set expiry date one year from now
  const expiryTimestamp = Timestamp.fromDate(expiryDate); // ✅ Convert to Firestore Timestamp

  const certificateReference = uuidv4(); // Generate unique reference

  try {
    // Save the reference number and expiry date to Firestore
    const userDocRef = doc(firestore, 'users', auth.currentUser.uid);
    await updateDoc(userDocRef, {
      'profile.certificateReference': certificateReference,
      'profile.certificateExpiry': expiryTimestamp, // ✅ Store as Firestore Timestamp
    });

    console.log("Certificate data saved to Firestore.");
  } catch (error) {
    console.error("Error updating Firestore:", error);
    return;
  }

  // Sanitize user data before rendering it on the canvas
  const sanitizedUserName = sanitizeInput(userData.profile?.name || '');

  // Draw certificate background
  ctx.fillStyle = '#fff';
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  // Border
  ctx.strokeStyle = '#d4af37'; // Gold border
  ctx.lineWidth = 15;
  ctx.strokeRect(10, 10, canvas.width - 20, canvas.height - 20);

  // Title
  ctx.font = '40px Poppins';
  ctx.fillStyle = '#333';
  ctx.textAlign = 'center';
  ctx.fillText('Certificate of Completion', canvas.width / 2, 100);

  // Subtitle
  ctx.font = '20px Poppins';
  ctx.fillText('This certifies that', canvas.width / 2, 150);

  // Recipient Name
  ctx.font = '32px Poppins';
  ctx.fillStyle = '#003366'; // Dark blue for name
  ctx.fillText(sanitizedUserName, canvas.width / 2, 200);

  // Course Completion Text
  ctx.font = '20px Poppins';
  ctx.fillStyle = '#333';
  ctx.fillText('has successfully completed the Cyber Academy Course', canvas.width / 2, 250);

  // Company Name
  ctx.font = '38px Poppins';
  ctx.fillStyle = '#333';
  ctx.fillText('Be Cyber Secure', canvas.width / 2, 350);

  // Issue Date
  ctx.font = '18px Poppins';
  ctx.fillStyle = '#666';
  ctx.fillText(`Issued on: ${date}`, canvas.width / 2, 400);

  // Reference Number
  ctx.font = '18px Poppins';
  ctx.fillStyle = '#666';
  ctx.fillText(`Certificate Ref: ${certificateReference}`, canvas.width / 2, 450);

  // Convert canvas to image and download
  const dataURL = canvas.toDataURL('image/png');
  const link = document.createElement('a');
  link.href = dataURL;
  link.download = `Certificate_${sanitizedUserName}.png`;
  link.click();

  setCertificateGenerated(true); // Mark certificate as generated
};


  const downloadExistingCertificate = () => {
    const canvas = certificateRef.current;
    const ctx = canvas.getContext('2d');
    const date = new Date().toLocaleDateString();
    const referenceNumber = userData.profile?.certificateReference;

    // Sanitize user data before rendering it on the canvas
    const sanitizedUserName = sanitizeInput(userData.profile?.name || '');

    // Redraw certificate using existing details
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.strokeStyle = '#d4af37';
    ctx.lineWidth = 15;
    ctx.strokeRect(10, 10, canvas.width - 20, canvas.height - 20);

    ctx.font = '40px Poppins';
    ctx.fillStyle = '#333';
    ctx.textAlign = 'center';
    ctx.fillText('Certificate of Completion', canvas.width / 2, 130);

    ctx.font = '20px Poppins';
    ctx.fillText('This certifies that', canvas.width / 2, 180);

    ctx.font = '32px Poppins';
    ctx.fillStyle = '#003366';
    ctx.fillText(sanitizedUserName, canvas.width / 2, 230);

    ctx.font = '20px Poppins';
    ctx.fillStyle = '#333';
    ctx.fillText('has successfully completed the Cyber Academy Course', canvas.width / 2, 280);

    ctx.font = '20px Poppins';
    ctx.fillStyle = '#333';
    ctx.fillText('By', canvas.width / 2, 330);

    ctx.font = '38px Poppins';
    ctx.fillText('Be Cyber Secure', canvas.width / 2, 380);

    ctx.font = '18px Poppins';
    ctx.fillStyle = '#666';
    ctx.fillText(`Issued on: ${date}`, canvas.width / 2, 430);

    ctx.font = '18px Poppins';
    ctx.fillText(`Certificate Ref: ${referenceNumber}`, canvas.width / 2, 480);

    const dataURL = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = `Certificate_${sanitizedUserName}.png`;
    link.click();
  };

  const renderStars = (progress, totalModules) => {
    const completedModules = Object.values(progress).filter(Boolean).length;
    return (
      <div className="stars">
        {[...Array(totalModules)].map((_, index) => (
          <span key={index} className={`star ${index < completedModules ? 'filled' : 'outlined'}`}></span>
        ))}
      </div>
    );
  };

  const handleSettingsClick = () => {
    navigate('/settings-standard#SettingsHeading');
  };

  const handleReportThreatClick = () => {
    navigate('/standard-report');
  };

  const handleUpgradeClick = () => {
    navigate('/account-type'); // Navigate to the account upgrade page
  };

  if (loading || !authChecked) {
    return <div>Loading...</div>;
  }

  if (!userData) {
    return <div>User data not found.</div>;
  }

  return (
    <>
      <div className="profile-container">
        <div className="user-portal">
          <h2>Progress for {sanitizeInput(userData.profile?.name || '')}</h2>

          <div className="course-container2">
            <div className="course-section2">
              <h3>CyberShield: Protecting Yourself from Everyday Cyber Threats</h3>
              <div className="stars">
                {renderStars(userData.progress?.course1 || {}, 8)}
              </div>
            </div>
          </div>

          <div className="course-container3">
            <div className="course-section3">
              <h3>CyberShield: Protecting Yourself from Everyday Cyber Threats Part 2</h3>
              <div className="stars">
                {renderStars(userData.progress?.course2 || {}, 9)}
              </div>
            </div>
          </div>

          <div className="course-container4">
            <div className="course-section4">
              <h3>Business Academy: Essential Cybersecurity Workplace Skills</h3>
              <div className="stars">
                {renderStars(userData.progress?.course3 || {}, 14)}
              </div>
            </div>
          </div>

          {/* Conditionally render the Settings button only for standard or admin users */}
          {accountType !== 'manager' && (
             <button 
               className="settings-button2" 
               onClick={handleSettingsClick}
               aria-label="Open settings menu"
  >
               Settings
            </button>
             )}

         {/* Conditionally show Report Threat button only if user is linked to a manager */}
          {linkedToManager && (
          <button 
               className="report-button" 
               onClick={handleReportThreatClick}
               aria-label="Report a cybersecurity threat"
          >
              Report Threat
          </button>
          )}


          {/* Conditionally show Upgrade button only if the user is not a standard or manager */}
           {accountType === 'FreeUser' && (
          <button 
                className="upgrade-button" 
                onClick={handleUpgradeClick}
                aria-label="Upgrade your free account to access additional features"
          >
                  Upgrade Account
          </button>
           )}


          {/* Show Downgrade Date if the user is a standard user */}
          {accountType === 'standard' && downgradeDate && (
            <div className="downgrade-date">
              <p>Your account will be downgraded to a Free User on: {downgradeDate}</p>
            </div>
          )}

          {/* Show Certificate Button if all modules completed */}
          {showCertificateButton && (
            <>
              {certificateGenerated ? (
                <button className="certificate-button" onClick={downloadExistingCertificate}>
                  Download Certificate
                </button>
              ) : (
                <button className="certificate-button" onClick={generateCertificate}>
                  Generate Certificate
                </button>
              )}
              <canvas ref={certificateRef} width={800} height={600} style={{ display: 'none' }}></canvas>
            </>
          )}
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Profile;
