import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './WhatIsCyberSecurity.css';

const FlipCard = ({ title, content, onFlip }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleClick = () => {
    if (!isFlipped && onFlip) {
      onFlip();
    }
    setIsFlipped(true);
  };

  return (
    <div 
      className={`flip-card-M2C2 ${isFlipped ? 'flip-card-M2C2-flipped' : ''}`} 
      onClick={handleClick} 
      tabIndex={0}
      aria-label={`Card: ${title}. ${isFlipped ? content : 'Click to flip.'}`}
    >
      <div className="flip-card-M2C2-inner">
        <div className="flip-card-M2C2-front">
          <h3>{title}</h3>
        </div>
        <div className="flip-card-M2C2-back">
          <p>{content}</p>
        </div>
      </div>
    </div>
  );
};

const ImportanceCyber = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const importanceRef = useRef(null);

  // Navigate to the quiz
  const goToNext = () => {
    navigate('/beginnerscourseparttwo/module2/importance-cyber-quiz');
  };

  // Go back to previous page
  const goBack = () => {
    navigate('/beginnerscourseparttwo/module2/what-is-cyber-security');
  };

  // Scroll to heading if URL hash is present
  useEffect(() => {
    if (location.hash === '#ImportanceHeading' && importanceRef.current) {
      importanceRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  // Card data
  const cardsData = [
    {
      title: 'Protection of Personal Information',
      content:
        'Personal information such as credit card details, medical records, and personal communications are valuable to cybercriminals. Protecting this information helps prevent identity theft, financial fraud, and privacy invasions.',
    },
    {
      title: 'Financial Security',
      content:
        'Cyberattacks can lead to significant financial loss. Phishing scams, online banking fraud, and unauthorised transactions can drain bank accounts and damage credit scores. Cybersecurity measures help protect your finances.',
    },
    {
      title: 'Preventing Identity Theft',
      content:
        'Identity theft occurs when someone uses another person’s personal information without permission to commit fraud. Effective cybersecurity practices help protect against this risk.',
    },
    {
      title: 'Safeguarding Digital Assets',
      content:
        'Photos, documents, and other digital assets stored on personal devices are often irreplaceable. Cybersecurity measures, such as regular backups and anti-malware protection, ensure these assets are protected from loss or damage.',
    },
    {
      title: 'Maintaining Privacy',
      content:
        'Cybersecurity helps individuals control their personal information and protect their privacy from unauthorised access and misuse in an era where data is frequently collected and shared.',
    },
    {
      title: 'Protecting Personal Devices',
      content:
        'Malware and viruses can cause significant damage to personal devices, leading to data loss or reduced functionality. Ensuring cybersecurity helps keep your devices running smoothly and securely.',
    },
    {
      title: 'Ensuring Safe Online Experiences',
      content:
        'From social media to online shopping, cybersecurity measures help ensure that online activities are conducted safely, protecting you from scams, cyberbullying, and other threats.',
    },
    {
      title: 'Avoiding Extortion and Blackmail',
      content:
        'Cybercriminals may use ransomware to lock you out of your devices or threaten to release personal information unless a ransom is paid. Cybersecurity practices help prevent these scenarios.',
    },
    {
      title: 'Securing Home Networks',
      content:
        'With the increasing number of smart devices in homes, securing home networks is essential to protect against unauthorised access and potential breaches.',
    },
  ];

  // Track the current card index and flip state
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [cardFlipped, setCardFlipped] = useState(false);

  const handleCurrentCardFlip = () => {
    setCardFlipped(true);
  };

  const handleNextCard = () => {
    setCardFlipped(false);
    setCurrentCardIndex((prev) => prev + 1);
  };

  const handlePreviousCard = () => {
    setCardFlipped(false);
    setCurrentCardIndex((prev) => (prev > 0 ? prev - 1 : 0));
  };

  // Consider the quiz complete if the last card (index cardsData.length - 1) has been flipped
  const allCardsCompleted = currentCardIndex === cardsData.length - 1 && cardFlipped;

  return (
    <main className="cybersecurity-basics-container">
      <header className="navigation-header">
        <button 
          className="back-arrow-button" 
          onClick={goBack} 
          aria-label="Go back to the previous section"
        >
          ←
        </button>
      </header>

      <section className="module-section">
        <header className="cybersecurity-basics-header" ref={importanceRef}>
          <h1 aria-level="1">Why is Cybersecurity Important for Individuals?</h1>
        </header>

        {/* Intro paragraph explaining the process */}
        <p className="flip-instructions" style={{ textAlign: 'center', marginTop: '1rem' }}>
          Please click on each flip card to reveal more information.
        </p>

        <section className="module-content">
          {!allCardsCompleted ? (
            <>
              <FlipCard
                key={currentCardIndex}
                title={cardsData[currentCardIndex].title}
                content={cardsData[currentCardIndex].content}
                onFlip={handleCurrentCardFlip}
              />
              {cardFlipped && (
                <div className="nav-card-button-container">
                  {currentCardIndex > 0 && (
                    <button 
                      className="nav-card-button"
                      onClick={handlePreviousCard}
                      aria-label="Show previous card"
                    >
                      Previous Card
                    </button>
                  )}
                  {currentCardIndex < cardsData.length - 1 && (
                    <button 
                      className="nav-card-button"
                      onClick={handleNextCard}
                      aria-label="Show next card"
                    >
                      Next Card
                    </button>
                  )}
                </div>
              )}
            </>
          ) : (
            <p style={{ textAlign: 'center' }}>
              All cards viewed! You can now continue to the quiz.
            </p>
          )}
        </section>

        <footer className="navigation-footer">
          {allCardsCompleted && (
            <div className="start-quiz-button-container">
              <button
                className="start-quiz-button1"
                onClick={goToNext}
                aria-label="Proceed to the cybersecurity importance quiz"
              >
                Start Quiz
              </button>
            </div>
          )}
        </footer>
      </section>
    </main>
  );
};

export default ImportanceCyber;
