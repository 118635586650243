import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './SidebarThree.css'; // Import SidebarThree styles

// SidebarThree component
const SidebarThree = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  // Function to toggle sidebar visibility on small screens
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  // Function to handle link click and hide the sidebar on small screens
  const handleLinkClick = () => {
    if (window.innerWidth < 769) {
      setSidebarVisible(false); // Hide sidebar when a link is clicked on small screens
    }
  };

  // Ensure sidebar is always visible on larger screens
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 769) {
        setSidebarVisible(true); // Always show the sidebar on larger screens
      } else {
        setSidebarVisible(false); // Hide sidebar initially on smaller screens
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on screen size

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {/* Sidebar Toggle Button (only visible on small screens) */}
      <div className="sidebar3-toggle-btn" onClick={toggleSidebar}>
        Contents
      </div>

      {/* Sidebar (conditionally apply show/hide class based on screen size) */}
      <div className={`sidebar3 ${sidebarVisible ? 'show' : 'sidebar3-hidden'}`}>
        <ul className="sidebar3-ul">
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/moduleone/intro#BusinessAcademyHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Introduction
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/moduleone/what-is-cybersecurity-two#BusinessAcademyHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              What is Cybersecurity?
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/moduleone/statistics#CurrentThreatLandscape"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Current Threat Landscape
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/moduleone/employee-role#EmployeeRoleHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Employee's Role
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/moduletwo/recognizing-cyber-threats#RecognizingCyberThreatsHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Recognising Threats
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/moduletwo/phishing-tasks#PhishingHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Phishing
            </NavLink>
          </li>

          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/moduletwo/spear-phishing-task#PhishingHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Spear Phishing
            </NavLink>
          </li>
          
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/moduletwo/smishing#SmishingHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Smishing
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/moduletwo/vishing#VishingHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Vishing
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/moduletwo/social-engineering#SocialEngineeringHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Social Engineering
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/moduletwo/insider-threats#InsiderThreatsHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Insider Threats
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/modulethree/securing-work-devices#SecuringWorkDevicesHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Securing Work Devices
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/modulefour/incidents#IncidentResponseHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Incidents
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/modulefour/reporting-procedures#ReportingProceduresHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Procedures
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/modulefive/remote-work-security#RemoteWorkSecurityHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Remote Work
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/modulefive/collaboration-tools#CollaborationToolsHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Collaboration Tools
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/modulesix/data-protection#DataProtectionHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Data Protection
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/modulesix/privacy#PrivacyHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Privacy
            </NavLink>
          </li>
          <li className="sidebar3-li">
            <NavLink 
              to="/businessacademy/modulesix/compliance#ComplianceHeading"
              className={({ isActive }) => isActive ? 'active3' : ''}
              onClick={handleLinkClick}
            >
              Compliance
            </NavLink>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SidebarThree;
