import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../ModuleTwo/WhatIsCyberSecurity.css';
import { HashLink as Link } from 'react-router-hash-link';

const Malware = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const malwareHeadingRef = useRef(null); // Ref for the Malware heading
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module3/malware-quiz');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module3/cyber-threats#CyberThreatsHeading');
  };

  // Scroll to the Malware heading if the hash is present
  useEffect(() => {
    if (location.hash === '#MalwareHeading' && malwareHeadingRef.current) {
      malwareHeadingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <main className="cybersecurity-basics-container">
      <header className="navigation-header">
        <button
          className="back-arrow-button"
          onClick={goBack}
          aria-label="Go back to the cyber threats section"
        >
          ←
        </button>
      </header>

      <section className="module-section">
        <header className="cybersecurity-basics-header" ref={malwareHeadingRef}>
          <h1 aria-level="1">Malware</h1>
        </header>

        <section className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FMalware.mp4?alt=media&token=33ad9154-5c52-485e-97ef-948603946e8a"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="Malware video explaining key concepts"
          >
            Your browser does not support the video tag.
          </video>
          <p aria-live="polite">
            {isVideoWatched
              ? ''
              : ''}
          </p>
        </section>

        <section className="module-content">
          <article className="bordered-content">
            
              <div className="module-content">
              <h3>Malware Removal Guide</h3>
          <div className="bordered-container alt-color">
            <h3>1. Disconnect from the Internet</h3>
            <p>Unplug your PC from the internet to stop the malware from spreading or communicating with its server.</p>
          </div>

          <div className="bordered-container">
            <h3>2. Enter Safe Mode</h3>
            <p>Restart your computer and boot into Safe Mode to prevent most malware from running.</p>
          </div>

          <div className="bordered-container alt-color">
            <h3>3. Backup Important Files</h3>
            <p>Backup your important files to an external drive before you start the removal process.</p>
          </div>

          <div className="bordered-container">
            <h3>4. Use an Antivirus Program</h3>
            <p>Install or update your antivirus software and run a full system scan. Tools like Malwarebytes or Norton can help.</p>
          </div>

          <div className="bordered-container alt-color">
            <h3>5. Delete Temporary Files</h3>
            <p>Delete temporary files using the Disk Cleanup tool to remove some types of malware.</p>
          </div>

          <div className="bordered-container">
            <h3>6. Uninstall Suspicious Programs</h3>
            <p>Go to Control Panel, Programs and Features, and uninstall any unfamiliar or suspicious programs.</p>
          </div>

          <div className="bordered-container alt-color">
            <h3>7. Reset Browser Settings</h3>
            <p>Reset your browser settings to default and remove any suspicious extensions or add-ons.</p>
          </div>

          <div className="bordered-container">
            <h3>8. Check for Suspicious Activities</h3>
            <p>Use Task Manager (Ctrl + Shift + Esc) to look for and end any unusual processes.</p>
          </div>

          <div className="bordered-container alt-color">
            <h3>9. Update Your Software</h3>
            <p>Ensure your operating system, browsers, and software are up to date to fix security vulnerabilities.</p>
          </div>

          <div className="bordered-container">
            <h3>10. Change Passwords</h3>
            <p>After removing the malware, change your passwords, especially for important accounts.</p>
          </div>

          <div className="bordered-container alt-color">
            <h3>11. Stay Protected</h3>
            <p>Install antivirus software, keep it updated, and practice safe browsing habits.</p>
          </div>
        </div>

      
          </article>

          <article className="highlighted-section2">
            <h3>Next Steps</h3>
            <p>
              Great job! You successfully removed the malware from that PC. However, to ensure it hasn't spread across
              the network, you need to check the other computers. Fortunately, the school is small, with only five
              computers. You install antivirus software on each one, run comprehensive scans, and advise the staff to
              monitor for any unusual activity. Additionally, you recommend that the school train staff in IT best
              practices to maintain system security.
            </p>
          </article>

          <article className="highlighted-section2">
            <h3>Investigation</h3>
            <p>
              To prevent future malware infections, it's crucial to understand how the malware initially infiltrated
              the PC. After questioning the staff and checking recent users, you discover that a student named Peter
              had plugged his USB drive into the computer to access his homework. Unfortunately, the USB drive was
              infected with malware. Peter had unknowingly downloaded malware embedded in a music file from an
              untrustworthy website offering free music downloads. You recommend the staff advise Peter and his parents
              to remove the malware, install antivirus software on his home PC, and only download music from official
              websites.
            </p>
          </article>
        </section>

        <footer className="navigation-footer">
          <button
            className="start-quiz-button"
            onClick={handleNextClick}
            aria-label="Proceed to the malware quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </footer>
      </section>
    </main>
  );
};

export default Malware;
