import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../Page3/RecognizingThreats.css';

const CheckingURLs = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const verifyLinksRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);
  const URLchecker = "https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2FURLchecker.png?alt=media&token=55903653-08d0-4c55-845c-58f800e24c3a";

  const goToNext = () => {
    navigate("/beginnerscourse/page5.5/Checking-URLs-quiz");
  };

  useEffect(() => {
    if (location.hash === '#VerifyLinks' && verifyLinksRef.current) {
      verifyLinksRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page5/safe-browsing-part-two#AvoidingSuspiciousLinks');
  };

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Avoiding Suspicious Links"
      >
        ←
      </button>
      <section className="module-section">
        <section className="module-section" style={{ textAlign: 'center' }}>
          <h1 id="VerifyLinks" ref={verifyLinksRef} className="BasicsTitle">
            Verify Links
          </h1>
        </section>

        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FcheckingURL.mp4?alt=media&token=5a396001-d86f-4e03-8acd-40580da20188"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            aria-label="Educational video on verifying links"
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <section className="module-section" style={{ textAlign: 'center' }}>
        <h2 style={{ color: "#005f73" }}>How to Verify links</h2>
        </section>

        <div className="module-content">
          <div className="highlighted-section">
            <p>
              Determining the authenticity of a link by sight alone can be challenging, especially when URL shorteners are used. To verify a link, you can hover your cursor over it to see the URL, or right-click and select "Copy Link" to inspect it further. To ensure a link is safe, you can paste it into a URL checker. This tool reveals the full web address, checks for risks, and provides information about the destination site, helping you avoid potentially harmful websites. Below is an example of a URL checked on a URL checker.
            </p>
          </div>
        </div>
        <br />

        <div style={{ textAlign: 'center' }}>
          <img
            src={URLchecker}
            alt="Screenshot showing the use of a URL checker to verify a link's safety"
            style={{ width: '100%', height: 'auto' }}
          />
        </div>

        <br />

        <div className="bordered-container">
          <p>
            The URL checker used above is available at{' '}
            <a
              href="https://radar.cloudflare.com/scan"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'darkblue', textDecoration: 'underline' }}
              aria-label="Open Cloudflare Radar URL checker in a new tab"
            >
              Cloudflare Radar
            </a>. There are various URL checkers available. The tested link, http://bit.ly/3TeDQM2, is a shortened URL that doesn't immediately reveal its destination. However, by using a URL checker, you can view a screenshot of the landing page and see the actual domain. In this case, the link directs to YouTube, confirming its legitimacy.

            If you were to check a link and found a Facebook login page in the screenshot, but the domain wasn't facebook.com, that would be a clear sign of a fraudulent site attempting to steal your credentials. While shortened URLs are commonly used for legitimate purposes, they can also be exploited for malicious intent.

           To stay safe online, it's essential to verify URLs before clicking them. Your task is to add a reliable URL checker to your bookmarks or favorites, making it quick and easy to verify links whenever needed.
          </p>
        </div>

        <div className="button-container">
          <button
            className="next-button"
            onClick={goToNext}
            aria-label="Proceed to Checking URLs quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text22" role="alert">
              You must watch the video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default CheckingURLs;
