import React, { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../ModuleOne/WhatIsCyberSecurity.css'; // Adjust the path for the CSS file

const ReportingProcedures = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const reportingRef = useRef(null);

  const goToQuiz = () => {
    navigate('/businessacademy/modulefour/reporting-procedures-quiz');
  };

  const goBack = () => {
    navigate('/businessacademy/modulefour/incidents#IncidentResponseHeading');
  };

  useEffect(() => {
    if (location.hash === '#ReportingProceduresHeading' && reportingRef.current) {
      reportingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="cybersecurity-basics-container">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the Incident Response module"
      >
        ←
      </button>

      <div className="cybersecurity-basics-header">
        <br />
        <h1 ref={reportingRef}>Reporting Procedures</h1>
        <br />
      </div>

      <section className="module-section">
        <div className="module-content">
          <div className="highlighted-section">
            <h3>How to Report Potential Security Incidents and Why Timely Reporting is Critical</h3> <br />
            <p>
              Understanding how to report potential security incidents and the importance of timely reporting is essential for safeguarding the organisation.
            </p>
          </div>
        </div>

        <div className="module-content">
        <div className="highlighted-section">
          <h3>Reporting Channels</h3> <br />
          
            <ul>
              <li><strong style={{ color: 'black' }}>Designated Contacts:</strong> Employees should know the specific person or department to contact when they suspect or identify a security incident, such as the IT or Security teams.</li>
              <br />
              <li><strong style={{ color: 'black' }}>Incident Reporting Tools:</strong> Many organisations have dedicated tools or platforms for reporting security incidents. Ensure employees are familiar with these tools and know how to use them.</li>
              <br />
              <li><strong style={{ color: 'black' }}>Confidentiality:</strong> Encourage employees to report incidents confidentially if necessary, especially if they involve insider threats or sensitive information.</li>
            </ul>
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Why Timely Reporting is Critical</h3> <br />
          
            <ul>
              <li><strong style={{ color: 'black' }}>Early Containment:</strong> Prompt reporting allows the organisation to contain the incident quickly, preventing further damage or data loss.</li>
              <br />
              <li><strong style={{ color: 'black' }}>Minimising Impact:</strong> The sooner an incident is reported, the faster the response, which can significantly reduce the impact on the organisation's operations and reputation.</li>
              <br />
              <li><strong style={{ color: 'black' }}>Compliance:</strong> Many regulatory frameworks, such as GDPR, require organisations to report certain types of security incidents within a specific timeframe. Timely internal reporting ensures the organisation can meet these obligations.</li>
              <br />
              <li><strong style={{ color: 'black' }}>Preventing Recurrence:</strong> Early detection and reporting can help identify the root cause of the incident, enabling the organisation to address vulnerabilities and prevent similar incidents in the future.</li>
              <br />
              <li><strong style={{ color: 'black' }}>Incident Investigation:</strong> Reporting incidents promptly provides the security team with the necessary time and information to conduct a thorough investigation, ensuring all aspects of the incident are understood and addressed.</li>
            </ul>
         
        </div>
      </div>

        <div className="start-quiz-button-container">
          <button
            className="start-quiz-button"
            onClick={goToQuiz}
            aria-label="Start Reporting Procedures Quiz"
          >
            Start Quiz
          </button>
        </div>
      </section>
    </div>
  );
};

export default ReportingProcedures;
