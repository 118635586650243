import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../ModuleTwo/WhatIsCyberSecurity.css';

const CyberThreats = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const threatsHeadingRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const scenarioSteps = [
    {
      title: "Step 1: Unexpected Email",
      text: "Chloe receives an email from a shipping company with the subject 'Urgent Delivery Notice'. Though she isn't expecting a package, the email appears legitimate."
    },
    {
      title: "Step 2: Clicking the Link",
      text: "The email instructs her to click a link to confirm delivery details. Worried about a missed delivery, she clicks the link without verifying its authenticity."
    },
    {
      title: "Step 3: Malicious Download",
      text: "The link directs her to a webpage that automatically downloads a file named 'DeliveryReceipt.pdf.exe'. Mistaking it for a PDF, she opens it."
    },
    {
      title: "Step 4: Silent Installation",
      text: "Unbeknownst to Chloe, the file installs ransomware in the background. The malware works silently, so no immediate signs are noticeable."
    },
    {
      title: "Step 5: Spreading & Encrypting Files",
      text: "The ransomware quickly begins encrypting her files—locking important documents, photos, and even system files behind strong encryption."
    },
    {
      title: "Step 6: Ransom Note",
      text: "A pop-up appears with a ransom note demanding 1 Bitcoin within 48 hours. It warns that failure to pay will result in permanent data loss."
    },
    {
      title: "Step 7: Realization & Impact",
      text: "Chloe discovers her files are inaccessible and contacts an IT-savvy friend, who confirms it's a ransomware attack."
    },
    {
      title: "Step 8: Consequences",
      text: "Faced with the dilemma of losing her data or paying a ransom, Chloe also worries that the malware might have compromised her external backup drive."
    },
    {
      title: "Step 9: Learning the Hard Way",
      text: "In the aftermath, Chloe understands the importance of cautious email practices, keeping systems updated, and maintaining secure backups."
    }
  ];

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  // Prevent skipping ahead more than 2 seconds beyond the last valid time.
  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleScenarioNext = () => {
    if (currentStepIndex < scenarioSteps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
      setShowWarning(false);
    }
  };

  const handleScenarioPrevious = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
      setShowWarning(false);
    }
  };

  const handleNextClick = () => {
    // Check if video is watched and scenario completed.
    if (!isVideoWatched || currentStepIndex < scenarioSteps.length - 1) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module3/malware-quiz');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module2/importance-cyber#ImportanceHeading');
  };

  // Scroll to the heading if the hash is present.
  useEffect(() => {
    if (location.hash === '#CyberThreatsHeading' && threatsHeadingRef.current) {
      threatsHeadingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <main className="cybersecurity-basics-container">
      <header className="navigation-header">
        <button
          className="back-arrow-button"
          onClick={goBack}
          aria-label="Go back to the importance of cybersecurity section"
        >
          ←
        </button>
      </header>

      <section className="module-section">
        <header className="cybersecurity-basics-header" ref={threatsHeadingRef}>
          <h1 aria-level="1">Types of Cyber Threats</h1>
        </header>

        <section className="content-layout">
          <article className="module-content">
            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay />
              <video
                ref={videoRef}
                src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FCyber%20Threats%20Unmasked_%20Stay%20Safe%20Online.mp4?alt=media&token=09fe83a6-daf8-4c1e-9dc3-396e03dac426"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error('Video Error:', e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                playsInline
                aria-label="Video explaining types of cyber threats"
              >
                Your browser does not support the video tag.
              </video>
            </div>

            {/* Interactive Scenario Section */}
            <section className="scenario-container">
              <h2>Scenario: Malware & Ransomware Infection</h2>
              <div className="scenario-step">
                <h3>{scenarioSteps[currentStepIndex].title}</h3>
                <p>{scenarioSteps[currentStepIndex].text}</p>
              </div>
              <div className="scenario-navigation">
                {currentStepIndex > 0 && (
                  <button 
                    onClick={handleScenarioPrevious} 
                    className="scenario-nav-button scenario-nav-button-C2M3" 
                    aria-label="Previous Step"
                  >
                    &#8592;
                  </button>
                )}
                {currentStepIndex < scenarioSteps.length - 1 && (
                  <button 
                    onClick={handleScenarioNext} 
                    className="scenario-nav-button scenario-nav-button-C2M3" 
                    aria-label="Next Step"
                  >
                    &#8594;
                  </button>
                )}
                {currentStepIndex === scenarioSteps.length - 1 && (
                  <p className="scenario-complete-message">You have completed the scenario.</p>
                )}
              </div>
            </section>
          </article>
        </section>

        <footer className="navigation-footer">
          <button
            className="start-quiz-button"
            onClick={handleNextClick}
            aria-label="Proceed to the next module on malware"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video and complete all scenario steps before proceeding.
            </p>
          )}
        </footer>
      </section>
    </main>
  );
};

export default CyberThreats;
