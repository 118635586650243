import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../Page3/RecognizingThreatsPartTwo.css'; // Adjusted path
import Tabs from './Tabs'; // Import the Tabs component

const StrongPasswordsPartThree = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState('');
  const [lastValidTime, setLastValidTime] = useState(0);

  // State to track which tabs have been visited (by index)
  const [visitedTabs, setVisitedTabs] = useState([]);
  const requiredTabsCount = 3; // Total number of tabs

  // Callback passed to Tabs so that when a tab is clicked, we mark it as visited.
  const handleTabVisited = (tabIndex) => {
    if (!visitedTabs.includes(tabIndex)) {
      setVisitedTabs([...visitedTabs, tabIndex]);
    }
  };

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning('You must watch the video before proceeding.');
    } else if (visitedTabs.length < requiredTabsCount) {
      setShowWarning('You must click through all the tabs before proceeding.');
    } else {
      setShowWarning('');
      navigate('/beginnerscourse/page4/password-game');
    }
  };

  useEffect(() => {
    if (location.hash === '#BasicsTitle') {
      const element = document.querySelector('.BasicsTitle');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning('');
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page3/recognizing-threats#BasicsTitle');
  };

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Strong Passwords Part Two"
      >
        ←
      </button>
      <br />
      <h1 id="BasicsTitle" className="BasicsTitle">Strong Passwords</h1>
      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FC1StrongPasswords.mp4?alt=media&token=a27e39c0-1f1c-4c10-b79d-0acd55882c92"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            aria-label="Educational video on creating strong passwords (Part 3)"
          >
            Your browser does not support the video tag.
          </video>
        </div>

        {/* New Tabs Section Replacing Key Points */}
        {/* Pass the onTabVisited callback so each tab notifies when visited */}
        <Tabs onTabVisited={handleTabVisited} />

        <hr></hr>

        <div className="module-content">
          <div className="bordered-container">
            <h2>Techniques for Creating Strong Passwords</h2>
            <br />
            <ul className="KeyPoints">
              <li>
                <span className="number-circle">1</span> Passphrase Method: Use a series of random words strung together. For example, "CorrectHorseBatteryStaple."
              </li>
              <br />
              <li>
                <span className="number-circle">2</span> Acronym Method: Create an acronym from a phrase or sentence. For instance, "I love to travel around the world in 80 days!" becomes "IltTatWi80d!"
              </li>
              <br />
              <li>
                <span className="number-circle">3</span> Alternatively, you can Install and Set Up a Password Manager: Choose a reputable password manager to securely store all your passwords.
              </li>
            </ul>
          </div>
        </div>

        {/* Navigation Buttons */}
        <div className="button-container">
          <button
            className="next-button"
            onClick={goToNext}
            aria-disabled={!isVideoWatched}
            aria-label="Proceed to Password Game"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text22" role="alert">
              {showWarning}
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default StrongPasswordsPartThree;
