import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay

const PhishingTasks = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const phishingRef = useRef(null); // Reference for the Phishing section
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // Prevent skipping ahead

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Mark video as fully watched
    setShowWarning(false); // Hide any warnings
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping ahead
    } else {
      setLastValidTime(currentTime); // Update valid time
    }
  };

  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'illegitimate');
  };

  const goToQuiz = () => {
    if (isVideoWatched) {
      navigate('/businessacademy/moduletwo/phishing-tasks-two#PhishingHeading'); // Navigate to the quiz page
    } else {
      setShowWarning(true); // Show warning if the video is not fully watched
    }
  };

  const goBack = () => {
    navigate('/businessacademy/moduletwo/recognizing-cyber-threats#RecognizingCyberThreatsHeading'); // Navigate to the previous page
  };

  // Scroll to the "Phishing" section if hash is present
  useEffect(() => {
    if (location.hash === '#PhishingHeading' && phishingRef.current) {
      phishingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button className="back-arrow-button" onClick={goBack}>
        ←
      </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h1 ref={phishingRef}>Phishing</h1>
            </div>
            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay /> {/* Add the logo overlay */}
              <video
                ref={videoRef}
                src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FModuleTwoPhishing.mp4?alt=media&token=430ef4ff-349e-4403-8a69-145b7feff321"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate" // Disable certain controls
                disablePictureInPicture
                playsInline
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        <div className="cybersecurity-basics-header">
          <br />
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            
              <h3><strong>What is Phishing?:</strong></h3><p> Phishing is the most prevalent cyber threat, affecting 84% of businesses and 83% of charities. It involves attackers posing as legitimate entities to steal sensitive information through fraudulent emails or websites.</p> <br></br> 
              <h3> Types of Phishing</h3> <br></br>
              <ul>
                <li>Email Phishing: Fraudulent emails that appear to come from reputable sources.</li>
                <li>Spear Phishing: Targeted attacks directed at specific individuals or organisations.</li>
                <li>Smishing: Phishing attempts via SMS messages.</li>
                <li>Vishing: Phishing attempts via voice calls.</li>
              </ul>
           
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>How to Identify Phishing</h3><br></br>
           
              <ul>
                <li>Look for suspicious email addresses and URLs.</li>
                <li>Beware of urgent or threatening language prompting immediate action.</li>
                <li>Check for spelling and grammatical errors.</li>
                <li>Verify unexpected attachments or links before clicking.</li>
              </ul>
              <br></br>
            
            <h3>How to Avoid Phishing</h3><br></br>
           
              <ul>
                <li>Do not click on links or download attachments from unknown or unexpected sources.</li>
                <li>Verify the authenticity of the email or message by contacting the sender through a known, trusted channel.</li>
                <li>Use email filters and security software to detect and block phishing attempts.</li>
              </ul>
          
          </div>
        </div>

        <div className="module-section2">
          <section className="module-section" style={{ textAlign: 'center' }}>
            <h2>Phishing Email</h2>
            <br />
          </section>
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="task-instruction">
                <p><strong><span style={{ color: 'black' }}>Your task is to decide if the email is legitimate or illegitimate.</span></strong></p>
              </div>
            </div>
          </div>

          <div className="email-example-box">
            <h3>Example Email</h3>
            <div className="email-content">
              <p><strong>From:</strong> it-support@inn0vatetechsolutions.com</p>
              <br />
              <p><strong>Subject:</strong> Urgent: Password Reset Required</p>
              <br />
              <p>Dear Employee,</p>
              <br />
              <p>
                We have detected unusual activity on your Company email account. For your security, please <strong><span className="tooltip-link"><strong><span style={{ color: 'blue' }}>Click Here</span></strong><span className="tooltip-text">http://inn0vatetechsolutions-reset.com</span></span></strong> to reset your password immediately. Failure to reset your password may result in a temporary hold on your account.
              </p>
              <br />
              <p>Thank you for your prompt attention to this matter.</p>
              <br />
              <p>Sincerely,</p>
              <p>Innovate Tech Solutions IT Support</p>
              <br />
            </div>
            <div className="answer-buttons">
  <button 
    onClick={() => handleAnswer('legitimate')} 
    aria-label="Select if you think the email is legitimate"
  >
    Legitimate
  </button>
  <button 
    onClick={() => handleAnswer('illegitimate')} 
    aria-label="Select if you think the email is illegitimate"
  >
    Illegitimate
  </button>
</div>

{isAnswered && (
  <div 
    className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`} 
    role="alert" 
    aria-live="polite"
  >
    {isCorrect ? 'Well done! This is a Phishing Email' : 'Wrong answer. Try again.'}
  </div>
)}

          </div>

          {isAnswered && (
            <div className="phishing-signs">
              <h3>What is a Phishing Email?</h3>
              <p>A phishing email is a scam where cybercriminals pose as legitimate organisations, like banks or online stores, to steal personal information such as passwords or credit card details. These emails often look convincing but usually contain signs like unusual sender addresses, urgent threats, and suspicious links or attachments. While some use generic greetings like 'Dear Employee,' more targeted attacks, known as spear phishing, use specific names. Always verify the sender and avoid clicking on links or providing personal information via email, as legitimate organisations will never ask for sensitive information this way.</p>
              <h3>Signs of a Phishing Attempt:</h3>
              <ul>
                <li><strong>Email Address:</strong> The sender's email address (it-support@inn0vatetechsolutions.com) might seem legitimate at first glance, but it's crucial to verify the domain carefully. Cybercriminals often employ subtle tricks, such as adding an extra full stop or substituting the letter "O" with a zero, to create deceptive emails. In this example, can you spot where the letter "O" has been replaced with a zero? </li>
                <br />
                <li><strong>Urgency:</strong> The email creates a sense of urgency, pressuring you to act quickly.</li>
                <br />
                <li><strong>Suspicious Link:</strong> The link provided does not lead to the official Company website. It's good practice to go directly to the official website instead of clicking on links.</li>
                <br />
                <li><strong>Generic Greeting:</strong> The email may use a generic greeting instead of your actual name. However, if the hacker has more information and the attack is more targeted, they may use your actual name; this is called spear phishing.</li>
              </ul>
            </div>
          )}
        </div>

        <button 
  className="start-quiz-button" 
  onClick={goToQuiz} 
  
  aria-disabled={!isVideoWatched}
>
  Next
</button>

{showWarning && (
  <p 
    className="warning-text" 
    role="alert" 
    tabIndex="0"
  >
    You must watch the entire video before proceeding.
  </p>
)}

      </section>

      
    </div>
  );
};

export default PhishingTasks;
