import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import '../ModuleOne/WhatIsCyberSecurity.css';

const RemoteWorkQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(7).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(7).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(7).fill(null));
  const [showStar, setShowStar] = useState(false);
  const auth = getAuth();

  const questions = [
    {
      question: "Why is it mandatory to use a VPN when accessing company resources remotely?",
      options: [
        "To improve internet speed",
        "To encrypt your connection",
        "To bypass network restrictions",
        "To allow for faster downloads"
      ],
      correctAnswer: "To encrypt your connection"
    },
    {
      question: "How often should you update your VPN client?",
      options: [
        "Once a year",
        "Whenever you feel like it",
        "Only if it stops working",
        "Regularly"
      ],
      correctAnswer: "Regularly"
    },
    {
      question: "What does Multi-Factor Authentication (MFA) add to your login process?",
      options: [
        "Just another password",
        "An extra layer of security",
        "A faster login process",
        "The ability to skip passwords"
      ],
      correctAnswer: "An extra layer of security"
    },
    {
      question: "What is a best practice for password management?",
      options: [
        "Use the same password for all accounts",
        "Write your passwords on a sticky note",
        "Use strong, unique passwords",
        "Share your passwords with a trusted colleague"
      ],
      correctAnswer: "Use strong, unique passwords"
    },
    {
      question: "Why should you log off from company systems when not in use?",
      options: [
        "To save electricity",
        "To improve system performance",
        "To prevent unauthorised access",
        "To make room for other users"
      ],
      correctAnswer: "To prevent unauthorised access"
    },
    {
      question: "What is the principle of 'least privilege'?",
      options: [
        "Only access what is necessary for your work",
        "Granting yourself all possible permissions",
        "Accessing as much data as possible",
        "Never logging out of systems"
      ],
      correctAnswer: "Only access what is necessary for your work"
    },
    {
      question: "How often is it recommended to change your router?",
      options: [
        "Replace every year",
        "Replace every two years",
        "Replace every three years",
        "Replace every four years"
      ],
      correctAnswer: "Replace every four years"
    },
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect =
        answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? "correct" : "incorrect";
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] =
          shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 5) {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, "users", user.uid);
          await updateDoc(userDocRef, {
            "progress.course3.module5_quiz1": true,
          });
          setShowStar(true);
        }
      } catch (error) {
        console.error("Error updating user progress:", error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(7).fill(null));
    setFeedback(Array(7).fill(null));
    setCorrectAnswers(Array(7).fill(null));
    setShowResults(false);
    setScore(0);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate("/businessacademy/modulefive/collaboration-tools");
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star" role="alert" aria-live="polite"></div>}
      <section
        className="module-section2"
        role="region"
        aria-labelledby="quiz-title"
      >
        <h2 id="quiz-title" tabIndex="0">
          Remote Work Security Quiz
        </h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={
                  feedback[currentQuestionIndex] ? "feedback" : undefined
                }
              >
                <legend
                  id={`question-${currentQuestionIndex}`}
                  tabIndex="0"
                  aria-live="polite"
                  aria-label={`Question ${
                    currentQuestionIndex + 1
                  }: ${shuffledQuestions[currentQuestionIndex]?.question}`}
                >
                  {shuffledQuestions[currentQuestionIndex]?.question}
                </legend>
                {shuffledQuestions[currentQuestionIndex]?.options.map(
                  (option, index) => (
                    <div key={index} className="quiz-option">
                      <label
                        className="quiz-option-label"
                        htmlFor={`option-${currentQuestionIndex}-${index}`}
                      >
                        <input
                          type="radio"
                          id={`option-${currentQuestionIndex}-${index}`}
                          name={`question-${currentQuestionIndex}`}
                          value={option}
                          checked={answers[currentQuestionIndex] === option}
                          onChange={() => handleAnswerChange(option)}
                          disabled={feedback[currentQuestionIndex] !== null}
                          aria-checked={
                            answers[currentQuestionIndex] === option
                          }
                        />
                        <span className="option-text">{option}</span>
                      </label>
                    </div>
                  )
                )}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  role="alert"
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === "correct"
                    ? "Correct!"
                    : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                </div>
              )}
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 7</p>
              <br></br>
              {score >= 5 ? (
                <p>
                  Congratulations! You passed the quiz, you now have a solid
                  understanding of remote work security.
                </p>
              ) : (
                <p>You need to score at least 5 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 5 ? (
                  <button className="next-button" onClick={goToNext}>
                    Next
                  </button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default RemoteWorkQuiz;
