import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Page3/RecognizingThreatsPartTwo.css"; // Adjusted path
import LogoOverlay from "../../components/LogoOverlayVideo/LogoOverlay"; // Import the logo overlay component

const StrongPasswords = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate("/beginnerscourse/page4/strong-passwords-part-two", { replace: false });
  
      // Scroll to the section after navigation
      setTimeout(() => {
        const target = document.getElementById("BasicsTitle");
        if (target) {
          target.scrollIntoView({ behavior: "smooth" });
        } else {
          window.location.href = "/beginnerscourse/page4/password-game";
        }
      }, 500);
    }
  };
  
  

  const goBack = () => {
    navigate("/beginnerscourse/page3/recognizing-threats-part-two#BasicsTitle"); // Navigate to the previous page
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Next" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener("timeupdate", handleTimeUpdate);
      return () => videoElement.removeEventListener("timeupdate", handleTimeUpdate);
    }
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container-r">
      {/* Back Button */}
      <button className="back-arrow-button" onClick={goBack} aria-label="Go back to Recognizing Threats Part Two">
        ←
      </button>
      <br />

      {/* Section Title */}
      <h1 id="BasicsTitle" className="BasicsTitle">Strong Passwords</h1>
      
      <section className="module-section">
        <div>
          {/* Video Container */}
          <div className="video-container" style={{ position: "relative" }}>
            <LogoOverlay />
            <video
              ref={videoRef}
              src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FPageFour.mp4?alt=media&token=1c6c6493-1101-4c44-ab27-d17e897791d1"
              width="100%"
              onEnded={handleVideoEnd}
              onError={(e) => console.error("Video Error:", e)}
              controls
              controlsList="nodownload noremoteplayback noplaybackrate"
              disablePictureInPicture
              aria-label="Educational video on strong passwords"
            >
              Your browser does not support the video tag.
            </video>
          </div>

          {/* Subsection */}
          <section className="module-section" style={{ textAlign: "center" }}>
            <h2>Understanding Strong Passwords</h2>
          </section>

          {/* Text Section */}
          <div className="module-content">
            <div className="bordered-container alt-color">
              <p>
                As you settle onto Ravi's sofa, tea in hand, he sighs.  
                "I tried logging into Facebook—locked out. Same with Twitter and Instagram."  
                Then he admits, "I use the same password for all of them."  
                You feel a chill. This isn't good.
              </p>
            </div>
          </div>

          {/* Next Button */}
          <button
            className="next-button"
            onClick={goToNext}
            aria-disabled={!isVideoWatched}
            aria-label="Proceed to Strong Passwords Part Two"
          >
            Next
          </button>

          {/* Warning Message */}
          {showWarning && <p className="warning-text22" role="alert">You must watch the video before proceeding.</p>}
        </div>
      </section>
    </div>
  );
};

export default StrongPasswords;
