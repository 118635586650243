import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './PasswordGame.css';

const questions = [
  {
    question: "What is the recommended length for a strong password?",
    options: ["8-10 characters", "10-12 characters", "12-16 characters"],
    answer: "12-16 characters"
  },
  {
    question: "Which one of the following is an example of using complexity in a password.",
    options: ["password123456789", "123456789", "Blu3D00rW@y8!6", "abcdefg"],
    answer: "Blu3D00rW@y8!6"
  },
  {
    question: "What should you avoid using in your password?",
    options: ["Special characters", "Common words and patterns", "Uppercase and lowercase letters", "Numbers"],
    answer: "Common words and patterns"
  },
  {
    question: "Which one of the following is a method to create randomness in passwords.",
    options: ["Using personal information", "Using common phrases", "Using unrelated words", "Using sequential numbers"],
    answer: "Using unrelated words"
  },
  {
    question: "What is a fundamental best practice for creating secure online accounts??",
    options: ["Use passwords that are easy to guess, such as 'password123'.", "Change your passwords only when you suspect a breach.", "Use the same password for all accounts to make them easier to remember.", "Use strong, unique passwords for each account."],
    answer: "Use strong, unique passwords for each account."
  },

  {
    question: "Why is it important to use a unique password for each account.",
    options: ["To protect your devices from physical damage", "To prevent multiple account breaches", "To remember easily"],
    answer: "To prevent multiple account breaches"
  },
  {
    question: "What is a benefit of using a password manager.",
    options: ["Stores all your passwords securely", "Shares your passwords with friends", "Makes passwords shorter"],
    answer: "Stores all your passwords securely"
  },
 
  {
    question: "What should you do if you suspect your password has been compromised?",
    options: ["Ignore it", "Change it immediately", "Share it with friends", "Use it on more accounts"],
    answer: "Change it immediately"
  }
];

const PasswordGame = () => {
  const navigate = useNavigate();
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [feedback, setFeedback] = useState('');
  const [canProceed, setCanProceed] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState('');
  const [user, setUser] = useState(null);
  const [showStar, setShowStar] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user || null); // ✅ Allow unauthenticated users to take the quiz
    });
  
    return () => unsubscribe();
  }, []);
  

  const handleAnswerOptionClick = (option) => {
    if (selectedOption === null) {
      setSelectedOption(option);

      if (option === questions[currentQuestion].answer) {
        setScore(score + 1);
        setFeedback('Correct!');
        setCorrectAnswer('');
      } else {
        setFeedback('Incorrect!');
        setCorrectAnswer(`The correct answer is: ${questions[currentQuestion].answer}`);
      }

      setTimeout(() => {
        setFeedback('');
        setCorrectAnswer('');
        setSelectedOption(null);

        const nextQuestion = currentQuestion + 1;
        if (nextQuestion < questions.length) {
          setCurrentQuestion(nextQuestion);
        } else {
          setShowScore(true);
          const finalScore = score + (option === questions[currentQuestion].answer ? 1 : 0);

if (finalScore >= 6) { // ✅ Requires 6 correct answers to pass
  setCanProceed(true);

  if (user) {
    const updateProgress = async () => {
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, {
          'progress.course1.module3': true,
        });
        setShowStar(true);
      } catch (error) {
        console.error('Error updating user profile: ', error);
      }
    };
    
    updateProgress();
  }
}

          
        }
      }, 3000);
    }
  };

  const resetGame = () => {
    setCurrentQuestion(0);
    setScore(0);
    setShowScore(false);
    setCanProceed(false);
    setShowStar(false);
    setSelectedOption(null);
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page4.5/two-factor-auth#BasicsTitle');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page4/strong-passwords-part-three#BasicsTitle');
  };

  return (
    <div className="password-game-container-unique">
      
      <br />
      {showStar && <div className="falling-star" aria-hidden="true"></div>}

      {showScore ? (
        <div className="score-section-unique" role="region" aria-labelledby="quiz-score">
          <h2 id="quiz-score">Quiz Results</h2>
          <br></br>
          <div className="score-feedback-unique">
          {canProceed ? (
  <div className="completion-message">
    {user ? (
      <p>
        🎉 Well done! You have passed the quiz and learnt the importance of strong passwords.
      </p>
    ) : (
      <p>
        🎉 Well done! You have completed the quiz. To track your progress and save your results, 
        <strong> sign up for an account.</strong>
      </p>
    )}
    <button
      className="next-button-unique"
      onClick={goToNext}
      aria-label="Go to the next section"
    >
      Next
    </button>
  </div>
) : (
  <>
    <p>You didn't score high enough to pass. Please try again.</p>
    <button onClick={resetGame} aria-label="Retake the quiz">
      Retake the Test
    </button>
  </>
)}

          </div>
        </div>
      ) : (
        <>
          <section
            className="module-section-unique"
            role="region"
            aria-labelledby={`question-${currentQuestion}`}
          >
            <h2 id={`question-${currentQuestion}`} className="question-text-unique">
              Question {currentQuestion + 1}: {questions[currentQuestion].question}
            </h2>
            <fieldset className="answer-section-unique" aria-describedby="feedback">
              <legend>Answer Choices</legend>
              {questions[currentQuestion].options.map((option, index) => (
                <div key={index} className="quiz-option-unique">
                  <label className="quiz-option-label">
                    <input
                      type="radio"
                      name={`question-${currentQuestion}`}
                      value={option}
                      checked={selectedOption === option}
                      onChange={() => handleAnswerOptionClick(option)}
                      disabled={selectedOption !== null}
                      aria-checked={selectedOption === option}
                    />
                    <span className="option-text">{option}</span>
                  </label>
                </div>
              ))}
            </fieldset>
            {feedback && (
              <div
                className={`feedback-unique ${feedback === 'Correct!' ? 'correct' : 'incorrect'}`}
                id="feedback"
                aria-live="polite"
              >
                {feedback}
                {correctAnswer && <p>{correctAnswer}</p>}
              </div>
            )}
          </section>
        </>
      )}
    </div>
  );
};

export default PasswordGame;
