import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../Page3/RecognizingThreatsPartTwo.css';

const EmailSecurity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const emailSecurityRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourse/page7/email-security-two#EmailSecurity');
    }
  };

  useEffect(() => {
    if (location.hash === '#EmailSecurity' && emailSecurityRef.current) {
      emailSecurityRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page6/social-media-safety#SocialMediaSafety');
  };

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Social Media Safety Part Two"
      >
        ←
      </button>

      <section className="module-section">
        <section className="module-section" style={{ textAlign: 'center' }} ref={emailSecurityRef}>
          <h1 id="EmailSecurity" className="BasicsTitle">
            Email Security
          </h1>
        </section>

        <div className="module-content">
          <div className="video-container" style={{ position: 'relative' }}>
            <LogoOverlay />
            <video
              ref={videoRef}
              src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FEmailScam.mp4?alt=media&token=11b6840d-d4fc-4e7f-a210-0d983fa6ab25"
              width="100%"
              onEnded={handleVideoEnd}
              onError={(e) => console.error('Video Error:', e)}
              controls
              controlsList="nodownload noremoteplayback noplaybackrate"
              disablePictureInPicture
              aria-label="Educational video on email security best practices"
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>


        <div className="button-container">
          <button
            className="next-button"
            onClick={goToNext}
            aria-label="Proceed to Email Security Part Two"
          >
            Start Tasks
          </button>
          {showWarning && (
            <p className="warning-text22" role="alert">
              You must watch the video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default EmailSecurity;
