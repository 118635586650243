import React, { useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './AboutPage.css'; // Updated CSS file
import Footer from './Footer/Footer';

const AboutPage = () => {
  const videoRef = useRef(null);
  const location = useLocation();

  const AboutPageImage = "https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2FAboutPageImage.webp?alt=media&token=dd8459b6-fa6a-4fcb-bab3-2aed684597d4";

  useEffect(() => {
    const videoElement = videoRef.current;

    // Event listener to stop the video 2 seconds before it ends
    const handleTimeUpdate = () => {
      if (videoElement.duration - videoElement.currentTime <= 1) {
        videoElement.pause();
      }
    };

    if (videoElement) {
      // Add event listener for time update
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
    }

    // Cleanup: remove event listener when component is unmounted
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, []);

  // Scroll to the About section based on URL hash
  useEffect(() => {
    if (location.hash === '#section-2-about') {
      const element = document.getElementById('section-2-about');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location.hash]);

  return (
    <>
     

      <div className="main-container-content-about">
     
     
      

        {/* Second Section: Courses */}
        <section id="section-2-about" className="section section-2-about">
          <div className="content-wrapper-about">
          <div className="content-container-about">
              <h2 className="section-title222-about2">Our Mission</h2>
              <p className="section-subtitle-about2">

              <div>

  <p style={{ marginBottom: "16px" }}>
    Our goal is to provide businesses with the tools to effectively train employees in cybersecurity—starting with personal security and extending to safeguarding the organisation. Our course recognises that employees must first learn to protect themselves before they can effectively protect the business.
  </p>

  <p style={{ marginBottom: "16px" }}>
    We equip managers with tools to track staff progress and ensure the completion of training courses. Additionally, we offer a secure platform for employees to report potential threats, helping businesses stay proactive in identifying risks.
  </p>

  <p style={{ marginBottom: "16px" }}>
  All reported threats are compiled into insightful, data-driven graphs, giving managers a clearer understanding of the cybersecurity risks their business faces. With these insights, businesses can make informed decisions to strengthen their defences, stay ahead of emerging threats, and enhance compliance with GDPR and other security standards.
  </p>
</div>


              </p>
             <div className="course-boxes-about">
                  <div style={{ display: 'flex', alignItems: 'left', gap: '10px', marginBottom: '15px', fontSize: '30px' }}>
                       <span>🟢</span>
                       <p style={{ color: 'black', fontWeight: '500' }}>Strengthen Security</p>
                  </div>
               <div style={{ display: 'flex', alignItems: 'left', gap: '10px', marginBottom: '15px', fontSize: '30px' }}>
                       <span>🟢</span>
                       <p style={{ color: 'black', fontWeight: '500' }}>Identify Risks</p>
               </div>
               <div style={{ display: 'flex', alignItems: 'left', gap: '10px', marginBottom: '15px', fontSize: '30px' }}>
                       <span>🟢</span>
                       <p style={{ color: 'black', fontWeight: '500' }}>Analyse Trends</p>
               </div>
               <div className="button-container-about">
              <Link to="/free-user-signup">
                <button className="ManagerAccountButtonHomePage-about"><strong>Create a Free Account Now!</strong></button>
              </Link>
            </div>
               
             </div>
           </div>

            
          </div>

          
          
        </section>
        


        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default AboutPage;
