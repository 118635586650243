import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import {
  getAuth,
  applyActionCode,
  signOut,
  sendEmailVerification,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";


const ActionHandler = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const auth = getAuth();

  // URL query parameters
  const mode = searchParams.get("mode");       // e.g. "verifyEmail" or "resetPassword"
  const oobCode = searchParams.get("oobCode"); // one-time code from Firebase

  // General UI states
  const [message, setMessage] = useState("Checking action code...");
  const [statusClass, setStatusClass] = useState("info-message");
  const [loading, setLoading] = useState(true);

  // For email verification
  const [showResend, setShowResend] = useState(false);

  // For password reset
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);

  // Password validation: at least 10 chars, 1 uppercase, 1 special character
  const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/;

  useEffect(() => {
    // Decide which flow to run based on "mode" and "oobCode"
    if (mode === "verifyEmail" && oobCode) {
      handleVerifyEmail(oobCode);
    } else if (mode === "resetPassword" && oobCode) {
      handleValidateResetCode(oobCode);
    } else {
      setMessage("⚠️ Invalid or missing action code.");
      setStatusClass("error-message");
      setLoading(false);
    }
  }, [mode, oobCode]);

  //
  // ──────────────────────────────────────────────────────────────────────────
  //   EMAIL VERIFICATION FLOW
  // ──────────────────────────────────────────────────────────────────────────
  //
  const handleVerifyEmail = async (code) => {
    try {
      await applyActionCode(auth, code);
      setMessage("✅ Your email has been successfully verified! You can now log in.");
      setStatusClass("success-message");
      setShowResend(false);

      // Sign the user out to refresh their session
      await signOut(auth);
    } catch (error) {
      console.error("Verification Error:", error);
      let errorMessage = "⚠️ Verification failed. The link may have expired or is invalid.";

      if (error.code === "auth/expired-action-code") {
        errorMessage = "❌ This verification link has expired. Please request a new verification email.";
      } else if (error.code === "auth/invalid-action-code") {
        errorMessage = "⚠️ This verification link is invalid. It may have been used already.";
      } else if (error.code === "auth/user-disabled") {
        errorMessage = "🚫 This account has been disabled.";
      } else if (error.code === "auth/user-not-found") {
        errorMessage = "⚠️ No user found for this verification link.";
      }

      setMessage(errorMessage);
      setStatusClass("error-message");
      setShowResend(true);
    }
    setLoading(false);
  };

  // Optionally let user resend verification if needed
  const handleResendVerification = async () => {
    try {
      const user = auth.currentUser;
      if (user && !user.emailVerified) {
        await sendEmailVerification(user);
        setMessage("📧 A new verification email has been sent! Please check your inbox.");
        setStatusClass("info-message");
        setShowResend(false);
      }
    } catch (error) {
      console.error("Error resending verification email:", error);
      setMessage("❌ Could not resend verification email. Try again later.");
      setStatusClass("error-message");
    }
  };

  //
  // ──────────────────────────────────────────────────────────────────────────
  //   PASSWORD RESET FLOW
  // ──────────────────────────────────────────────────────────────────────────
  //
  const handleValidateResetCode = async (code) => {
    try {
      // Verify that the password reset code is valid
      await verifyPasswordResetCode(auth, code);
      setMessage("✅ Code verified. Please enter a new password.");
      setStatusClass("success-message");
      setLoading(false);
      // Focus on the first password input
      newPasswordRef.current?.focus();
    } catch (error) {
      console.error("Password reset code validation error:", error);
      setMessage("⚠️ Password reset link is invalid or expired.");
      setStatusClass("error-message");
      setLoading(false);
    }
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();

    if (!oobCode) {
      setMessage("⚠️ Password reset code is missing or invalid.");
      setStatusClass("error-message");
      return;
    }
    if (newPassword !== confirmPassword) {
      setMessage("⚠️ Passwords do not match.");
      setStatusClass("error-message");
      confirmPasswordRef.current?.focus();
      return;
    }
    if (!passwordRegex.test(newPassword)) {
      setMessage("⚠️ Password must be at least 10 characters, include one uppercase letter and one special character.");
      setStatusClass("error-message");
      newPasswordRef.current?.focus();
      return;
    }

    setLoading(true);
    try {
      await confirmPasswordReset(auth, oobCode, newPassword);
      setMessage("✅ Password reset successful! Redirecting to login...");
      setStatusClass("success-message");
      setTimeout(() => navigate("/login"), 3000);
    } catch (error) {
      console.error("Error resetting password:", error);
      setMessage("⚠️ Error resetting password. Please try again.");
      setStatusClass("error-message");
      setLoading(false);
    }
  };

  //
  // ──────────────────────────────────────────────────────────────────────────
  //   RENDER LOGIC
  // ──────────────────────────────────────────────────────────────────────────
  //
  if (mode === "verifyEmail") {
    // EMAIL VERIFICATION UI
    return (
      <main className="verification-container" role="main">
        <h1 tabIndex="0">Email Verification</h1>
        <p role="status" aria-live="assertive" className={statusClass}>
          {message}
        </p>

        {/* If link is invalid, optionally let user resend */}
        {showResend && (
          <button
            className="resend-button"
            onClick={handleResendVerification}
            aria-label="Resend verification email"
          >
            Resend Verification Email
          </button>
        )}
      </main>
    );
  } else if (mode === "resetPassword") {
    // PASSWORD RESET UI
    return (
      <main className="reset-password-page-container">
        <h1 tabIndex="0">Reset Password</h1>
        <p role="alert" aria-live="assertive" className={statusClass}>
          {message}
        </p>

        {/* Only show the form if the code was verified successfully */}
        {!loading && message.includes("Please enter a new password") && (
          <form onSubmit={handleResetPassword} className="reset-password-page-form">
            {/* New Password */}
            <div className="reset-password-page-input-group">
              <label htmlFor="newPassword">
                New Password <span className="sr-only">(required)</span>
              </label>
              <div className="password-input-container">
                <input
                  id="newPassword"
                  type={showNewPassword ? "text" : "password"}
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  placeholder="Enter new password"
                  ref={newPasswordRef}
                  aria-required="true"
                  aria-describedby="passwordHelp"
                />
                <button
                  type="button"
                  className="toggle-password-button"
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  aria-label={showNewPassword ? "Hide new password" : "Show new password"}
                >
                  {showNewPassword ? "Hide" : "Show"}
                </button>
              </div>
              <small id="passwordHelp" className="reset-password-page-helper-text">
                Must be at least 10 characters, include an uppercase letter and a special character.
              </small>
            </div>

            {/* Confirm Password */}
            <div className="reset-password-page-input-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <div className="password-input-container">
                <input
                  id="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  placeholder="Confirm new password"
                  ref={confirmPasswordRef}
                />
                <button
                  type="button"
                  className="toggle-password-button"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  aria-label={showConfirmPassword ? "Hide confirm password" : "Show confirm password"}
                >
                  {showConfirmPassword ? "Hide" : "Show"}
                </button>
              </div>
            </div>

            {/* Submit */}
            <button
              type="submit"
              className="reset-password-page-button"
              disabled={loading}
              aria-live="polite"
            >
              {loading ? "Resetting..." : "Reset Password"}
            </button>
          </form>
        )}

        {/* Back to login button */}
        <button
          className="reset-password-page-back-button"
          onClick={() => navigate("/login")}
        >
          Back to Login
        </button>
      </main>
    );
  } else {
    // FALLBACK / ERROR
    return (
      <main className="verification-container" role="main">
        <h1 tabIndex="0">Action Error</h1>
        <p role="status" aria-live="assertive" className="error-message">
          {message}
        </p>
      </main>
    );
  }
};

export default ActionHandler;
