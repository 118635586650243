import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';

const WhatIsCyberSecurityTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const businessAcademyRef = useRef(null);
  const warningRef = useRef(null); // Reference for focus on warning message

  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (isVideoWatched) {
      navigate('/businessacademy/moduleone/intro-quiz#CybersecurityQuizHeading');
    } else {
      setShowWarning(true);
      warningRef.current?.focus();
    }
  };

  const goBack = () => {
    navigate('/businessacademy/moduleone/intro#BusinessAcademyHeading');
  };

  useEffect(() => {
    if (location.hash === '#BusinessAcademyHeading' && businessAcademyRef.current) {
      businessAcademyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container" role="main">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the previous page"
      >
        ←
      </button>

      <header className="cybersecurity-basics-header">
        <h1 ref={businessAcademyRef} id="BusinessAcademyHeading" tabIndex="0">
          What is Cybersecurity?
        </h1>
      </header>

      <section className="module-section" role="region" aria-labelledby="BusinessAcademyHeading">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FWhatisCybersecurity.mp4?alt=media&token=9bade990-b115-42c8-a7c2-258c560ad00d"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="What is Cybersecurity video"
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="module-content">
          <section className="highlighted-section" role="region" aria-labelledby="CybersecurityImportance">
            <h3 id="CybersecurityImportance">What is Cybersecurity and why it is Critical for Businesses</h3>
            <ul>
              <li>Protecting sensitive data (customer information, intellectual property, financial data).</li>
              <li>Maintaining business continuity and avoiding operational disruptions.</li>
              <li>Safeguarding the company's reputation and trustworthiness.</li>
              <li>Compliance with legal and regulatory requirements (e.g., GDPR).</li>
            </ul>
          </section>
        </div>

        <div className="module-content">
          <section className="highlighted-section" role="region" aria-labelledby="CybersecurityConsequences">
            <h3 id="CybersecurityConsequences">Consequences of Poor Cybersecurity</h3>
            <ul>
              <li>Data breaches and financial loss.</li>
              <li>Legal ramifications and fines.</li>
              <li>Damage to brand reputation.</li>
              <li>Loss of customer trust.</li>
            </ul>
          </section>
        </div>

        <button
          className="start-quiz-button"
          onClick={handleNextClick}
          
          aria-disabled={!isVideoWatched}
        >
          Start Quiz
        </button>

        {showWarning && (
          <p
            className="warning-text"
            ref={warningRef}
            role="alert"
            tabIndex="0"
            
          >
            You must watch the entire video before proceeding.
          </p>
        )}
      </section>
    </div>
  );
};

export default WhatIsCyberSecurityTwo;
