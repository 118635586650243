import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, onAuthStateChanged, setPersistence, browserSessionPersistence, sendEmailVerification } from 'firebase/auth';
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore'; // Firestore methods
import { firestore } from '../firebase'; // Firestore instance
import ReCAPTCHA from 'react-google-recaptcha';
import './login.css'; // Updated CSS file
import { FaEye, FaEyeSlash } from 'react-icons/fa'; // Import the eye icons
import { Timestamp } from 'firebase/firestore';

const MAX_EMAIL_LENGTH = 100; // Maximum character limit for email
const MAX_PASSWORD_LENGTH = 50; // Maximum character limit for password
const MAX_FAILED_ATTEMPTS = 5; // Maximum failed attempts before lockout
const LOCKOUT_DURATION = 15 * 60 * 1000; // 15 minutes lockout duration in milliseconds


const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [timeToRetry, setTimeToRetry] = useState(null); // To track when the user can try again
  const [isLoggedIn, setIsLoggedIn] = useState(false); // Add a state to track login status
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility
  const [unverifiedUser, setUnverifiedUser] = useState(null);

  const handlePasswordReset = () => {
    navigate('/reset-password'); // Navigates user to your password reset page
  };
  


  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsLoggedIn(true);
        navigate('/');
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, [navigate]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Function to encode email for Firestore document ID
  const encodeEmail = (email) => {
    return email.replace('@', '__AT__').replace(/\./g, '__DOT__');
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const auth = getAuth();

    if (!recaptchaToken) {
        setError('⚠️ reCAPTCHA verification failed. Please try again.');
        return;
    }

    try {
        await setPersistence(auth, browserSessionPersistence);
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;

        if (!user.emailVerified) {
            setUnverifiedUser(user); 
            navigate('/verify-email'); 
            return;
        }

        const userDocRef = doc(firestore, 'users', user.uid);
        const userDocSnapshot = await getDoc(userDocRef);

        if (!userDocSnapshot.exists()) {
            throw new Error('User profile not found in Firestore.');
        }

        await updateDoc(userDocRef, { lastLogin: new Date().toISOString() });

        // ✅ Reset failed login attempts after successful login
        await resetLoginAttempts(email);

        setError(null);
        setUnverifiedUser(null);
        navigate('/');

    } catch (error) {
        console.error("Login error:", error);
        
        // ✅ Ensure failed attempt logging finishes before showing error
        const failedAttemptData = await logFailedAttempt(email);

        if (failedAttemptData.isLocked) {
            setError(`❌ Too many failed attempts. If account exists it will be locked for 15 minutes.`);
        } else if (failedAttemptData.attemptsLeft !== undefined) {
            setError(`⚠️ Incorrect password. You have ${failedAttemptData.attemptsLeft} attempts left.`);
        } else {
            setError('❌ Login failed. Please try again later.');
        }

        // ✅ Force re-render to ensure UI updates
        setEmail(prev => prev + " "); // Quick trick to force a UI update
        setTimeout(() => setEmail(prev => prev.trim()), 50);
    }
};


  // ✅ **Function to Resend Verification Email**
  const handleResendVerification = async () => {
    try {
      if (unverifiedUser) {
        await sendEmailVerification(unverifiedUser);
        alert("📧 A new verification email has been sent! Please check your inbox.");
      }
    } catch (error) {
      console.error("Error resending verification email:", error);
      setError("Could not resend verification email. Try again later.");
    }
};

  
  // ✅ **Update reCAPTCHA Handler**
  const onRecaptchaChange = (token) => {
    setRecaptchaToken(token);
  };
  

  // Function to log failed login attempts in Firestore with rate limiting
  const logFailedAttempt = async (email) => {
    try {
        console.log(`Logging failed attempt for ${email}`);
        const encodedEmail = encodeEmail(email);
        const loginAttemptsRef = doc(firestore, 'loginAttempts', encodedEmail);

        const loginAttemptsDoc = await getDoc(loginAttemptsRef);
        console.log(`Fetched loginAttempts doc: `, loginAttemptsDoc.exists() ? loginAttemptsDoc.data() : "No doc found");

        let failedAttempts = 0;
        let lockedUntil = null;
        let lastFailedAt = null;

        if (loginAttemptsDoc.exists()) {
            const data = loginAttemptsDoc.data();
            failedAttempts = data.failedAttempts || 0;
            lockedUntil = data.lockedUntil || null;
            lastFailedAt = data.lastFailedAt || null;
        }

        const now = Timestamp.now(); // ✅ Firestore Timestamp

        failedAttempts += 1;
        let attemptsLeft = MAX_FAILED_ATTEMPTS - failedAttempts;
        console.log(`Failed Attempts: ${failedAttempts}, Attempts Left: ${attemptsLeft}`);

        if (failedAttempts >= MAX_FAILED_ATTEMPTS) {
            lockedUntil = Timestamp.fromMillis(Date.now() + LOCKOUT_DURATION); // ✅ Store lock time as a Timestamp
            console.log(`User locked out until ${lockedUntil.toDate()}`);

            await setDoc(loginAttemptsRef, {
                failedAttempts,
                lastFailedAt: now, // ✅ Store as Firestore Timestamp
                isLocked: true,
                lockedUntil,
            });
            return { isLocked: true, attemptsLeft: 0 };
        } else {
            await setDoc(loginAttemptsRef, {
                failedAttempts,
                lastFailedAt: now, // ✅ Store as Firestore Timestamp
                isLocked: false,
            });
        }

        console.log("Failed login attempt successfully recorded in Firestore.");
        return { isRateLimited: false, isLocked: false, attemptsLeft };

    } catch (error) {
        console.error("Failed to log the login attempt:", error);
        throw new Error('Failed to log the login attempt.');
    }
};


  // Function to reset failed login attempts after a successful login
  const resetLoginAttempts = async (email) => {
    try {
      const encodedEmail = encodeEmail(email);
      const loginAttemptsRef = doc(firestore, 'loginAttempts', encodedEmail);

      await setDoc(loginAttemptsRef, {
        failedAttempts: 0,
        isLocked: false,
        lockedUntil: null,
      });
    } catch (error) {
      console.error("Failed to reset login attempts:", error);
      throw new Error('Failed to reset login attempts.');
    }
  };

  // Function to check if the account is locked
  const checkAccountLock = async (email) => {
    try {
      const encodedEmail = encodeEmail(email);
      const loginAttemptsRef = doc(firestore, 'loginAttempts', encodedEmail);

      const loginAttemptsDoc = await getDoc(loginAttemptsRef);

      if (loginAttemptsDoc.exists()) {
        const data = loginAttemptsDoc.data();
        const isLocked = data.isLocked || false;
        const lockedUntil = data.lockedUntil || null;

        if (isLocked && new Date(lockedUntil).getTime() > new Date().getTime()) {
          return { isLocked: true, lockedUntil };
        } else if (isLocked && new Date(lockedUntil).getTime() <= new Date().getTime()) {
          await resetLoginAttempts(email); // Reset lock if time has passed
        }
      }
      return false;
    } catch (error) {
      console.error("Failed to check account lock:", error);
      throw new Error('Failed to check account lock.');
    }
  };

  return (
    <div className="login-containerLogin">
  <div className="auth-boxLogin">
    <h1>Login</h1>

    <form onSubmit={handleLogin}>
      <div className="input-groupLogin">
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value.slice(0, MAX_EMAIL_LENGTH))}
          required
          aria-required="true"
          aria-label="Enter your email"
        />
      </div>

      <div className="password-container">
  <input
    id="password"
    type={showPassword ? 'text' : 'password'}
    value={password}
    onChange={(e) => setPassword(e.target.value.slice(0, MAX_PASSWORD_LENGTH))}
    required
    aria-required="true"
    aria-label="Enter your password"
    className={error && error.includes("Incorrect password") ? "error-input" : ""}
  />
  <span 
    onClick={togglePasswordVisibility} 
    className="password-icon" 
    role="button" 
    tabIndex="0" 
    aria-label={showPassword ? "Hide password" : "Show password"}
  >
    {showPassword ? <FaEyeSlash /> : <FaEye />}
  </span>
</div>


      {/* Move error messages here */}
      {error && (
        <div className="error-containerLogin" role="alert">
          <p className="error-textLogin">{error}</p>
        
          {unverifiedUser && (
            <button 
              className="resend-button"
              onClick={handleResendVerification}
              aria-label="Resend verification email"
            >
              Resend Verification Email
            </button>
          )}
        </div>
      )}

      <button 
        type="button"
        className="forgot-password-link"
        onClick={handlePasswordReset}
        aria-label="Forgot password? Reset your password"
      >
        Forgot your password?
      </button>

      <div className={
  "recaptcha-container " +
  (error && error.includes("reCAPTCHA verification failed") ? "error-input" : "")
}>
  <label htmlFor="recaptcha" className="sr-only">
    Please complete the ReCAPTCHA verification
  </label>
  <ReCAPTCHA
    sitekey="6Lfr8W0qAAAAABQY145UoWluq1Ap6kr0jOXxQfWz"
    onChange={onRecaptchaChange}
    id="recaptcha"
    aria-labelledby="recaptcha"
  />
</div>


      <button
        type="submit"
        className="login-buttonLogin"
        disabled={timeToRetry && timeToRetry > Date.now()}
        aria-label="Submit login form"
      >
        Log In
      </button>
    </form>
  </div>
</div>


  );
};

export default Login;
