import { initializeApp } from "firebase/app";
import { getAuth, EmailAuthProvider, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions"; // Import Functions
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

const firebaseConfig = {
  apiKey: "AIzaSyDdUTqyL5O4Y07w8B78HraA3VQb3nBNKEc",
  uthDomain: process.env.NODE_ENV === "development" ? "localhost" : "safe-bytes.firebaseapp.com",
  projectId: "safe-bytes",
  storageBucket: "safe-bytes.appspot.com",
  messagingSenderId: "390466569984",
  appId: "1:390466569984:web:63ef0fabda733f8a7a15e5",
  measurementId: "G-797QFYTS6H"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const firestore = getFirestore(app);
const functions = getFunctions(app); // Initialize Functions

// Consolidated UI Configuration
const uiConfig = {
  signInOptions: [
    EmailAuthProvider.PROVIDER_ID,
    GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    signInSuccessWithAuthResult: (authResult) => {
      // Handle successful sign-in
      console.log('Sign-in successful:', authResult);
      return false; // Prevent automatic redirect
    }
  }
};

// Ensure a single AuthUI instance
let ui;
if (!firebaseui.auth.AuthUI.getInstance()) {
  ui = new firebaseui.auth.AuthUI(auth);
} else {
  ui = firebaseui.auth.AuthUI.getInstance();
}

export { auth, firestore, functions, ui, uiConfig }; // Export `functions` properly
