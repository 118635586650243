import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './LegalDocuments.css'; // Optional: Add CSS for styling
import Footer from '../Footer/Footer';

const UserAgreement = () => {
  const location = useLocation();

  // Effect to handle scroll to section based on URL hash
  useEffect(() => {
    if (location.hash === '#UserAgreementHeading') {
      const element = document.querySelector('.legal-h1');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  return (
    <>

      <div className="legal-documents-body">
        <div className="legal-user-agreement-container">
          <h1 className="legal-h1">User Agreement</h1>
        <p className="legal-p">Effective Date: 14/10/2024</p>

        <h2 className="legal-h2">1. Account Types</h2>
        <p className="legal-p">
          Free User: Free to create, with access to one free course.
          Additional courses can be purchased. Free User accounts are deleted after
          two years of inactivity.
          <br />
          Team Plan: Requires payment of a sign-up fee and yearly
          subscription. Users can track progress and receive threat
          reports from linked employees.
          <br />
          Personal Plan: Requires one-off payment. Account is active for 1 year. User can pay again for additoinal year. Users have full access to all courses. Users have the option to link their account to a Team Plan account. When linked the user on the Team Plan account can track progress, receive threat reports from the user on the Personal Plan.
        </p>

        <h2 className="legal-h2">2. Account Creation</h2>
        <p className="legal-p">
          To use our services, you must create an account by providing accurate
          and complete information. You are responsible for maintaining the
          confidentiality of your login credentials.
        </p>

        <h2 className="legal-h2">3. Use of the Platform</h2>
        <p className="legal-p">
          PErsonal Plan users: You may use the platform to complete cybersecurity
          courses. If you link your account to a User who has a Team Plan account, they can track your
          progress and receive threat reports from you.
          <br />
          Users who have a Team Plan account: You may use the platform to track the progress of linked employees, receive threat reports from them, 
          view platform statistics, and complete all courses.
        </p>

        <h2 className="legal-h2">4. Payment for Team Plan Accounts</h2>
        <p className="legal-p">
          Users who upgrade to a Team Plan are required to pay a sign-up fee and subscribe to a monthly
          plan. Subscription renewals are automatic unless canceled before the
          renewal date. We will notify you before renewal to allow cancellation. 
          If you cancel during the 14-day statutory cooling-off period, a refund will be issued 
          unless you have already accessed paid services.
          <br />
          Personal Plan users will pay to gain access to further courses if required.
        </p>

        <h2 className="legal-h2">5. Data Deletion and Retention</h2>
        <p className="legal-p">
          Free User accounts: Automatically deleted after two years of inactivity. All user data will be deleted.
          Free User accounts can also delete their accounts at any time through their account settings, which will remove all associated data.
          <br />
          Personal Plan accounts: Automatically deleted after two years of inactivity. All user data will be deleted.
          Personal Plan users can also delete their accounts at any time through their account settings, which will remove all associated data.
         <br />
            Team Plan Accounts: Retained as long as the subscription is active. Once the subscription has ended, user data will be kept until the account is deleted or no activity is detected for two years. Users can also delete their accounts at any time through their account settings, which will remove all associated data.
       </p>


        <h2 className="legal-h2">6. Cancellation of Services</h2>
        <p className="legal-p">
          Personal Plan users can delete their account at any time on the settings page.
          <br />
          Team Plan users can cancel their subscription, but refunds are not provided for partial periods. Users can delete their account at any time on the settings page.
        </p>

        <h2 className="legal-h2">7. Restrictions</h2>
        <p className="legal-p">
          You agree not to use the platform for unlawful purposes, share your account with others, or upload malicious code.
        </p>

        <h2 className="legal-h2">8. Intellectual Property</h2>
        <p className="legal-p">
          All content on this platform is the intellectual property of Be Cyber Aware LTD and cannot be reproduced or distributed without permission.
        </p>

        <h2 className="legal-h2">9. Limitation of Liability</h2>
        <p className="legal-p">
          Nothing in this Agreement limits our liability for death or personal injury caused by our negligence, fraud, or any other liability that cannot be excluded by UK law. Be Cyber Aware LTD is not liable for any damages arising from the use of the platform, including but not limited to security breaches or data loss.
        </p>

        <h2 className="legal-h2">10. Changes to This Agreement</h2>
        <p className="legal-p">
          We may modify this User Agreement at any time. Continued use of the platform constitutes acceptance of the revised terms.
        </p>

        <h2 className="legal-h2">11. Contact Us</h2>
        <p className="legal-p">
          For any questions or concerns, please contact us at contact@becyberaware.co.uk.
        </p>

        <h2 className="legal-h2">12. Governing Law and Jurisdiction</h2>
        <p className="legal-p">
          This Agreement is governed by the laws of England and Wales. Any disputes shall be resolved in the courts of England and Wales.
        </p>
      </div>
      <Footer />
    </div>
    </>
  );
};

export default UserAgreement;
