import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc, serverTimestamp, getDoc, doc } from 'firebase/firestore';
import { useAuth } from '../../src/contexts/AuthProvider';
import { firestore } from '../firebase';
import DOMPurify from 'dompurify';
import './StandardReport.css';

const MAX_TITLE_LENGTH = 100;
const MAX_DESCRIPTION_LENGTH = 250;

const StandardReport = () => {
  const { currentUser } = useAuth();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [threatType, setThreatType] = useState('Phishing');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [titleWarning, setTitleWarning] = useState('');
  const [descriptionWarning, setDescriptionWarning] = useState('');
  const navigate = useNavigate();
  const successRef = useRef(null);
  const errorRef = useRef(null);

  useEffect(() => {
    if (success) {
      successRef.current?.focus();
    }
    if (error) {
      errorRef.current?.focus();
    }
  }, [success, error]);

  const handleTitleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length > MAX_TITLE_LENGTH) {
      setTitleWarning(`Title cannot exceed ${MAX_TITLE_LENGTH} characters.`);
    } else {
      setTitle(inputValue);
      setTitleWarning('');
    }
  };

  const handleDescriptionChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length > MAX_DESCRIPTION_LENGTH) {
      setDescriptionWarning(`Description cannot exceed ${MAX_DESCRIPTION_LENGTH} characters.`);
    } else {
      setDescription(inputValue);
      setDescriptionWarning('');
    }
  };

  const createReport = async (title, description, threatType) => {
    if (!currentUser) {
      setError('You must be logged in to report a threat.');
      return;
    }

    try {
      const userDoc = await getDoc(doc(firestore, 'users', currentUser.uid));
      const userProfile = userDoc.data();

      if (!userProfile?.profile?.managerId) {
        setError('You must be linked to a manager to submit a threat report.');
        return;
      }

      let managerId = userProfile.profile.managerId;

      const sanitizedTitle = DOMPurify.sanitize(title);
      const sanitizedDescription = DOMPurify.sanitize(description);

      const reportRef = collection(firestore, 'threatReports');
      await addDoc(reportRef, {
        reportedBy: currentUser.uid,
        reportedByEmail: currentUser.email,
        managerId: managerId,
        title: sanitizedTitle,
        description: sanitizedDescription,
        threatType,
        timestamp: serverTimestamp(),
        comments: []
      });

      setSuccess('Report successfully submitted.');
      setTitle('');
      setDescription('');
      setThreatType('Phishing');
    } catch (err) {
      setError('Failed to submit the report. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title.length > MAX_TITLE_LENGTH || description.length > MAX_DESCRIPTION_LENGTH) {
      setError('Please ensure the title and description meet the character limits.');
      return;
    }

    setError(null);
    setSuccess(null);
    createReport(title, description, threatType);
  };

  const handleBackClick = () => {
    navigate('/profile');
  };

  return (
    <div className="standard-report-container" role="main">
      <button
        type="button"
        className="back-button"
        onClick={handleBackClick}
        aria-label="Back to Profile"
      >
        ← Back to Profile
      </button>
      <h1>Report a Threat</h1>
      <form onSubmit={handleSubmit} className="standard-report-form">
        <div className="form-group">
          <label htmlFor="title">Title (max {MAX_TITLE_LENGTH} characters)</label>
          <input
            id="title"
            type="text"
            value={title}
            onChange={handleTitleChange}
            maxLength={MAX_TITLE_LENGTH}
            required
            aria-describedby="title"
          />
          <p id="title-warning" className="warning-text">{titleWarning}</p>
        </div>
        <div className="form-group">
          <label htmlFor="description">Description (max {MAX_DESCRIPTION_LENGTH} characters)</label>
          <textarea
            id="description"
            value={description}
            onChange={handleDescriptionChange}
            maxLength={MAX_DESCRIPTION_LENGTH}
            required
            aria-describedby="description"
          />
          <p id="description-warning" className="warning-text">{descriptionWarning}</p>
        </div>
        <div className="form-group">
          <label id="threatType-label" htmlFor="threatType">Threat Type</label>
          <select id="threatType" aria-labelledby="threatType-label"
            value={threatType}
            onChange={(e) => setThreatType(e.target.value)}
            required
          >
            <option value="Phishing">Phishing</option>
            <option value="Spear Phishing">Spear Phishing</option>
            <option value="Malware">Malware</option>
            <option value="Ransomware">Ransomware</option>
            <option value="Smishing">Smishing</option>
            <option value="Vishing">Vishing</option>
            <option value="Social Engineering">Social Engineering</option>
            <option value="Insider Threat">Insider Threat</option>
            <option value="Other">Other</option>
          </select>
        </div>
        {error && (
          <p ref={errorRef} className="error-text" role="alert" aria-live="assertive" tabIndex="-1">
            {error}
          </p>
        )}
        {success && (
          <p ref={successRef} className="success-text" role="status" aria-live="polite" tabIndex="-1">
            {success}
          </p>
        )}
       <button type="submit" className="submit-button24" aria-label="Submit threat report">Submit</button>
      </form>
    </div>
  );
};

export default StandardReport;
