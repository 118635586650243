import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../Page3/RecognizingThreatsPartTwo.css'; // Adjusted path
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const StrongPasswordsPartTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0); // To prevent skipping

  useEffect(() => {
    if (location.hash) {
      const elementId = location.hash.substring(1); // Remove "#" from hash
      const element = document.getElementById(elementId);
  
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [location]);
  

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning if the video is not fully watched
    } else {
      navigate('/beginnerscourse/page4/password-game'); // Navigate to the new page
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true); // Enable the "Next" button when the video ends
    setShowWarning(false); // Hide the warning if the video has ended
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime; // Prevent skipping
    } else {
      setLastValidTime(currentTime); // Update last valid time
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page4/strong-passwords-part-three#BasicsTitle'); // Navigate to the previous page
  };

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Strong Passwords Part One"
      >
        ←
      </button>
      <br />
      <h1 id="BasicsTitle" className="BasicsTitle">Strong Password Creation</h1>
      <section className="module-section">

        <section className="module-section">
          <div className="module-content">
            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay /> {/* Add the logo overlay */}
              <video
                ref={videoRef}
                src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FPageFourPartTwo.mp4?alt=media&token=3f5e30b1-c123-424e-aee0-4e76438186bd"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate" // Disable unwanted controls
                disablePictureInPicture // Disable Picture-in-Picture
                aria-label="Educational video on strong passwords (Part 2)"
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </section>

        <section className="module-section2">
        <h2 style={{ color: "#005f73" }}>Recap - Techniques for Creating Strong Passwords</h2>
          <div className="module-content">
            <div className="highlighted-section">
             
              <p>
                <strong>  Passphrase Method:</strong> Use a series of random words strung together. For example, "CorrectHorseBatteryStaple."
              </p>
              
            </div>

            

            <div className="highlighted-section">
              <p>
                <strong>Acronym Method:</strong> Create an acronym from a phrase or sentence. For instance, "I love to travel around the world in 80 days!" becomes "IltTatWi80d!"
              </p>
            </div>

            

            <div className="highlighted-section">
              <p>
                <strong>Sentence Method:</strong> Turn a sentence into a password by using the first letter of each word, including punctuation. For example, "My favourite movie is Jurassic Park!" becomes "MfmijP!"
              </p>
            </div>

            

            <div className="highlighted-section">
              <p>
                Alternatively, you can <strong>Install and Set Up a Password Manager:</strong> Choose a reputable password manager to securely store all your passwords.
              </p>
            </div>
          </div>
        </section>

        <div className="button-container">
          <button
            className="next-button"
            onClick={goToNext}
            aria-disabled={!isVideoWatched}
            aria-label="Proceed to Strong Passwords Part Three"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p
              className="warning-text22"
              role="alert"
            >
              You must watch the video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default StrongPasswordsPartTwo;
