import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './SafeBrowsingDropDragGame.css';

// List of terms, each with an ID, display text, and the correct category.
const terms = [
  { id: 1, text: "Ensure websites use https", category: "good" },
  { id: 2, text: "Click on suspicious links", category: "bad" },
  { id: 3, text: "Enable two-factor authentication", category: "good" },
  { id: 4, text: "Use weak passwords", category: "bad" },
  { id: 5, text: "Verify sender's address", category: "good" },
  { id: 6, text: "Share personal information publicly", category: "bad" },
  { id: 7, text: "Update software regularly", category: "good" },
  { id: 8, text: "Ignore security warnings", category: "bad" },
  { id: 9, text: "Instead of clicking on links, go directly to the official site", category: "good" },
  { id: 10, text: "Click on unknown email attachments", category: "bad" },
  { id: 11, text: "Use unique passwords for different accounts", category: "good" },
  { id: 12, text: "Download movies from unofficial sites", category: "bad" },
];

const SafeBrowsingDropDragGame = () => {
  const navigate = useNavigate();

  // Unsorted terms (initially all).
  const [unsortedTerms, setUnsortedTerms] = useState(terms);
  // Sorted terms go into good[] or bad[] after the user clicks the correct container.
  const [sortedTerms, setSortedTerms] = useState({ good: [], bad: [] });
  // Track which term (if any) the user has selected (clicked on).
  const [selectedTerm, setSelectedTerm] = useState(null);

  // Handle clicking on a statement in the unsorted list.
  const handleTermClick = (term) => {
    // If the clicked term is already selected, deselect it. Otherwise, select it.
    if (selectedTerm && selectedTerm.id === term.id) {
      setSelectedTerm(null);
    } else {
      setSelectedTerm(term);
    }
  };

  // Handle clicking on either "Good" or "Bad" container.
  const handleCategoryClick = (category) => {
    if (!selectedTerm) return; // No term selected, do nothing.

    // Check if the selected term’s category matches the clicked container.
    if (selectedTerm.category === category) {
      // Move the term from unsorted to the correct sorted list.
      setSortedTerms((prev) => ({
        ...prev,
        [category]: [...prev[category], selectedTerm],
      }));
      setUnsortedTerms((prev) => prev.filter((t) => t.id !== selectedTerm.id));
      setSelectedTerm(null);
    } else {
      // Optionally alert or show an error if user chooses the wrong container.
      alert("Incorrect placement! Please select the correct category.");
      setSelectedTerm(null);
    }
  };

  // Navigate back to the previous page.
  const goBack = () => {
    navigate('/beginnerscourse/page5/safe-browsing-part-two#AvoidingSuspiciousLinks');
  };

  // Navigate to the quiz or next page.
  const goToQuiz = () => {
    navigate('/beginnerscourse/page5/safe-browsing-game');
  };

  // Check if all terms have been sorted (unsortedTerms is empty).
  const isAllSortedCorrectly = unsortedTerms.length === 0;

  return (
    <div className="drop-drag-game-container" role="main" aria-label="Safe Browsing Sorting Game">
      {/* Back Button */}
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Safe Browsing Part Two"
      >
        ←
      </button>

      {/* Title & Instructions */}
      <h1 className="GameTitle" tabIndex={0} aria-label="Safe Browsing Sorting Game">
        Safe Browsing Sorting Game
      </h1>
      <p id="gametext" className="gametext">
        Click a statement to select it, then choose whether it belongs in the Good or Bad container.
      </p>

      {/* Unsorted Terms */}
      <div className="terms-container">
        {unsortedTerms.map((term) => (
          <div
            key={term.id}
            className={`term ${selectedTerm && selectedTerm.id === term.id ? 'selected' : ''}`}
            onClick={() => handleTermClick(term)}
            role="button"
            tabIndex={0}
            aria-label={`Unsorted term: ${term.text}`}
          >
            {term.text}
          </div>
        ))}
      </div>

      {/* Categories (Good & Bad) */}
      <div className="categories-container">
        <div
          className={`category good ${selectedTerm ? 'highlight' : ''}`}
          onClick={() => handleCategoryClick('good')}
          role="region"
          aria-label="Good category"
          tabIndex={0}
        >
          <h3>Good</h3>
          {sortedTerms.good.map((term) => (
            <div
              key={term.id}
              className="term good-term"
              role="listitem"
              tabIndex={0}
              aria-label={`Good term: ${term.text}`}
            >
              {term.text}
            </div>
          ))}
        </div>

        <div
          className={`category bad ${selectedTerm ? 'highlight' : ''}`}
          onClick={() => handleCategoryClick('bad')}
          role="region"
          aria-label="Bad category"
          tabIndex={0}
        >
           <h3>Bad</h3>
          {sortedTerms.bad.map((term) => (
            <div key={term.id} className="term bad-term">
              {term.text}
            </div>
          ))}
        </div>
      </div>

      {/* Custom Pop-Up Modal */}
      {isAllSortedCorrectly && (
        <div className="modal-overlay" role="dialog" aria-modal="true">
          <div className="modal-content">
            <h2>Congratulations!</h2>
            <p>You have sorted all the terms correctly.</p>
            <button onClick={goToQuiz} className="next-button">
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SafeBrowsingDropDragGame;
