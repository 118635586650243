import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../ModuleOne/WhatIsCyberSecurity.css'; // Updated path for the CSS file

const SpearPhishingTask2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const spearPhishingRef = useRef(null); // Reference for the Spear Phishing section
  const feedbackRef = useRef(null); // Reference for feedback focus
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);

  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'legitimate');
  };

  const goToQuiz = () => {
    navigate('/businessacademy/moduletwo/recognizing-quiz');
  };

  const goBack = () => {
    navigate('/businessacademy/moduletwo/spear-phishing-task#PhishingHeading');
  };

  // Scroll to the "Spear Phishing Task 2" section if hash is present
  useEffect(() => {
    if (location.hash === '#PhishingHeading' && spearPhishingRef.current) {
      spearPhishingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  // Move focus to feedback message when an answer is selected
  useEffect(() => {
    if (isAnswered && feedbackRef.current) {
      feedbackRef.current.focus();
    }
  }, [isAnswered]);

  return (
    <div className="cybersecurity-basics-container">
      <button className="back-arrow-button" onClick={goBack} aria-label="Go back">
        ←
      </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h1 ref={spearPhishingRef}>Spear Phishing Task 2</h1>
            </div>
          </div>
        </div>

        <div className="module-section2">
          <div className="module-content2">
            <div className="task-instruction">
              <h3><strong>Your task is to decide if the email is legitimate or illegitimate.</strong></h3>
            </div>
          </div>

          <div className="email-example-box">
            <h3>Example Email</h3>
            <blockquote className="email-content">
              <p><strong>From:</strong> John@innovatetechsolutions.com</p>
              <p><strong>Subject:</strong> Team Meeting Follow-Up</p>
              <p>Hi David,</p>
              <p>
                Thank you for your participation in yesterday's team meeting. As discussed, I wanted to follow up on the action items we outlined.
                Please ensure that the project timeline document is updated by Friday and share it with the team.
              </p>
              <p>If you have any questions or need further clarification, feel free to reach out to me directly.</p>
              <p>Best regards,</p>
              <p>John</p>
              <p>CEO, Innovate Tech Solutions</p>
            </blockquote>

            <div className="answer-buttons">
              <button 
                onClick={() => handleAnswer('legitimate')}
                aria-pressed={isAnswered && isCorrect}
              >
                Legitimate
              </button>
              <button 
                onClick={() => handleAnswer('illegitimate')}
                aria-pressed={isAnswered && !isCorrect}
              >
                Illegitimate
              </button>
            </div>

            {isAnswered && (
              <div 
                className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`} 
                ref={feedbackRef} 
                tabIndex="-1"
                role="alert"
              >
                {isCorrect ? 'Well done! This is a legitimate email.' : 'Wrong answer. Try again.'}
              </div>
            )}
          </div>

          {isAnswered && (
            <div className="email-legitimate">
              <h3>Why is this email legitimate?</h3>
              <br></br>
              <ul>
                <li><strong>Email Address:</strong> The sender's email address (ceo@innovatetechsolutions.com) is from a recognised domain and matches the sender's name and position. However, this alone cannot be relied upon as the email may have been spoofed or compromised.</li>
                <br></br>
                <li><strong>Content:</strong> The email provides clear and specific information about a recent team meeting and follows up on discussed action items.</li>
                <br></br>
                <li><strong>Tone and Language:</strong> The tone and language are consistent with professional communication, without any signs of urgency or threats.</li>
                <br></br>
                <li><strong>Contact Information:</strong> The email offers an invitation to reach out for further clarification, which is a typical professional practice.</li>
                <br></br>
                <li>All the above factors together suggest this is a legitimate email.</li>
              </ul>
            </div>
          )}
        </div>

        <button className="start-quiz-button" onClick={goToQuiz} aria-label="Start quiz">
          Start Quiz
        </button>
      </section>
    </div>
  );
};

export default SpearPhishingTask2;
