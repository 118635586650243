import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css'; // Import Sidebar styles

// Sidebar component
const Sidebar = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);

  // Function to toggle sidebar visibility on small screens
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  // Function to handle link click and hide the sidebar on small screens
  const handleLinkClick = () => {
    if (window.innerWidth < 769) {
      setSidebarVisible(false); // Hide sidebar when a link is clicked on small screens
    }
  };

  // Ensure sidebar is always visible on larger screens
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 769) {
        setSidebarVisible(true); // Always show the sidebar on larger screens
      } else {
        setSidebarVisible(false); // Hide sidebar initially on smaller screens
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on screen size

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {/* Sidebar Toggle Button (only visible on small screens) */}
      <div className="sidebar-toggle-btn" onClick={toggleSidebar}>
        Contents
      </div>

      {/* Sidebar (conditionally apply show/hide class based on screen size) */}
      <div className={`sidebar ${sidebarVisible ? 'show' : 'sidebar-hidden'}`}>
        <ul>
          
          <li>
            <NavLink
              to="/beginnerscourse/page2/cybersecurity-basics#BasicsTitle"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={handleLinkClick}  // Collapse sidebar when clicked
            >
              What is Cybersecurity?
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/beginnerscourse/page3/recognizing-threats#BasicsTitle"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={handleLinkClick}  // Collapse sidebar when clicked
            >
              Recognising Threats
            </NavLink>
          </li>
          
          
          <li>
            <NavLink
              to="/beginnerscourse/page4/strong-passwords-part-three#BasicsTitle"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={handleLinkClick}  // Collapse sidebar when clicked
            >
              Strong Passwords
            </NavLink>
          </li>

          
          <li>
            <NavLink
              to="/beginnerscourse/page4.5/two-factor-auth#BasicsTitle"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={handleLinkClick}  // Collapse sidebar when clicked
            >
              Two Factor Authentication
            </NavLink>
          </li>
          
          
          <li>
            <NavLink
              to="/beginnerscourse/page5/safe-browsing#BasicsTitle"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={handleLinkClick}  // Collapse sidebar when clicked
            >
              Safe Browsing
            </NavLink>
          </li>
          
          <li>
            <NavLink
              to="/beginnerscourse/page5/safe-browsing-part-two#BasicsTitle"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={handleLinkClick}  // Collapse sidebar when clicked
            >
              Avoiding Suspicious Links
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/beginnerscourse/page5.5/checking-urls#VerifyLinks"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={handleLinkClick}  // Collapse sidebar when clicked
            >
              Verifying Links
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/beginnerscourse/page6/social-media-safety#SocialMediaSafety"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={handleLinkClick}  // Collapse sidebar when clicked
            >
              Social Media Safety
            </NavLink>
          </li>
          
          <li>
            <NavLink
              to="/beginnerscourse/page7/email-security#EmailSecurity"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={handleLinkClick}  // Collapse sidebar when clicked
            >
              Email Security
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/beginnerscourse/page7/email-security-two#EmailSecurity"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={handleLinkClick}  // Collapse sidebar when clicked
            >
              Email Task One
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/beginnerscourse/page7/email-security-three#EmailSecurity"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={handleLinkClick}  // Collapse sidebar when clicked
            >
              Email Task Two
            </NavLink>
          </li>

          <li>
            <NavLink
              to="/beginnerscourse/page7/email-security-four#EmailSecurity"
              className={({ isActive }) => (isActive ? 'active' : '')}
              onClick={handleLinkClick}  // Collapse sidebar when clicked
            >
              Email Task Three
            </NavLink>
          </li>
          
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
