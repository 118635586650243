import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import './Statistics.css';

const DividerThreatLandscape = () => (
  <div className="divider-threat-landscape-statistics" role="separator" aria-hidden="true" />
);

const Statistics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const threatLandscapeRef = useRef(null);
  const warningRef = useRef(null);

  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const breachesImage = "https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2FBreaches.png?alt=media&token=a070cdb9-422f-4f2a-967b-ac6688ae8aef";
  const disruptive = "https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2FDistruptive.png?alt=media&token=101e80a9-2b8c-4a94-8d14-f79f2cdabdfb";
  const typesofbreachesbreachesImage = "https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2FTypesOfBreaches.png?alt=media&token=490804b1-a397-47d7-bddb-6233e4f5b230";

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  useEffect(() => {
    if (location.hash === '#CurrentThreatLandscape' && threatLandscapeRef.current) {
      threatLandscapeRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const handleNextClick = () => {
    if (isVideoWatched) {
      navigate('/businessacademy/moduleone/employee-role#EmployeeRoleHeading');
    } else {
      setShowWarning(true);
      warningRef.current?.focus();
    }
  };

  const goBack = () => {
    navigate('/businessacademy/moduleone/what-is-cybersecurity-two#BusinessAcademyHeading');
  };

  return (
    <div className="cybersecurity-basics-container" role="main">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the previous page"
      >
        ←
      </button>

      <header className="cybersecurity-basics-header">
        <h1 ref={threatLandscapeRef} id="CurrentThreatLandscape" tabIndex="0">
          Current Threat Landscape
        </h1>
      </header>

      <section className="module-section" role="region" aria-labelledby="CurrentThreatLandscape">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FBreachesSurvey.mp4?alt=media&token=37bb0d1e-2e9b-43ae-b4f8-00dc31a9d107"
            width="100%"
            onEnded={handleVideoEnd}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="Breaches survey video"
          >
            
            Your browser does not support the video tag.
          </video>
        </div>

        <section className="inline-content-threat-landscape-statistics" role="region" aria-labelledby="CyberSurvey">
          <h2 id="CyberSurvey">Statistics From Cyber Security Breaches Survey 2024</h2>
          <p>Published 9 April 2024 - GOV.UK</p>
        </section>

        <h3 className="figure-heading-threat-landscape-statistics">Figure 1 - Types of Breaches or Attacks in the Last 12 Months</h3>
        <img 
          src={typesofbreachesbreachesImage} 
          alt="Graph showing the different types of cybersecurity breaches in the last 12 months" 
          className="small-image-threat-landscape-statistics"
        />
        
        <p className="image-source-threat-landscape-statistics">
          Source: <a href="https://www.gov.uk/government/statistics/cyber-security-breaches-survey-2024/cyber-security-breaches-survey-2024" target="_blank" rel="noopener noreferrer">
            Cyber Security Breaches Survey 2024
          </a>
        </p>

        <div className="bordered-container-threat-landscape-statistics">
          <ul>
            <li><strong>Phishing:</strong> The most predominant issue, impacting <strong>84%</strong> of businesses and <strong>83%</strong> of charities.</li>
            <li><strong>Impersonation:</strong> The second most common issue, affecting <strong>35%</strong> of businesses and <strong>37%</strong> of charities.</li>
            <li><strong>Viruses or Other Malware:</strong> Reported by <strong>17%</strong> of businesses and <strong>14%</strong> of charities.</li>
          </ul>
        </div>

        <DividerThreatLandscape />

        <h3 className="figure-heading-threat-landscape-statistics">Figure 2 - Percentage of Organisations That Have Identified Breaches or Attacks in the Last 12 Months</h3>
        <img 
          src={breachesImage} 
          alt="Graph showing the percentage of organizations that identified breaches in the last year" 
          className="small-image-threat-landscape-statistics"
        />
        <p className="image-source-threat-landscape-statistics">
          Source: <a href="https://www.gov.uk/government/statistics/cyber-security-breaches-survey-2024/cyber-security-breaches-survey-2024" target="_blank" rel="noopener noreferrer">
            Cyber Security Breaches Survey 2024
          </a>
        </p>


        <DividerThreatLandscape />

        <h3 className="figure-heading-threat-landscape-statistics">Figure 3 - Most Disruptive Breaches in the Last 12 Months</h3>
        <img 
          src={disruptive} 
          alt="Graph highlighting the most disruptive cybersecurity breaches" 
          className="small-image-threat-landscape-statistics"
        />
        <p className="image-source-threat-landscape-statistics">
          Source: <a href="https://www.gov.uk/government/statistics/cyber-security-breaches-survey-2024/cyber-security-breaches-survey-2024" target="_blank" rel="noopener noreferrer">
            Cyber Security Breaches Survey 2024
          </a>
        </p>


        <div className="start-quiz-button-container-threat-landscape-statistics">
          <button
            className="next-button-threat-landscape-statistics"
            onClick={handleNextClick}
           
            aria-disabled={!isVideoWatched}
          >
            Next
          </button>

          {showWarning && (
            <p
              className="warning-text"
              ref={warningRef}
              role="alert"
              tabIndex="0"
              
            >
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>

      
    </div>
  );
};

export default Statistics;
