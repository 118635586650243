import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const RemoteWorkSecurity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const remoteWorkRef = useRef(null);
  const videoRef = useRef(null);
  const warningRef = useRef(null);

  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
      setTimeout(() => {
        warningRef.current?.focus();
      }, 100);
    } else {
      goToQuiz();
    }
  };

  const goToQuiz = () => {
    navigate('/businessacademy/modulefive/remote-work-security-quiz');
  };

  const goBack = () => {
    navigate('/businessacademy/modulefour/reporting-procedures#ReportingProceduresHeading');
  };

  useEffect(() => {
    if (location.hash === '#RemoteWorkSecurityHeading' && remoteWorkRef.current) {
      remoteWorkRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the Reporting Procedures module"
      >
        ←
      </button>

      <div className="cybersecurity-basics-header">
        <br />
        <h1 ref={remoteWorkRef}>Remote Work Security</h1>
        <br />
      </div>

      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FRemoteWorkSecurityModuleFive.mp4?alt=media&token=86df6779-bef7-4859-a67f-920d66966cf7"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="Remote Work Security Awareness Video"
          >
            
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Secure Remote Access</h3>
            <p>Best Practices for Accessing Company Resources Remotely:</p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Use of Virtual Private Networks (VPNs)</h3> 
            <ul>
              <li><strong style={{ color: 'black' }}>Mandatory VPN Use:</strong> Always connect to the organisation's VPN when accessing company resources from a remote location. A VPN encrypts your internet connection, ensuring that data transmitted between your device and the company network is secure from potential eavesdroppers.</li>  
              <br />
              <li><strong style={{ color: 'black' }}>Regular VPN Updates:</strong> Ensure that your VPN client is always up-to-date with the latest patches and security updates provided by the IT department.</li>
            </ul>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Multi-Factor Authentication (MFA)</h3> 
            <ul>
              <li><strong style={{ color: 'black' }}>Enable MFA:</strong> Use multi-factor authentication for all remote access points.</li> 
              <br />
              <li><strong style={{ color: 'black' }}>Consistent Use:</strong> Make MFA a consistent part of your login routine.</li>
            </ul>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Secure Password Management</h3> 
            <ul>
              <li><strong style={{ color: 'black' }}>Strong Passwords:</strong> Use strong, unique passwords for all accounts.</li>  
              <br />
              <li><strong style={{ color: 'black' }}>Regular Password Changes:</strong> Change your passwords regularly.</li>
            </ul>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Access Control</h3> 
            <ul>
              <li><strong style={{ color: 'black' }}>Least Privilege Principle:</strong> Only access the information necessary for your work.</li> 
              <br />
              <li><strong style={{ color: 'black' }}>Log Off When Not in Use:</strong> Always log off from company systems when not in use.</li>
            </ul>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Router</h3>
            <p>
              Make sure to replace your router at least every four years to stay up-to-date with the latest technology and security features. 
              Regularly upgrading your router helps protect your network from emerging threats, ensures compatibility with new devices, 
              and provides better overall performance.
            </p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button
            className="start-quiz-button"
            onClick={handleNextClick}
            aria-label="Start Remote Work Security Quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" ref={warningRef} tabIndex="-1" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default RemoteWorkSecurity;
