import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import "../ModuleTwo/WhatIsCyberSecurity.css";

const PublicWifiQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(9).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(9).fill(null));
  const [correctAnswers, setCorrectAnswers] = useState(Array(9).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [showStar, setShowStar] = useState(false);

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
    {
      question: "What is the first step to secure your connection on public Wi-Fi?",
      options: [
        "Use a VPN",
        "Disable Wi-Fi",
        "Install a new app",
        "Connect to any available network"
      ],
      correctAnswer: "Use a VPN"
    },
    {
      question: "Why should you avoid using public Wi-Fi for sensitive transactions?",
      options: [
        "Public Wi-Fi is always slow",
        "Public Wi-Fi can be compromised",
        "Public Wi-Fi is expensive",
        "Public Wi-Fi has limited data"
      ],
      correctAnswer: "Public Wi-Fi can be compromised"
    },
    {
      question: "Which of the following can help protect your data on public Wi-Fi?",
      options: [
        "Using an unsecured website",
        "Connecting to an unknown network",
        "Using HTTPS websites",
        "Disabling firewall"
      ],
      correctAnswer: "Using HTTPS websites"
    },
    {
      question: "What should you do if you suspect a public Wi-Fi network is fake?",
      options: [
        "Connect and test the speed",
        "Connect anyway as you have a VPN enabled",
        "Avoid connecting and report it",
        "Ignore and connect to another network"
      ],
      correctAnswer: "Avoid connecting and report it"
    },
    {
      question: "Why is it important to log out from accounts after using public Wi-Fi?",
      options: [
        "To save data",
        "To prevent unauthorised access",
        "To speed up your device",
        "To free up memory"
      ],
      correctAnswer: "To prevent unauthorised access"
    },
    {
      question: "How can a VPN protect you on public Wi-Fi?",
      options: [
        "By increasing speed",
        "By encrypting your internet connection",
        "By providing free internet",
        "By disabling firewalls"
      ],
      correctAnswer: "By encrypting your internet connection"
    },
    {
      question: "What type of network should you avoid connecting to?",
      options: [
        "Home network",
        "Corporate network",
        "Network with a generic name like 'Free Wi-Fi'",
        "Network with a strong password"
      ],
      correctAnswer: "Network with a generic name like 'Free Wi-Fi'"
    },
    {
      question: "Why should you keep your software updated?",
      options: [
        "To access new features",
        "To protect against vulnerabilities",
        "To make your device look better",
        "To increase battery life"
      ],
      correctAnswer: "To protect against vulnerabilities"
    },
    {
      question: "What is the risk of connecting to public Wi-Fi without protection?",
      options: [
        "No risk at all",
        "Your data can be intercepted by cybercriminals",
        "You can get free internet",
        "You can connect faster"
      ],
      correctAnswer: "Your data can be intercepted by cybercriminals"
    }
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect =
        answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? "correct" : "incorrect";
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] =
          shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 6 && user) {
      try {
        const userDocRef = doc(firestore, "users", user.uid);
        await updateDoc(userDocRef, {
          "progress.course2.module6_quiz": true,
        });
        setShowStar(true);
      } catch (error) {
        console.error("Error updating user profile:", error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(9).fill(null));
    setFeedback(Array(9).fill(null));
    setCorrectAnswers(Array(9).fill(null));
    setShowResults(false);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate("/beginnerscourseparttwo/module7/data-backup#DataBackupHeading");
  };

  const goBack = () => {
    navigate(
      "/beginnerscourseparttwo/module6/public-wifi-part-two#BestPracticesPublicWiFiHeading"
    );
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && (
        <div className="falling-star" role="alert" aria-live="polite"></div>
      )}
      <section
        className="module-section2"
        role="region"
        aria-labelledby="quiz-title"
      >
        <h2 id="quiz-title" tabIndex="0">
          Safe Use of Public Wi-Fi Quiz
        </h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={
                  feedback[currentQuestionIndex] ? "feedback" : undefined
                }
              >
                <legend
                  id={`question-${currentQuestionIndex}`}
                  tabIndex="0"
                  aria-live="polite"
                  aria-label={`Question ${
                    currentQuestionIndex + 1
                  }: ${shuffledQuestions[currentQuestionIndex]?.question}`}
                >
                  {shuffledQuestions[currentQuestionIndex]?.question}
                </legend>
                {shuffledQuestions[currentQuestionIndex]?.options.map(
                  (option, index) => (
                    <div key={index} className="quiz-option">
                      <label
                        className="quiz-option-label"
                        htmlFor={`option-${currentQuestionIndex}-${index}`}
                      >
                        <input
                          type="radio"
                          id={`option-${currentQuestionIndex}-${index}`}
                          name={`question-${currentQuestionIndex}`}
                          value={option}
                          checked={answers[currentQuestionIndex] === option}
                          onChange={() => handleAnswerChange(option)}
                          disabled={feedback[currentQuestionIndex] !== null}
                          aria-checked={
                            answers[currentQuestionIndex] === option
                          }
                        />
                        <span className="option-text">{option}</span>
                      </label>
                    </div>
                  )
                )}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  role="alert"
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === "correct"
                    ? "Correct!"
                    : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                </div>
              )}
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                    aria-label="Next Question"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                    aria-label="Submit Quiz"
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results" role="alert" aria-live="polite">
              <p tabIndex="0">Your score: {score} / 9</p>
              <br></br>
              {score >= 6 ? (
                <p tabIndex="0">
                  Congratulations! You passed the quiz and successfully learned
                  how to use public Wi-Fi safely.
                </p>
              ) : (
                <p tabIndex="0">
                  You need to score at least 6 to pass. Please retake the quiz.
                </p>
              )}
              <div className="quiz-buttons">
                {score >= 6 ? (
                  <button
                    className="next-button"
                    onClick={goToNext}
                    aria-label="Next Lesson"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="retake-button"
                    onClick={handleRetake}
                    aria-label="Retake Quiz"
                  >
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="back-button-container">
          
        </div>
      </section>
    </div>
  );
};

export default PublicWifiQuiz;
