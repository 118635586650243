import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';

const Intro = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const businessAcademyRef = useRef(null); 
  const warningRef = useRef(null); // Reference for warning message

  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
    setProgress(100);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }

    const progressPercent = (currentTime / duration) * 100;
    setProgress(progressPercent);
  };

  useEffect(() => {
    if (location.hash === '#BusinessAcademyHeading' && businessAcademyRef.current) {
      businessAcademyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
      warningRef.current?.focus();
    } else {
      navigate('/businessacademy/moduleone/what-is-cybersecurity-two#BusinessAcademyHeading');
    }
  };

  return (
    <div className="cybersecurity-basics-container" role="main">
      <section className="module-section" role="region" aria-labelledby="BusinessAcademyHeading">
        <h2 className="BasicsTitle" ref={businessAcademyRef} id="BusinessAcademyHeading" tabIndex="0">
          Business Academy
        </h2>

        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FIntroBusiness.mp4?alt=media&token=1a4854e3-e13c-4a74-92c5-b63c16390086"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            aria-label="Introductory Business Academy video"
          >
        
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="start-button-container-intro">
        <button
  className="start-button-intro1"
  onClick={handleNextClick}
  aria-disabled={!isVideoWatched}
>
  Next
</button>


          {showWarning && (
            <p
              className="warning-text"
              ref={warningRef}
              role="alert"
              tabIndex="0"
              
            >
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default Intro;
