import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../Page3/RecognizingThreatsPartTwo.css'; // Adjusted path

const TwoFactorAuthPartTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const goToNext = () => {
    navigate('/beginnerscourse/page4.5/two-factor-auth-quiz#top'); // Navigate to the quiz page
  };

  const goBack = () => {
    navigate('/beginnerscourse/page4.5/two-factor-auth#BasicsTitle'); // Navigate to the previous section
  };

  useEffect(() => {
    if (location.hash === '#Why2FAIsImportant') {
      const element = document.getElementById('Why2FAIsImportant');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Two-Factor Authentication overview"
      >
        ←
      </button>
      <section className="module-section">
        <section className="module-section" style={{ textAlign: 'center' }}>
          <h1 id="Why2FAIsImportant" className="BasicsTitle">
            Why 2FA is Important
          </h1>
        </section>

        <div className="bordered-container alt-color">
          <div className="grid-item text">
            <p>
              <strong>
                <span style={{ color: '#005f73' }}>Enhanced Security:</span>
              </strong>{' '}
              Adds an extra layer of defense against hackers.
            </p>
            <p>
              <strong>
                <span style={{ color: '#005f73' }}>Protection Against Password Theft:</span>
              </strong>{' '}
              Even if someone steals your password, they can't access your account without the second factor.
            </p>
            <p>
              <strong>
                <span style={{ color: '#005f73' }}>Peace of Mind:</span>
              </strong>{' '}
              Provides greater assurance that your accounts are secure.
            </p>
          </div>
        </div>

        <section className="module-section" style={{ textAlign: 'center' }}>
          <h2>Best Practices for 2FA</h2>
        </section>

        <section className="module-section2">
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="grid-container3">
                <div className="grid-item-text2">
                  <p>
                    <strong>
                      <span style={{ color: 'black' }}>Regularly updating</span>
                    </strong>{' '}
                    your 2FA methods is crucial to ensure continued protection and adapt to new security features; to
                    do so, follow the platform-specific instructions to update your 2FA settings.
                  </p>
                  <br />
                  <p>
                    <strong>
                      <span style={{ color: 'black' }}>Educating Others:</span>
                    </strong>{' '}
                    Positively encourage family and friends to use 2FA.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <br />

        <div className="module-content">
          <div className="bordered-container alt-color">
            <p>
              Ravi has a number of questions about two-factor authentication. Your task is to answer them!
            </p>
          </div>
        </div>
        <br />

        <button
          className="next-button"
          onClick={goToNext}
          aria-label="Proceed to Two-Factor Authentication Quiz"
        >
          Start Task
        </button>
      </section>
    </div>
  );
};

export default TwoFactorAuthPartTwo;
