import React, { useState } from 'react';
import './Tabs.css';

const Tabs = ({ onTabVisited }) => {
  const [activeTab, setActiveTab] = useState(0);

  const tabs = [
    {
      title: 'Recommended Password Length',
      content: (
        <>
          <p>A strong password should be 12-16 characters long. This length provides a good balance between security and usability.</p>
          <br />
          <p>Shorter passwords are more vulnerable to attacks, while excessively long passwords may be difficult to remember.</p>
        </>
      ),
    },
    {
      title: 'Avoid Common Patterns',
      content: (
        <>
          <p>Steer clear of using common words, predictable patterns, or personal information in your passwords. Examples include 'password123', your birthdate, or sequential numbers like '123456'.</p>
          <br />
          <p>Such passwords are easily guessed or cracked by attackers using automated tools.</p>
        </>
      ),
    },
    {
      title: 'Unique Passwords for Each Account',
      content: (
        <>
          <p>Always use a unique password for every account you create. Reusing passwords across multiple accounts increases the risk of a security breach.</p>
          <br />
          <p>If one account is compromised, attackers could gain access to all accounts sharing the same password.</p>
        </>
      ),
    },
    {
      title: 'Responding to Compromised Passwords',
      content: (
        <>
          <p>If you suspect that a password has been compromised, change it immediately. Prompt action can prevent unauthorised access to your accounts.</p>
        </>
      ),
    },
  ];

  return (
    <div className="Tabs">
      <div className="tab-buttons">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`tab-button ${activeTab === index ? 'active' : ''}`}
            onClick={() => {
              setActiveTab(index);
              if (onTabVisited) {
                onTabVisited(index);
              }
            }}
          >
            {tab.title}
          </button>
        ))}
      </div>
      <div className="tab-content">
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

export default Tabs;
