import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import the logo overlay component

const Compliance = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const complianceRef = useRef(null);
  const videoRef = useRef(null);
  const warningRef = useRef(null);

  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
      setTimeout(() => {
        warningRef.current?.focus();
      }, 100);
    } else {
      goToQuiz();
    }
  };

  const goToQuiz = () => {
    navigate('/businessacademy/modulesix/compliance-quiz');
  };

  const goBack = () => {
    navigate('/businessacademy/modulesix/privacy#PrivacyHeading');
  };

  useEffect(() => {
    if (location.hash === '#ComplianceHeading' && complianceRef.current) {
      complianceRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the Privacy module"
      >
        ←
      </button>

      <div className="cybersecurity-basics-header">
        
        <h1 ref={complianceRef}>Compliance for Businesses</h1>
        
      </div>

      <section className="module-section">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FComplianceModuleSix.mp4?alt=media&token=2d882e7d-cafe-4d5f-b56b-e24a9d620b40"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="Compliance Awareness Video"
          >
            
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="module-content">
        <div className="highlighted-section">
          <h3>Understanding Organisational Cybersecurity Policies</h3> <br />
          
            <ul>
              
                <li><strong style={{ color: 'black' }}>Overview of Company Policies:</strong> The organisation's cybersecurity policies are designed to protect its assets, data, and reputation. These policies include guidelines on acceptable use, password management, incident reporting, and more.</li>
              
              <br />
            
                <li><strong style={{ color: 'black' }}>Employee Responsibilities:</strong> Compliance with these policies is mandatory for all employees. This includes adhering to guidelines for secure data handling, reporting any suspicious activities, and following best practices for online security.</li>
              
            </ul>
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Adhering to Compliance Requirements</h3> <br />
          
            <ul>
              
                <li><strong style={{ color: 'black' }}>Legal and Regulatory Compliance:</strong> Adhering to legal requirements, such as GDPR, is critical for protecting the organisation from legal risks and maintaining customer trust.</li>
              
              <br />
              
                <li><strong style={{ color: 'black' }}>Policy Implementation:</strong> Ensure that all organisational policies are aligned with legal requirements and communicated clearly to employees.</li>
              
              <br />
              
                <li><strong style={{ color: 'black' }}>Third-Party Compliance:</strong> Verify that third-party vendors comply with the same standards as your organisation.</li>
              
            </ul>
          
        </div>
      </div>

      <div className="module-content">
        <div className="highlighted-section">
          <h3>Continuous Training and Policy Updates</h3> <br />
          
            <ul>
              
                <li><strong style={{ color: 'black' }}>Ongoing Cybersecurity Training:</strong> Continuous training ensures that employees remain aware of the latest cybersecurity threats and how to protect the organisation against them.</li>
              
              <br />
              
                <li><strong style={{ color: 'black' }}>Staying Updated with Policy Changes:</strong> Regularly review and update organisational policies to address emerging threats and changes in the regulatory environment.</li>
              
              <br />
              
                <li><strong style={{ color: 'black' }}>Employee Involvement:</strong> Encourage employees to provide feedback on policies and suggest improvements.</li>
              
            </ul>
          
        </div>
      </div>

        <div className="start-quiz-button-container">
          <button
            className="start-quiz-button"
            onClick={handleNextClick}
            aria-label="Start Compliance Quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" ref={warningRef} tabIndex="-1" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default Compliance;
