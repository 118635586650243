import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import './IntroductionPartTwo.css';

const IntroductionPartTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const detectiveRef = useRef(null); // Ref for The Cybersecurity Detective section
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleStartClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true); // Show warning only on button click
    } else {
      navigate('/beginnerscourseparttwo/module2/what-is-cyber-security#BackArrow');
    }
  };

  // Scroll to the Cybersecurity Detective section if the hash is present
  useEffect(() => {
    if (location.hash === '#CybersecurityDetective' && detectiveRef.current) {
      detectiveRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <main className="introduction-container-intro2">
       <header className="cybersecurity-basics-header" ref={detectiveRef}>
        <h1>The Cybersecurity Detective</h1>
        </header>

        <section className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FIntroductionModuleTwo.mp4?alt=media&token=2ff4f2f4-3739-48d3-b393-7c872be58c1f"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="Introduction video for module two"
          >
            
            Your browser does not support the video tag.
          </video>
          
        </section>

        <div className="start-quiz-button-container">
      
          <button
            className="start-quiz-button1"
            onClick={handleStartClick}
            aria-label="Start the next section"
          >
            Start
          </button>
      
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      
    </main>
  );
};

export default IntroductionPartTwo;
