import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../ModuleOne/WhatIsCyberSecurity.css'; // Updated path for the CSS file

const Smishing = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const smishingRef = useRef(null);
  const feedbackRefs = useRef([]);
  const [isAnswered, setIsAnswered] = useState([false, false, false, false]);
  const [isCorrect, setIsCorrect] = useState([null, null, null, null]);

  const messages = [
    {
      id: 1,
      from: '07700 900123',
      message: `Bank Alert: Your account has been temporarily suspended due to suspicious activity. Please verify your account immediately by clicking on this link: `,
      linkText: 'click here',
      linkUrl: 'https://0nline-bank.com',
      isLegitimate: false,
    },
    {
      id: 2,
      from: '03457740740',
      message: `Your Royal Mail package has been shipped. Track your delivery by clicking on this link: `,
      linkText: 'click here',
      linkUrl: 'https://www.royalmail.com',
      isLegitimate: true,
    },
    {
      id: 3,
      from: '0808 060 0802',
      message: `Vodafone: Your bill is ready. View your bill by clicking on this link: `,
      linkText: 'click here',
      linkUrl: 'https://www.vodafone.co.uk/',
      isLegitimate: true,
    },
    {
      id: 4,
      from: '07700 900456',
      message: `You've won a free gift card! Claim your prize now by clicking on this link: `,
      linkText: 'click here',
      linkUrl: 'http://allfr33gifts.com',
      isLegitimate: false,
    },
  ];

  const handleAnswer = (event, index, answer) => {
    event.preventDefault();
    const newIsAnswered = [...isAnswered];
    const newIsCorrect = [...isCorrect];
    newIsAnswered[index] = true;
    newIsCorrect[index] = answer === (messages[index].isLegitimate ? 'legitimate' : 'illegitimate');
    setIsAnswered(newIsAnswered);
    setIsCorrect(newIsCorrect);

    if (feedbackRefs.current[index]) {
      feedbackRefs.current[index].focus();
    }
  };

  const goToQuiz = (event) => {
    event.preventDefault();
    navigate('/businessacademy/moduletwo/vishing#VishingHeading');
  };

  const goBack = () => {
    navigate('/businessacademy/moduletwo/spear-phishing-task-two');
  };

  useEffect(() => {
    if (location.hash === '#SmishingHeading' && smishingRef.current) {
      smishingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="cybersecurity-basics-container">
      <button className="back-arrow-button" onClick={goBack} aria-label="Go back">
        ←
      </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header">
              <h1 ref={smishingRef}>Smishing</h1>
            </div>
          </div>
        </div>

        <div className="module-section2">
          <div className="module-content2">
            <div className="task-instruction">
              <h2><strong>Your task is to decide if each SMS is legitimate or illegitimate.</strong></h2>
            </div>
          </div>

          {messages.map((message, index) => (
            <div className="sms-example-box white-background-container" key={message.id}>
              <h3>Example SMS {index + 1}</h3>
              <blockquote className="sms-content">
                <p><strong>From:</strong> {message.from}</p>
                <p>
                  <strong>Message:</strong> {message.message}
                  <span 
                    className="tooltip"
                    tabIndex="0"
                    aria-label={`This link goes to: ${message.linkUrl}`}
                  >
                    {message.linkText}
                    <span className="tooltiptext">{message.linkUrl}</span>
                  </span>
                </p>
              </blockquote>

              <div className="answer-buttons">
                <button 
                  onClick={(event) => handleAnswer(event, index, 'legitimate')} 
                  disabled={isAnswered[index]}
                  aria-pressed={isAnswered[index] && isCorrect[index]}
                >
                  Legitimate
                </button>
                <button 
                  onClick={(event) => handleAnswer(event, index, 'illegitimate')} 
                  disabled={isAnswered[index]}
                  aria-pressed={isAnswered[index] && !isCorrect[index]}
                >
                  Illegitimate
                </button>
              </div>

              {isAnswered[index] && (
                <div 
                  className={`feedback ${isCorrect[index] ? 'correct' : 'incorrect'}`} 
                  ref={(el) => feedbackRefs.current[index] = el} 
                  tabIndex="-1"
                  role="alert"
                >
                  {isCorrect[index] ? 'Well done!' : 'Wrong answer.'}
                </div>
              )}

              {isAnswered[index] && (
                <div className="sms-legitimate">
                  <h4>{message.isLegitimate ? 'Why is this SMS legitimate?' : 'Why is this SMS a smishing attempt?'}</h4>
                  <ul>
                    {message.isLegitimate ? (
                      <>
                        <li><strong>Sender's Number:</strong> While the sender's number is recognised and associated with a legitimate service, numbers can be spoofed.</li>
                        <li><strong>Content:</strong> The message provides clear information about a service you are expecting.</li>
                        <li><strong>Link:</strong> The link provided leads to a legitimate website.</li>
                      </>
                    ) : (
                      <>
                        <li><strong>Sender's Number:</strong> The sender's number is not associated with a legitimate contact.</li>
                        <li><strong>Urgency:</strong> The message creates a false sense of urgency or excitement.</li>
                        <li><strong>Suspicious Link:</strong> The link does not lead to an official website but to a suspicious URL.</li>
                        <li><strong>Unusual Request:</strong> Legitimate organisations typically do not request sensitive information via SMS.</li>
                      </>
                    )}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>

        <button className="start-quiz-button" onClick={goToQuiz} aria-label="Next">
          Next
        </button>
      </section>
    </div>
  );
};

export default Smishing;
