import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import '../page5/SafeBrowsingGame.css';

const scenarios = [
  {
    text: "What is one way a URL checker helps identify potentially fraudulent links?",
    options: [
      { text: "It guarantees that the link is safe to click.", isCorrect: false },
      { text: "It replaces the shortened URL with a new, longer URL.", isCorrect: false },
      { text: "It reveals the actual domain and provides a screenshot of the landing page.", isCorrect: true },
    ],
    feedback: [
      "Incorrect.",
      "Incorrect.",
      "Correct!",
    ],
  },
  {
    text: "What is the most reliable way to verify the safety of a link before clicking on it?)",
    options: [
      { text: "Click the link to see where it leads.", isCorrect: false },
      { text: "Use a trusted URL checker to analyse the link.", isCorrect: true },
      { text: "Rely on the sender's email address to determine the link's safety.", isCorrect: false },
    ],
    feedback: [
      "Incorrect.",
      "Correct!",
      "Incorrect.",
    ],
  },
  {
    text: "What domain does this link end at?: https://bit.ly/3TeDQM2 (Use a URL Checker)",
    options: [
      { text: "www.youtube.com", isCorrect: true },
      { text: "www.facebook.com", isCorrect: false },
      { text: "www.bbc.com", isCorrect: false },
    ],
    feedback: [
      "Correct!",
      "Incorrect.",
      "Incorrect.",
    ],
  },
];

const CheckingURLsQuiz = () => {
  const [currentScenario, setCurrentScenario] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showStar, setShowStar] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user || null); // ✅ Allow unauthenticated users to take the quiz
    });
  
    return () => unsubscribe();
  }, []);
  

  const handleOptionClick = (isCorrect, feedbackMessage, index) => {
    if (selectedOption === null) {
      setSelectedOption(index);
      setFeedback({ message: feedbackMessage, isCorrect });
      if (isCorrect) setScore(score + 1);

      setTimeout(() => {
        setFeedback(null);
        setSelectedOption(null);
        setCurrentScenario(currentScenario + 1);
      }, 3000);
    }
  };

  useEffect(() => {
    if (currentScenario >= scenarios.length && score === scenarios.length) {
      if (user) {
        const updateProgress = async () => {
          try {
            const userDocRef = doc(firestore, 'users', user.uid);
            await updateDoc(userDocRef, {
              'progress.course1.module55': true,
            });
            setShowStar(true);
          } catch (error) {
            console.error('Error updating user profile:', error);
          }
        };
  
        updateProgress();
      }
    }
  }, [currentScenario, score, user]);
  

  const resetGame = () => {
    setCurrentScenario(0);
    setScore(0);
    setFeedback(null);
    setShowStar(false);
    setSelectedOption(null);
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page6/social-media-safety#SocialMediaSafety');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page5.5/checking-urls#VerifyLinks');
  };

  if (currentScenario >= scenarios.length) {
    const hasPassed = score === scenarios.length;

    return (
      <div className="safe-browsing-game-container-unique11">
        <button
          className="back-arrow-button-unique11"
          onClick={goBack}
          aria-label="Go back to the previous page"
        >
          ←
        </button>
        {showStar && hasPassed && <div className="falling-star" aria-hidden="true"></div>}
        {hasPassed ? (
  <div className="completion-message">
    {showStar && <div className="falling-star" aria-hidden="true"></div>}
    {user ? (
      <p>
        🎉 Well done! You passed the quiz and learned how to check URLs safely. Your progress has been saved.
      </p>
    ) : (
      <p>
        🎉 Well done! You have completed the quiz. To track your progress and save your results, 
        <strong> sign up for an account.</strong>
      </p>
    )}
   
  </div>
) : (
  <>
    <h2>Oops! You didn't pass the quiz.</h2>
    <p>You need to get all answers correct to pass.</p>
    <p>Your score: {score} out of {scenarios.length}</p>
    <button onClick={resetGame} aria-label="Restart the quiz">Try Again</button>
  </>
)}

        <br></br>
        <p>
          You scored {score} out of {scenarios.length}
        </p>
        <button onClick={resetGame} aria-label="Restart the quiz">
          Try Again
        </button>
        {hasPassed && (
          <button onClick={goToNext} aria-label="Go to the next section">
            Next
          </button>
        )}
      </div>
    );
  }

  const { text, options, feedback: scenarioFeedback } = scenarios[currentScenario];

  return (
    <div className="safe-browsing-game-container-unique11">
      
      <fieldset
        className="quiz-fieldset"
        aria-describedby={feedback ? 'feedback' : undefined}
      >
        <legend className="quiz-legend">{text}</legend>
        {options.map((option, index) => (
          <button
            key={index}
            className={
              selectedOption === index
                ? feedback?.isCorrect
                  ? 'correct-unique11'
                  : 'incorrect-unique11'
                : ''
            }
            onClick={() => handleOptionClick(option.isCorrect, scenarioFeedback[index], index)}
            disabled={selectedOption !== null}
            aria-describedby={selectedOption === index && feedback ? 'feedback' : undefined}
            aria-pressed={selectedOption === index}
          >
            {option.text}
          </button>
        ))}
      </fieldset>
      <div
        id="feedback"
        className={`feedback-unique11 ${feedback?.isCorrect ? 'correct-feedback-unique11' : 'incorrect-feedback-unique11'}`}
        aria-live="polite"
      >
        {feedback?.message}
      </div>
    </div>
  );
};

export default CheckingURLsQuiz;
