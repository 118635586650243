import React, { useState, useRef } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import ReCAPTCHA from 'react-google-recaptcha';
import './ResetPassword.css';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [recaptchaCompleted, setRecaptchaCompleted] = useState(false); // Track reCAPTCHA completion
  const recaptchaRef = useRef(null);
  const emailInputRef = useRef(null);
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleResetPassword = async (event) => {
    event.preventDefault();
    setError('');
    setMessage('');

    const auth = getAuth();

    // Validate email
    if (!emailRegex.test(email)) {
      setError('⚠️ Please enter a valid email address.');
      emailInputRef.current.focus();
      return;
    }

    // Ensure reCAPTCHA is completed
    const recaptchaToken = recaptchaRef.current.getValue();
    if (!recaptchaToken) {
      setError('⚠️ Please complete the reCAPTCHA verification.');
      return;
    }

    setLoading(true); // Show loading spinner

    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('✅ If an account exists with this email, a password reset email has been sent.');
      setError('');
    } catch (error) {
      console.error('❌ Firebase Error:', error.code, error.message);

      if (error.code === 'auth/user-not-found') {
        setError('⚠️ No account found with this email.');
      } else if (error.code === 'auth/invalid-email') {
        setError('⚠️ Invalid email format.');
      } else {
        setError('⚠️ Error sending reset email. Please try again later.');
      }
    } finally {
      setLoading(false);
      recaptchaRef.current.reset();
      setRecaptchaCompleted(false);
    }
  };

  return (
    <div className="reset-password-container">
      <h1 className="reset-password-title" tabIndex="0">Reset Password</h1>

      {/* Success and Error Messages */}
      {message && (
        <p className="reset-message" role="alert" aria-live="assertive">
          {message}
        </p>
      )}
      {error && (
        <p className="reset-error" role="alert" aria-live="assertive">
          {error}
        </p>
      )}

      <form onSubmit={handleResetPassword} id="reset-password-form">
        <div className="input-group">
          <label htmlFor="email" className="email-label">Email</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            placeholder="Enter your email address"
            maxLength="70"
            ref={emailInputRef}
            aria-required="true"
          />
        </div>

        <div className="recaptcha-container">
          <label htmlFor="recaptcha" className="sr-only">
            Please complete the reCAPTCHA verification
          </label>
          <ReCAPTCHA
            sitekey="6Lfr8W0qAAAAABQY145UoWluq1Ap6kr0jOXxQfWz"
            size="compact"
            ref={recaptchaRef}
            id="recaptcha"
            onChange={() => setRecaptchaCompleted(true)} // Enable button when completed
            onExpired={() => setRecaptchaCompleted(false)} // Disable button if expired
          />
        </div>

        <button type="submit" className="reset-button" disabled={!recaptchaCompleted || loading}>
          {loading ? 'Sending...' : 'Send Reset Email'}
        </button>
      </form>
    </div>
  );
};

export default ResetPassword;
