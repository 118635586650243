import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../ModuleTwo/WhatIsCyberSecurity.css';

const Phishing = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const phishingHeadingRef = useRef(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const [showWarning, setShowWarning] = useState(false);

  // Record the user's answer.
  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'illegitimate');
  };

  // Proceed only if the user has answered.
  const handleNextClick = () => {
    if (!isAnswered) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module3/phishing-quiz');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module3/cyber-threats#CyberThreatsHeading');
  };

  useEffect(() => {
    if (location.hash === '#PhishingHeading' && phishingHeadingRef.current) {
      phishingHeadingRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="cybersecurity-basics-container">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the cyber threats section"
      >
        ←
      </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header" ref={phishingHeadingRef}>
              <h1>Phishing Task</h1>
            </div>
          </div>
        </div>

        <div className="module-section2">
          <section className="module-section" style={{ textAlign: 'center' }}>
            {/* Additional instructions can go here if needed */}
          </section>
          <div className="module-content2">
            <div className="bordered-container2">
              <div className="task-instruction">
                <p>
                  <strong>
                    <span style={{ color: 'black' }}>
                      Your task is to decide if the email is legitimate or illegitimate.
                    </span>
                  </strong>
                </p>
              </div>
            </div>
          </div>

          <div className="email-example-box">
            <h3>Example Email</h3>
            <div className="email-content">
              <p><strong>From:</strong> support@byourbank.com</p>
              <p><strong>Subject:</strong> Important: Verify Your Account Information</p>
              <p>Dear Customer,</p>
              <p>
                We have detectd unusual activity on your bank account. For your scurity, please{' '}
                <span
                  className="tooltip-link"
                  role="link"
                  tabIndex="0"
                  aria-label="Fake phishing link, does nothing"
                  onClick={(e) => e.preventDefault()}
                  style={{ color: 'blue', cursor: 'pointer', textDecoration: 'underline' }}
                >
                  Click Here
                </span>{' '}
                to verify your account information immediately. Failure to verify your information may result in a
                temporary hold on your account.
              </p>
              <p>Thank you for your prompt attention to this matter.</p>
              <p>Sincerely,</p>
              <p>Bank Support Team</p>
            </div>
            <div className="answer-buttons">
              <button onClick={() => handleAnswer('legitimate')} aria-label="Mark email as legitimate">
                Legitimate
              </button>
              <button onClick={() => handleAnswer('illegitimate')} aria-label="Mark email as illegitimate">
                Illegitimate
              </button>
            </div>
            {isAnswered && (
              <div className={`explanation-card ${isCorrect ? 'correct' : 'incorrect'}`} role="alert">
                {isCorrect ? (
                  <>
                    <h4>Good job!</h4>
                    <p>
                      This email is illegitimate. It is a phishing attempt because it contains several red flags, such as misspelt words like &lsquo;detectd&rsquo; and &lsquo;scurity&rsquo;, a sender address that doesn&apos;t match your bank&apos;s usual domain, and an urgent request to verify your account via a dubious link. Always verify unexpected emails directly with your bank.
                    </p>
                  </>
                ) : (
                  <>
                    <h4>That is not correct</h4>
                    <p>
                      Despite your answer, please note that this email is in fact illegitimate. Observe the misspelt words (&lsquo;detectd&rsquo;, &lsquo;scurity&rsquo;), the unusual sender address, and the urgent call-to-action that pressures you to click a link. These are common indicators of a phishing attempt. Always confirm such emails with your bank directly.
                    </p>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section2">
            <h3>Different types of phishing</h3>
            <p>
              Phishing can take various forms, such as phishing emails, smishing (SMS phishing), and vishing (voice phishing).
              Signs of phishing include unsolicited messages, urgent requests for personal information, and suspicious links or attachments.
              You should not respond to the email, mark the sender as junk or spam, and delete the email.
            </p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button
            className="start-quiz-button1"
            disabled={!isAnswered}
            onClick={handleNextClick}
            aria-label="Proceed to the phishing quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must complete the task before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default Phishing;
