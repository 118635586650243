import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './EmailSecurity.css';

const EmailSecurityQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(8).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(8).fill(null));
  const [showStar, setShowStar] = useState(false); // State for star animation

  const auth = getAuth();
  const user = auth.currentUser;

  const questions = [
   
    {
      question: "Which of the following sender email addresses would be considered suspicious?",
      options: [" support@amazon.com", " updates@apple.com", " customerservice@paypal.com", " helpdesk@paypa1.com" ],
      correctAnswer: " helpdesk@paypa1.com"
    },

    {
      question: "What should you do if an email says your account will be closed unless you click a link and update your information?",
      options: [" Click the link and update your information", " Reply to the email for more information", " Don't click the link—visit the official site and log in to verify"],
      correctAnswer: " Don't click the link—visit the official site and log in to verify"
    },
    {
      question: "What should you do if you receive an unexpected email with an attachment?",
      options: [" Forward the email to a friend", " Verify the sender", " Open the attachment to see what it is"],
      correctAnswer: " Verify the sender"
    },
    {
      question: "What should you do if an email says you've won a contest you never entered?",
      options: [" Mark the email as spam and delete it", " Reply asking for more details", " Celebrate and provide the requested information", " Share the email with friends"],
      correctAnswer: " Mark the email as spam and delete it"
    },
    {
      question: "How can you verify if a suspicious email from your bank is real?",
      options: [" Click on the link in the email", " Call the phone number provided in the email", " Contact the bank on their official number", " Reply to the email asking for confirmation"],
      correctAnswer: " Contact the bank on their official number"
    },
    {
      question: "What is a red flag that an email may be a scam?",
      options: [" Greeting you with your name", " Creating a sense of urgency or fear", " Being sent in the early hours of the morning"],
      correctAnswer: " Creating a sense of urgency or fear"
    },
    {
      question: "How can you check where a suspicious link in an email leads without clicking on it?",
      options: [" Open the email on a different device", " Copy and paste the link into your browser", " Copy the link and paste it into a URL checker", " Reply to the sender asking for more details"],
      correctAnswer: " Copy the link and paste it into a URL checker"
    },
    {
      question: "What should you do if you think an email is trying to scam you?",
      options: [" Forward it to your friends as a warning", " Ignore it and leave it in your inbox", " Mark it as spam and delete it", " Reply to the sender with a warning"],
      correctAnswer: " Mark it as spam and delete it"
    },
  ];

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 8 && user) {
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        await updateDoc(userDocRef, {
          'progress.course1.module7': true,
        });
        setShowStar(true);
      } catch (error) {
        console.error('Error updating user profile:', error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(8).fill(null));
    setFeedback(Array(8).fill(null));
    setShowResults(false);
    setShowStar(false);
    setScore(0);
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page8/finishers-page');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page7/email-security-four');
  };

  return (
    <div className="cybersecurity-basics-container">
      
      <br />
      {showStar && <div className="falling-star" aria-hidden="true"></div>}
      <section className="module-section2" role="region" aria-labelledby="quiz-title">
        <h2 id="quiz-title">Email Security Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={feedback[currentQuestionIndex] ? 'feedback' : undefined}
              >
                <legend className="quiz-legend">
                  {questions[currentQuestionIndex].question}
                </legend>
                {questions[currentQuestionIndex].options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                        aria-pressed={answers[currentQuestionIndex] === option}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === 'correct'
                    ? 'Correct!'
                    : 'Incorrect'}
                </div>
              )}
              <div className="quiz-buttons">
                {currentQuestionIndex < questions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                    aria-label="Go to the next question"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                    aria-label="Submit your answers"
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results" role="region" aria-labelledby="results-title">
              <h2 id="results-title">Results</h2>
              <p>Your score: {score} / 8</p>
              <br></br>
              {score >= 6 ? (
                <p>Congratulations! You passed the quiz.</p>
              ) : (
                <p>You need to score at least 6 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 8 ? (
                  <button
                    className="next-button"
                    onClick={goToNext}
                    aria-label="Go to the next section"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="retake-button"
                    onClick={handleRetake}
                    aria-label="Retake the quiz"
                  >
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default EmailSecurityQuiz;
