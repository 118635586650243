import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, orderBy, limit } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthProvider'; // Ensure this is correctly imported
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase';
import DOMPurify from 'dompurify';
import { useLocation } from 'react-router-dom'; // Import useLocation for hash detection
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import './ThreatStats.css';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const ThreatStats = () => {
  const [reportData, setReportData] = useState({ labels: [], datasets: [] });
  const [pieData, setPieData] = useState({ labels: [], datasets: [] });
  const [monthlyData, setMonthlyData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(''); // Error state
  const location = useLocation(); // Track hash location
  const { currentUser } = useAuth(); // Call useAuth at the top level

   // Smooth scroll to the section based on URL hash
   useEffect(() => {
    if (location.hash === '#ThreatStatsHeading') {
      const scrollTimeout = setTimeout(() => {
        const element = document.querySelector('.ThreatStats-title');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);

      // Clean up the timeout on unmount
      return () => clearTimeout(scrollTimeout);
    }
  }, [location.hash]);


  // Helper function to convert local date to UTC
  const convertToUTC = (date) => {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0));
  };

  
  useEffect(() => {
  
    const fetchReports = async () => {
      if (!currentUser || !currentUser.uid) return;
  
      const today = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(today.getDate() - 30);
  
      // Convert all date ranges to UTC to align with Firestore's timestamp storage
      const currentMonthStart = convertToUTC(new Date(today.getFullYear(), today.getMonth(), 1));
      const lastMonthStart = convertToUTC(new Date(today.getFullYear(), today.getMonth() - 1, 1));
      const twoMonthsAgoStart = convertToUTC(new Date(today.getFullYear(), today.getMonth() - 2, 1));
      const threeMonthsAgoStart = convertToUTC(new Date(today.getFullYear(), today.getMonth() - 3, 1));
  
      try {
        const managerRef = doc(firestore, 'users', currentUser.uid);
        const managerSnap = await getDoc(managerRef);
        let employees = [];
  
        if (managerSnap.exists()) {
          employees = managerSnap.data().employees || [];
          console.log("Manager's linked employees:", employees);
        }
  
        // ✅ Ensure managers always include their own reports, even if they have no linked employees
const reportQuery = query(
  collection(firestore, 'threatReports'),
  where('reportedBy', 'in', [...employees, currentUser.uid]), // ✅ Include manager's own reports
  where('timestamp', '>=', threeMonthsAgoStart),
  orderBy('timestamp', 'asc'),
  limit(100) // Limit to 100 reports
);

        // Query Firestore for reports from linked employees
        const q = query(
          collection(firestore, 'threatReports'),
          where('reportedBy', 'in', [...employees, currentUser.uid]), // ✅ Include manager's own reports
          where('timestamp', '>=', threeMonthsAgoStart),
          orderBy('timestamp', 'asc'),
          limit(100) // Limiting the number of returned documents
        );
        
  
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          console.warn("No threat reports found for linked employees.");
        }
  
        // Aggregate the threats by type for the last 30 days
        const overallThreatCountsLast30Days = {
          Phishing: 0,
          'Spear Phishing': 0,
          Malware: 0,
          Ransomware: 0,
          Smishing: 0,
          Vishing: 0,
          'Social Engineering': 0,
          'Insider Threat': 0,
        };
  
        const monthlyCounts = {
          currentMonth: JSON.parse(JSON.stringify(overallThreatCountsLast30Days)),
          lastMonth: JSON.parse(JSON.stringify(overallThreatCountsLast30Days)),
          twoMonthsAgo: JSON.parse(JSON.stringify(overallThreatCountsLast30Days)),
        };
        
  
        querySnapshot.forEach((doc) => {
          const report = doc.data();
          const reportDate = new Date(report.timestamp.seconds * 1000); // ✅ Convert Firestore timestamp properly
        
          console.log(`Processing report: ${report.title}, Date: ${reportDate}`);
        
          const sanitizedThreatType = sanitizeInput(report.threatType);
        
          if (sanitizedThreatType && overallThreatCountsLast30Days[sanitizedThreatType] !== undefined) {
            overallThreatCountsLast30Days[sanitizedThreatType] += 1;
          }
        
          // ✅ Fix month categorization
          let monthKey = null;
          if (reportDate >= currentMonthStart) {
            monthKey = 'currentMonth';
          } else if (reportDate >= lastMonthStart && reportDate < currentMonthStart) {
            monthKey = 'lastMonth';
          } else if (reportDate >= twoMonthsAgoStart && reportDate < lastMonthStart) {
            monthKey = 'twoMonthsAgo';
          }
        
          // ✅ Debugging to verify correct month assignment
          console.log(`Report Date: ${reportDate}`);
          console.log(`Month Cutoffs:`);
          console.log(`Current Month Start: ${currentMonthStart}`);
          console.log(`Last Month Start: ${lastMonthStart}`);
          console.log(`Two Months Ago Start: ${twoMonthsAgoStart}`);
          if (monthKey) {
            console.log(`Assigned to: ${monthKey}`);
            monthlyCounts[monthKey][sanitizedThreatType] += 1;
          } else {
            console.warn(`⚠ Report ${report.title} not assigned to any month!`);
          }
        });
        

        console.log("Final Monthly Data:", monthlyCounts);
      
  
        // Prepare the data for the bar chart for the last 30 days
        setReportData({
          labels: Object.keys(overallThreatCountsLast30Days),
          datasets: [
            {
              label: 'Number of Threats',
              data: Object.values(overallThreatCountsLast30Days),
              backgroundColor: Object.keys(overallThreatCountsLast30Days).map((label) => getColor(label)),
            },
          ],
        });
  
        // Prepare data for the pie chart
        setPieData({
          labels: Object.keys(overallThreatCountsLast30Days),
          datasets: [
            {
              data: Object.values(overallThreatCountsLast30Days),
              backgroundColor: Object.keys(overallThreatCountsLast30Days).map((label) => getColor(label)),
            },
          ],
        });
  
        // Prepare monthly data for the last three months
        setMonthlyData([
          {
            month: today.toLocaleString('default', { month: 'long', year: 'numeric' }),
            data: generateMonthlyDataset(monthlyCounts.currentMonth),
          },
          {
            month: lastMonthStart.toLocaleString('default', { month: 'long', year: 'numeric' }),
            data: generateMonthlyDataset(monthlyCounts.lastMonth),
          },
          {
            month: twoMonthsAgoStart.toLocaleString('default', { month: 'long', year: 'numeric' }),
            data: generateMonthlyDataset(monthlyCounts.twoMonthsAgo),
          },
        ]);
      } catch (error) {
        console.error('Error fetching reports:', error);
        setErrorMessage('Error fetching reports. Please try again later.');
      }
    };

    fetchReports();
  }, [currentUser]);
  
  // Function to sanitize user input (threatType)
  const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input);
  };

  // Function to get color based on threat type (ensuring consistency between charts)
  const getColor = (threatType) => {
    const colors = {
      Phishing: '#FF6384',
      'Spear Phishing': '#36A2EB',
      Malware: '#FFCE56',
      Ransomware: '#FF9F40',
      Smishing: '#4BC0C0',
      Vishing: '#9966FF',
      'Social Engineering': '#4CAF50',
      'Insider Threat': '#FFC107',
    };
    return colors[threatType] || '#000000';
  };

  // Function to generate a dataset for a specific month
  const generateMonthlyDataset = (monthlyCounts) => {
    console.log("Generating dataset for:", monthlyCounts); // Debugging
    
    const labels = Object.keys(monthlyCounts);
    const data = labels.map((label) => monthlyCounts[label]);
  
    return {
      labels,
      datasets: [
        {
          label: 'Number of Threats',
          data,
          backgroundColor: labels.map((label) => getColor(label)),
        },
      ],
    };
  };
  

  return (
    <div className="ThreatStats-container">
      <h1 id="ThreatStatsHeading" tabIndex="-1">Threat Statistics</h1>
  
      {errorMessage && <p className="error-message" role="alert">{errorMessage}</p>}
  
      {/* Bar Chart Section */}
      <div className="ThreatStats-chart-container">
        <h2 className="ThreatStats-title" id="bar-chart-title">Threats Over the Last 30 Days</h2>
        <p id="bar-chart-desc" className="visually-hidden">
          This bar chart displays the number of different cybersecurity threats reported in the last 30 days.
        </p>
        <Bar
          data={reportData}
          options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
              y: {
                beginAtZero: true,
                title: { display: true, text: 'Number of Threats' },
              },
              x: {
                title: { display: true, text: 'Threat Type' },
                ticks: { autoSkip: false },
              },
            },
            plugins: { legend: { display: false } },
          }}
          aria-labelledby="bar-chart-title"
          aria-describedby="bar-chart-desc"
        />
      </div>
  
      {/* Pie Chart Section */}
      <div className="ThreatStats-pie-container">
        <h2 id="pie-chart-title">Threat Type Distribution of the Past 30 Days</h2>
        <p id="pie-chart-desc" className="visually-hidden">
          This pie chart shows the percentage distribution of different cybersecurity threats reported in the past 30 days.
        </p>
        <Pie
          data={pieData}
          options={{ responsive: true, plugins: { legend: { position: 'right' } } }}
          aria-labelledby="pie-chart-title"
          aria-describedby="pie-chart-desc"
        />
      </div>
  
      <hr className="ThreatStats-divider" />
  
      {/* Monthly Data Charts */}
      <div className="ThreatStats-monthly-charts">
        <h2>Threats for the latest three months (Graphs will be overwritten over three months old)</h2>
        {monthlyData.map((monthData, index) => (
          <div key={index} className="ThreatStats-chart-container">
            <h2 id={`monthly-chart-title-${index}`}>Threats in {sanitizeInput(monthData.month)}</h2>
            <p id={`monthly-chart-desc-${index}`} className="visually-hidden">
              This bar chart displays the number of different cybersecurity threats reported in {sanitizeInput(monthData.month)}.
            </p>
            <Bar
              data={monthData.data}
              options={{
                responsive: true,
                scales: {
                  y: { beginAtZero: true, title: { display: true, text: 'Number of Threats' } },
                  x: { title: { display: true, text: 'Threat Type' } },
                },
                plugins: { legend: { display: false } },
              }}
              aria-labelledby={`monthly-chart-title-${index}`}
              aria-describedby={`monthly-chart-desc-${index}`}
            />
          </div>
        ))}
      </div>
    </div>
  );
  
};

export default ThreatStats;
