import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay'; // Import your logo overlay
import '../Page3/RecognizingThreats.css'; // Existing CSS
// You can also create/use a separate CSS file for .TabsSafeBrowsing and accordion styling if desired.

const SafeBrowsing = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);

  // Track whether the video was fully watched
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState('');
  const [lastValidTime, setLastValidTime] = useState(0);

  // Track which tabs have been visited
  const [visitedTabs, setVisitedTabs] = useState([]);
  const TOTAL_TABS = 4; // We have 4 accordion items

  // This function checks if the user can proceed
  const canProceed = isVideoWatched && visitedTabs.length === TOTAL_TABS;

  // When a tab is opened, record it as visited
  const handleTabVisit = (index) => {
    if (!visitedTabs.includes(index)) {
      setVisitedTabs((prev) => [...prev, index]);
    }
  };

  // "Next" button logic
  const goToNext = () => {
    if (!canProceed) {
      if (!isVideoWatched) {
        setShowWarning('You must watch the video before proceeding.');
      } else {
        setShowWarning('You must click through all the tabs before proceeding.');
      }
    } else {
      setShowWarning('');
      navigate('/beginnerscourse/page5/safe-browsing-part-two#BasicsTitle');
    }
  };

  // Handle end of video
  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning('');
  };

  // Prevent skipping in the video
  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  // Attach/remove the timeupdate event for skip prevention
  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  // Scroll to "BasicsTitle" if the URL hash is present
  useEffect(() => {
    if (location.hash === '#BasicsTitle') {
      const element = document.querySelector('.BasicsTitle');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);

  // Go back to previous page
  const goBack = () => {
    navigate('/beginnerscourse/page4.5/two-factor-auth#BasicsTitle');
  };

  return (
    <div className="introduction-container2">
      {/* Back Button */}
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Two-Factor Authentication Part Two"
      >
        ←
      </button>

      <h1 id="BasicsTitle" className="BasicsTitle">
        Safe Browsing
      </h1>

      {/* Video Section */}
      <div className="video-container" style={{ position: 'relative' }}>
        <LogoOverlay /> {/* Logo overlay component */}
        <video
          ref={videoRef}
          src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FC1SafeBrowsing%20(1).mp4?alt=media&token=25932908-b3b0-4ca0-9d01-574c83221ebe"
          width="100%"
          onEnded={handleVideoEnd}
          onError={(e) => console.error("Video Error:", e)}
          controls
          controlsList="nodownload noremoteplayback noplaybackrate"
          disablePictureInPicture
          aria-label="Educational video on Safe Browsing"
        >
          Your browser does not support the video tag.
        </video>
      </div>

      <section className="module-section" style={{ textAlign: 'center' }}>
        <h2
          style={{
            color: 'black',
            textAlign: 'left',
            fontWeight: '500',
            fontSize: '2rem'
          }}
        >
          Using Secure Connections
        </h2>
        <br />
      </section>

      {/* Tabs/Accordion Section */}
      <div className="TabsSafeBrowsing">
        <SafeBrowsingAccordion onTabVisit={handleTabVisit} />
      </div>

      {/* Next Button & Warnings */}
      <div className="button-container">
        <button
          className="next-button"
          onClick={goToNext}
          aria-disabled={!canProceed}
          aria-label="Proceed to Safe Browsing Part Two"
        >
          Next
        </button>
        {showWarning && (
          <p className="warning-text22" role="alert">
            {showWarning}
          </p>
        )}
      </div>
    </div>
  );
};

export default SafeBrowsing;

/* -----------------------------------------
   Accordion Component for the 4 Tabs
   ----------------------------------------- */

const accordionData = [
  {
    title: 'What is HTTPS?',
    content: (
      <>
        <p>
          HTTPS stands for HyperText Transfer Protocol Secure. It ensures that
          the data exchanged between your browser and a website is encrypted,
          making it harder for hackers to intercept sensitive information.
        </p>
        <p>
          Always look for "https" at the beginning of a website's URL to confirm
          that the connection is secure. This is a fundamental step in
          protecting your online activities.
        </p>
      </>
    )
  },
  {
    title: 'The Importance of the Padlock Icon',
    content: (
      <>
        <p>
          The padlock icon next to a website's URL indicates that the connection
          is encrypted and secure. This visual cue helps users identify websites
          that prioritize data protection.
        </p>
        <p>
          However, while the padlock is a good sign, it is not a guarantee of
          complete safety. Always combine this check with other verification
          methods to ensure the website's legitimacy.
        </p>
      </>
    )
  },
  {
    title: 'Limitations of HTTPS',
    content: (
      <>
        <p>
          Although HTTPS enhances security, it does not eliminate all risks.
          Scammers and malicious websites can also use HTTPS to appear
          trustworthy, potentially misleading users.
        </p>
        <p>
          For example, a fraudulent email might link to a fake website that uses
          HTTPS. Always verify the domain name carefully and avoid clicking on
          unsolicited links to stay safe.
        </p>
      </>
    )
  },
  {
    title: 'Tips for Verifying Website Legitimacy',
    content: (
      <>
        <p>
          To ensure a website is legitimate, type the domain directly into your
          browser instead of clicking on links from unknown sources. This
          reduces the risk of being redirected to a fake site.
        </p>
        <p>
          Additionally, use trusted URL checkers to verify links and assess the
          website’s authenticity. These steps can help protect your personal
          information from being compromised.
        </p>
      </>
    )
  }
];

const SafeBrowsingAccordion = ({ onTabVisit }) => {
  const [openIndices, setOpenIndices] = useState([]);

  // Toggle each accordion item open/close
  const toggleItem = (index) => {
    if (openIndices.includes(index)) {
      // Close if already open
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      // Open and record the visit
      setOpenIndices([...openIndices, index]);
      if (onTabVisit) {
        onTabVisit(index);
      }
    }
  };

  return (
    <div>
      {accordionData.map((item, index) => {
        const isOpen = openIndices.includes(index);

        return (
          <div
            key={index}
            className={`accordion-item ${isOpen ? 'open' : ''}`}
            style={{
              margin: '1rem 0',
              border: '1px solid #ccc',
              borderRadius: '6px',
              overflow: 'hidden'
            }}
          >
            {/* Header */}
            <button
              className="accordion-header"
              onClick={() => toggleItem(index)}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                background: '#fff',
                border: 'none',
                cursor: 'pointer',
                padding: '1rem',
                fontSize: '1.1rem',
                fontWeight: 'bold',
                textAlign: 'left',
                outline: 'none',
                borderLeft: isOpen ? '4px solid red' : '4px solid transparent',
                transition: 'border-left 0.3s ease',
                color: 'black'
              }}
            >
              {item.title}
              <span style={{ fontSize: '1.5rem', color: 'black' }}>
                {isOpen ? '−' : '+'}
              </span>
            </button>

            {/* Content */}
            {isOpen && (
              <div
                className="accordion-content"
                style={{
                  padding: '0 1rem 1rem 1rem',
                  backgroundColor: 'white',
                  fontSize: '1.2rem',
                  color: 'black'
                }}
              >
                {item.content}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
