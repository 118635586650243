import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import '../ModuleOne/WhatIsCyberSecurity.css';

const ReportingProceduresQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(6).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(6).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(6).fill(null));
  const [showStar, setShowStar] = useState(false);
  const auth = getAuth();

  const questions = [
    {
      question: "Why is it important to report security incidents promptly?",
      options: [
        "To punish employees who make mistakes",
        "To allow the organisation to respond quickly and minimise damage",
        "To improve overall employee morale",
        "To delay the response to avoid panic",
      ],
      correctAnswer: "To allow the organisation to respond quickly and minimise damage",
    },
    {
      question: "What is one key benefit of timely incident reporting?",
      options: [
        "It helps increase software performance",
        "It reduces the impact on the organisation's operations",
        "It allows employees more time to consider their response",
        "It improves relationships with external vendors",
      ],
      correctAnswer: "It reduces the impact on the organisation's operations",
    },
    {
      question: "Which of the following is a correct procedure for reporting incidents?",
      options: [
        "Discussing the incident informally with colleagues",
        "Using the designated tools or platforms provided by the organisation",
        "Ignoring the incident until it becomes a bigger issue",
        "Sharing the incident details on social media",
      ],
      correctAnswer: "Using the designated tools or platforms provided by the organisation",
    },
    {
      question: "Why should incidents be reported confidentially when necessary?",
      options: [
        "To avoid unnecessary paperwork",
        "To protect sensitive information, especially about insider threats",
        "To prevent the IT department from being overwhelmed",
        "To reduce the number of incidents reported",
      ],
      correctAnswer: "To protect sensitive information, especially about insider threats",
    },
    {
      question: "What is a crucial reason for timely reporting according to compliance?",
      options: [
        "It helps meet regulatory obligations like GDPR",
        "It allows for a leisurely response process",
        "It ensures employees have ample time to review the incident",
        "It keeps management from being disturbed",
      ],
      correctAnswer: "It helps meet regulatory obligations like GDPR",
    },
    {
      question: "How does early detection and reporting help prevent future incidents?",
      options: [
        "By enabling the organisation to address vulnerabilities",
        "By making the reporting process faster each time",
        "By reducing the need for incident investigations",
        "By ensuring employees do not have to deal with the incident again",
      ],
      correctAnswer: "By enabling the organisation to address vulnerabilities",
    },
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect =
        answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? "correct" : "incorrect";
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] =
          shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 4) {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, "users", user.uid);
          await updateDoc(userDocRef, {
            "progress.course3.module4_quiz2": true,
          });
          setShowStar(true);
        }
      } catch (error) {
        console.error("Error updating user progress:", error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(6).fill(null));
    setFeedback(Array(6).fill(null));
    setCorrectAnswers(Array(6).fill(null));
    setShowResults(false);
    setScore(0);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate("/businessacademy/modulefive/remote-work-security#RemoteWorkSecurityHeading");
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star" role="alert" aria-live="polite"></div>}
      <section
        className="module-section2"
        role="region"
        aria-labelledby="quiz-title"
      >
        <h2 id="quiz-title" tabIndex="0">
          Reporting Procedures Quiz
        </h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={
                  feedback[currentQuestionIndex] ? "feedback" : undefined
                }
              >
                <legend
                  id={`question-${currentQuestionIndex}`}
                  tabIndex="0"
                  aria-live="polite"
                  aria-label={`Question ${
                    currentQuestionIndex + 1
                  }: ${shuffledQuestions[currentQuestionIndex]?.question}`}
                >
                  {shuffledQuestions[currentQuestionIndex]?.question}
                </legend>
                {shuffledQuestions[currentQuestionIndex]?.options.map(
                  (option, index) => (
                    <div key={index} className="quiz-option">
                      <label
                        className="quiz-option-label"
                        htmlFor={`option-${currentQuestionIndex}-${index}`}
                      >
                        <input
                          type="radio"
                          id={`option-${currentQuestionIndex}-${index}`}
                          name={`question-${currentQuestionIndex}`}
                          value={option}
                          checked={answers[currentQuestionIndex] === option}
                          onChange={() => handleAnswerChange(option)}
                          disabled={feedback[currentQuestionIndex] !== null}
                          aria-checked={
                            answers[currentQuestionIndex] === option
                          }
                        />
                        <span className="option-text">{option}</span>
                      </label>
                    </div>
                  )
                )}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  role="alert"
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === "correct"
                    ? "Correct!"
                    : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                </div>
              )}
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 6</p>
              <br></br>
              {score >= 4 ? (
                <p>
                  Congratulations! You passed the quiz, you now have a solid
                  understanding of reporting procedures.
                </p>
              ) : (
                <p>You need to score at least 4 to pass. Please retake the quiz.</p>
              )}
              <div className="quiz-buttons">
                {score >= 4 ? (
                  <button className="next-button" onClick={goToNext}>
                    Next
                  </button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default ReportingProceduresQuiz;
