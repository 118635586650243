import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './SocialMediaDropDragGame.css';

const initialTerms = [
  { id: 1, text: "Set your profile visibility to friends only", category: "good" },
  { id: 2, text: "Post your home address publicly", category: "bad" },
  { id: 3, text: "Enable two-factor authentication", category: "good" },
  { id: 4, text: "Use weak passwords", category: "bad" },
  { id: 5, text: "Verify sender's address before opening email links", category: "good" },
  { id: 6, text: "Share travel plans in real-time", category: "bad" },
  { id: 7, text: "Update privacy settings to hide personal details", category: "good" },
  { id: 8, text: "Accept friend requests from unknown people", category: "bad" },
  { id: 9, text: "Clicking on links in the comment section", category: "bad" },
  { id: 10, text: "Click on suspicious links", category: "bad" },
  { id: 11, text: "Think twice before you post", category: "good" },
  { id: 12, text: "Share sensitive personal information publicly", category: "bad" }
];

const SocialMediaDropDragGame = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Terms that haven't been placed yet.
  const [unsortedTerms, setUnsortedTerms] = useState(initialTerms);
  // Sorted terms per category.
  const [sortedTerms, setSortedTerms] = useState({ good: [], bad: [] });
  // The currently selected term (if any).
  const [selectedTerm, setSelectedTerm] = useState(null);

  // Scroll to the game section if URL hash is present.
  useEffect(() => {
    if (location.hash === '#DragAndDropGame') {
      const element = document.querySelector('h2');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location.hash]);

  // Toggle selection when user clicks a term
  const handleTermClick = (term) => {
    if (selectedTerm && selectedTerm.id === term.id) {
      setSelectedTerm(null);
    } else {
      setSelectedTerm(term);
    }
  };

  // Place the selected term into Good or Bad if it matches
  const handleCategoryClick = (category) => {
    if (!selectedTerm) return;

    if (selectedTerm.category === category) {
      setSortedTerms((prev) => ({
        ...prev,
        [category]: [...prev[category], selectedTerm],
      }));
      setUnsortedTerms((prev) => prev.filter((t) => t.id !== selectedTerm.id));
      setSelectedTerm(null);
    } else {
      alert("Incorrect placement! Please select the correct category.");
      setSelectedTerm(null);
    }
  };

  // Navigation
  const goBack = () => {
    navigate('/beginnerscourse/page6/social-media-safety#SocialMediaSafety');
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page6/social-media-safety-quiz');
  };

  // Check if all terms have been sorted
  const isAllSortedCorrectly = unsortedTerms.length === 0;

  return (
    <div className="drop-drag-game-container" role="main" aria-label="Social Media Safety Game">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Social Media Safety Part Two"
      >
        ←
      </button>
      <h1 className="GameTitle" tabIndex={0} aria-label="Social Media Safety Game">
        Social Media Safety Game
      </h1>
      <p id="gametext" className="gametext">
        Click a statement to select it, then choose whether it belongs in the Good or Bad container.
      </p>

      <div className="game-areas">
        {/* Unsorted Terms Area */}
        <div className="terms-container">
          {unsortedTerms.map(term => (
            <div
              key={term.id}
              className={`term ${selectedTerm && selectedTerm.id === term.id ? 'selected' : ''}`}
              onClick={() => handleTermClick(term)}
              role="button"
              tabIndex={0}
              aria-label={`Unsorted term: ${term.text}`}
            >
              {term.text}
            </div>
          ))}
        </div>

        {/* Categories: Good and Bad */}
        <div className="categories-container">
          <div
            className={`category good ${selectedTerm ? 'highlight' : ''}`}
            onClick={() => handleCategoryClick("good")}
            role="region"
            aria-label="Good category"
            tabIndex={0}
          >
            <h3>Good</h3>
            {sortedTerms.good.map(term => (
              <div
                key={term.id}
                className="term good-term"
                role="listitem"
                tabIndex={0}
                aria-label={`Good term: ${term.text}`}
              >
                {term.text}
              </div>
            ))}
          </div>
          <div
            className={`category bad ${selectedTerm ? 'highlight' : ''}`}
            onClick={() => handleCategoryClick("bad")}
            role="region"
            aria-label="Bad category"
            tabIndex={0}
          >
            <h3>Bad</h3>
            {sortedTerms.bad.map(term => (
              <div
                key={term.id}
                className="term bad-term"
                role="listitem"
                tabIndex={0}
                aria-label={`Bad term: ${term.text}`}
              >
                {term.text}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Modal Overlay when game is complete */}
      {isAllSortedCorrectly && (
        <div className="modal-overlay" role="dialog" aria-modal="true">
          <div className="modal-content">
            <h2>Congratulations!</h2>
            <p>You have sorted all the terms correctly.</p>
            <button
              className="next-button"
              onClick={goToNext}
              aria-label="Go to the Social Media Safety Quiz"
            >
              Next
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SocialMediaDropDragGame;
