import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './CybersecurityBasics.css';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';

const FlipCard = ({ frontText, backText, onFlip }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleCardClick = () => {
    // Call onFlip only the first time the card is flipped
    if (!isFlipped && typeof onFlip === 'function') {
      onFlip();
    }
    setIsFlipped(!isFlipped);
  };

  return (
    <div className={`CardFlip ${isFlipped ? 'flipped' : ''}`} onClick={handleCardClick}>
      <div className="card-inner">
        <div className="card-front">
          <p>{frontText}</p>
        </div>
        <div className="card-back">
          <p>{backText}</p>
        </div>
      </div>
    </div>
  );
};

const CybersecurityBasics = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const basicsTitleRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const [lastValidTime, setLastValidTime] = useState(0);

  // States to track if each flip card was clicked
  const [card1Clicked, setCard1Clicked] = useState(false);
  const [card2Clicked, setCard2Clicked] = useState(false);

  const goToQuiz = () => {
    if (!isVideoWatched) {
      setWarningMessage('You must watch the video before proceeding.');
    } else if (!card1Clicked || !card2Clicked) {
      setWarningMessage('You must click both flip cards before proceeding.');
    } else {
      setWarningMessage('');
      navigate('/beginnerscourse/page2/cybersecurity-quiz');
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setWarningMessage('');
    setProgress(100);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }

    const progressPercent = (currentTime / duration) * 100;
    setProgress(progressPercent);
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/courses-two');
  };

  useEffect(() => {
    if (location.hash === '#BasicsTitle' && basicsTitleRef.current) {
      basicsTitleRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div className="introduction-container2" role="main" aria-label="Cybersecurity Basics">
      <button className="back-arrow-button" onClick={goBack} aria-label="Go back to Introduction page">
        ←
      </button>

      <h1 id="BasicsTitle" ref={basicsTitleRef} className="BasicsTitle" tabIndex={0} aria-label="What is Cybersecurity?">
        What is Cybersecurity?
      </h1>

      <section className="module-section" aria-labelledby="BasicsTitle" role="region" aria-label="Cybersecurity Basics Content">
        <div className="content-layout">
          <div className="video-container" id="video-container" style={{ position: 'relative' }}>
            <LogoOverlay />
            <video
              ref={videoRef}
              src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FCourse1Module1%20(1).mp4?alt=media&token=293b0850-d62b-464c-9d51-6ce8e3058d23"
              width="100%"
              onEnded={handleVideoEnd}
              onError={(e) => console.error("Video Error:", e)}
              controls
              controlsList="nodownload noremoteplayback noplaybackrate"
              disablePictureInPicture
              aria-label="Introduction to Cybersecurity Basics"
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="module-content" role="region" aria-label="Cybersecurity Basics Information">
          <div className="bordered-container">
            <div className="flip-card-container">
              <FlipCard 
                frontText="What is Cybersecurity?" 
                backText="Cybersecurity refers to the practice of protecting systems, networks, and programs from digital attacks." 
                onFlip={() => setCard1Clicked(true)}
              />
              <FlipCard 
                frontText="Why is Cybersecurity Important?" 
                backText="Cybersecurity is crucial to protect sensitive information, prevent data breaches, and maintain privacy." 
                onFlip={() => setCard2Clicked(true)}
              />
            </div>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button className="start-quiz-button" onClick={goToQuiz} aria-label="Start Cybersecurity Quiz">
            Start Quiz
          </button>
          {warningMessage && (
            <p className="warning-text22" role="alert" aria-live="assertive">
              {warningMessage}
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default CybersecurityBasics;
