import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';

const EmployeeRole = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const employeeRoleRef = useRef(null);
  const warningRef = useRef(null);

  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (isVideoWatched) {
      navigate('/businessacademy/moduleone/employee-role-quiz');
    } else {
      setShowWarning(true);
      warningRef.current?.focus();
    }
  };

  const goBack = () => {
    navigate('/businessacademy/moduleone/statistics#CurrentThreatLandscape');
  };

  useEffect(() => {
    if (location.hash === '#EmployeeRoleHeading' && employeeRoleRef.current) {
      employeeRoleRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container" role="main">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the previous page"
      >
        ←
      </button>

      <div className="cybersecurity-basics-header">
        <h1 ref={employeeRoleRef} id="EmployeeRoleHeading" tabIndex="0">
          Employee's Role in Protecting the Organisation
        </h1>
      </div>

      <section className="module-section" role="region" aria-labelledby="EmployeeRoleHeading">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FEmployeesRole.mp4?alt=media&token=7f05fa65-954f-4058-b612-c25a2e39e2c9"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="Employee's role in cybersecurity training video"
          >
            
            Your browser does not support the video tag.
          </video>
        </div>

        <h2>Employee's Responsibilities</h2>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Importance of Using Strong, Unique Passwords</h3>
            <p>
              You should create passwords that are complex and unique for each account, incorporating a mix of letters, numbers, and special characters or using a password manager. This reduces the risk of password guessing and hacking.
            </p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Recognising and Reporting Phishing Attempts</h3>
            <p>
              This training will give you the knowledge to help identify phishing emails, such as those with suspicious links, attachments, or urgent requests for personal information. You should know how to report these attempts to the IT department immediately.
            </p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Securing Devices and Ensuring Software is Up-to-Date</h3>
            <p>
              You should regularly update your device and software to protect against vulnerabilities. This includes operating systems, applications, and antivirus software.
            </p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Following the Company's Cybersecurity Policies and Guidelines</h3>
            <p>
              It's essential for you to adhere to established cybersecurity policies, including acceptable use policies, data handling procedures, and guidelines for remote work.
            </p>
          </div>
        </div>

        <h2>Incident Reporting</h2>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Identifying Potential Security Incidents</h3>
            <p>
              If you recognise signs of a security breach, such as unusual system behaviour, unexpected software installations, or unauthorised access alerts, you should report it immediately to the IT department or relevant parties.
            </p>
          </div>
        </div>

        <div className="module-content">
          <div className="highlighted-section">
            <h3>Reporting Incidents</h3>
            <p>
              You need to know the specific steps for reporting incidents, including whom to contact and what information to provide. Quick reporting can help contain and mitigate the impact of a breach.
            </p>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button
            className="start-quiz-button"
            onClick={handleNextClick}
            aria-disabled={!isVideoWatched}
          >
            Start Quiz
          </button>

          {showWarning && (
            <p
              className="warning-text"
              ref={warningRef}
              role="alert"
              tabIndex="0"
            >
              You must watch the entire video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default EmployeeRole;
