import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './RecognizingThreats.css';

const RecognizingThreatsQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(7).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(7).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(7).fill(null));
  const [authChecked, setAuthChecked] = useState(false);
  const [user, setUser] = useState(null);
  const [showStar, setShowStar] = useState(false);
  const location = useLocation();

  const questions = [
    {
      question: "What is phishing?",
      options: [
        "All your files being locked",
        "Fraudulent emails or messages",
        "A way to encrypt communications",
        "Malicious software"
      ],
      correctAnswer: "Fraudulent emails or messages"
    },
    {
      question: "Which of the following is a common sign of a phishing attempt?",
      options: [
        "A message with clear, formal language and correct grammar",
        "Unexpected urgency pressures you into making quick decisions",
        "Files locked until a ransom is paid",
       
      ],
      correctAnswer: "Unexpected urgency pressures you into making quick decisions"
    },
    {
      question: "What is the primary goal of ransomware?",
      options: [
        "To decrease computer performance",
        "To lock files until a fee is paid",
        "To stop automatic updates on your software",
        "To trick you to click on a malicious link"
      ],
      correctAnswer: "To lock files until a fee is paid"
    },
    {
      question: "How does social engineering typically work?",
      options: [
        "By automatically installing malware on your device",
        "Manipulating you to reveal confidential information",
        "By entering business premisies for malicious purposes",
        "By encrypting your data so you no longer have access"
      ],
      correctAnswer: "Manipulating you to reveal confidential information"
    },
    {
      question: "What does impersonation in cybersecurity refer to?",
      options: [
        "Gaining access through multiple logins simultaneously",
        "Attackers mimic trusted figures to prompt unsafe actions",
        "Using your own credentials in various applications",
        
      ],
      correctAnswer: "Attackers mimic trusted figures to prompt unsafe actions"
    },
    {
      question: "Why should you verify requests for sensitive data?",
      options: [
        "You shouldn't as this takes too much time",
        "In case the request is from a malicious actor",
        "Because trusted sources never ask for information",
       
      ],
      correctAnswer: "In case the request is from a malicious actor"
    },
    {
      question: "Which of the following best describes a cyber threat?",
      options: [
        "System errors",
        "Harmful acts undermining security",
        "Poorly designed networks",
        "Software updates causing delays"
      ],
      correctAnswer: "Harmful acts undermining security"
    }
    
  ];
  


  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user || null); // Allows unauthenticated users to take the quiz
      setAuthChecked(true);
    });
  
    return () => unsubscribe();
  }, []);
  

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  useEffect(() => {
    if (location.hash === '#QuizTitle') {
      const element = document.getElementById('QuizTitle');
      if (element) {
        const offset = element.getBoundingClientRect().top + window.pageYOffset - 100;
        window.scrollTo({ top: offset, behavior: 'smooth' });
      }
    }
  }, [location]);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);
      const newFeedback = [...feedback];
      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 6) {
      if (user) {
        try {
          const userDocRef = doc(firestore, 'users', user.uid);
          await updateDoc(userDocRef, {
            'progress.course1.module2': true,
          });
          setShowStar(true);
        } catch (error) {
          console.error('Error updating user profile: ', error);
        }
      }
    }
    
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(7).fill(null));
    setFeedback(Array(7).fill(null));
    setCorrectAnswers(Array(7).fill(null));
    setShowResults(false);
    setScore(0);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate("/beginnerscourse/page4/strong-passwords-part-three#BasicsTitle");
  
    // Ensure the next page will scroll correctly
    setTimeout(() => {
      const target = document.getElementById("BasicsTitle");
      if (target) {
        target.scrollIntoView({ behavior: "smooth" });
      } else {
        window.location.href = "/beginnerscourse/page4/strong-passwords-part-three#BasicsTitle";
      }
    }, 500);
  };
  

  const goBack = () => {
    navigate('/beginnerscourse/page3/recognizing-threats#BasicsTitle');
  };

  if (!authChecked || shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      
      <br />
      {showStar && <div className="falling-star" aria-hidden="true"></div>}
      <section className="module-section2" role="region" aria-labelledby="QuizTitle">
        <h2 id="QuizTitle">Recognising Cyber Threats Quiz</h2>
        
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={feedback[currentQuestionIndex] ? 'feedback' : undefined}
              >
                <legend className="quiz-legend">
                  Question {currentQuestionIndex + 1}: {shuffledQuestions[currentQuestionIndex].question}
                </legend>
                {shuffledQuestions[currentQuestionIndex].options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                        aria-checked={answers[currentQuestionIndex] === option}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
              </fieldset>
              <div
                id="feedback"
                className={`feedback ${feedback[currentQuestionIndex] || ''}`}
                aria-live="polite"
              >
                {feedback[currentQuestionIndex] === 'correct'
                  ? 'Correct!'
                  : feedback[currentQuestionIndex] === 'incorrect'
                  ? `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`
                  : ''}
              </div>
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                    aria-label="Go to the next question"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                    aria-label="Submit your answers"
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results" role="region" aria-labelledby="results-title">
              <h2 id="results-title">Results</h2>
              <p>Your score: {score} / 7</p>
              <br></br>
              {score >= 6 ? (
  <div className="completion-message">
    {user ? (
      <p>
        🎉 Congratulations! You've passed the quiz. Now are better prepared to recognise cyber threats! 
      </p>
    ) : (
      <p>
        🎉 Well done! You have completed the quiz. To track your progress and save your results,
        <strong> sign up for an account.</strong>
      </p>
    )}
  </div>
) : (
  <p>You need to score at least 6 to pass. Please retake the quiz.</p>
)}

              <div className="quiz-buttons">
                {score >= 6 ? (
                  <button className="next-button" onClick={goToNext} aria-label="Go to the next section">
                    Next
                  </button>
                ) : (
                  <button className="retake-button" onClick={handleRetake} aria-label="Retake the quiz">
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default RecognizingThreatsQuiz;
