import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const CreditProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const creditProfileRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const scenarioSteps = [
    {
      title: "Step 1: Enrolling in Credit Monitoring",
      text: "John subscribes to a trusted UK credit monitoring service like Experian, ensuring he keeps a close eye on his credit activity."
    },
    {
      title: "Step 2: Alert of Suspicious Activity",
      text: "One morning, John receives an immediate alert about an unexpected credit enquiry and a new account application in his name."
    },
    {
      title: "Step 3: Immediate Verification",
      text: "Alarmed, John logs into his dashboard and confirms he did not authorise the enquiry, then contacts his bank and credit reference agency."
    },
    {
      title: "Step 4: Activation of a Fraud Alert",
      text: "John quickly places a fraud alert on his credit file, ensuring lenders take extra steps to verify his identity before approving new credit."
    },
    {
      title: "Step 5: Preventing Further Fraud",
      text: "With the fraud alert in place, unauthorised credit applications are halted, protecting his financial identity from further compromise."
    },
    {
      title: "Step 6: Thwarting the Cyber Attack",
      text: "Thanks to his proactive measures, the attempted fraud is intercepted before any financial loss occurs."
    },
    {
      title: "Step 7: Ongoing Vigilance",
      text: "John continues to monitor his credit regularly and maintains robust security practices, ensuring long-term protection against cyber threats."
    }
  ];

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleScenarioNext = () => {
    if (currentStepIndex < scenarioSteps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
      setShowWarning(false);
    }
  };

  const handleScenarioPrevious = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
      setShowWarning(false);
    }
  };

  const handleNextClick = () => {
    // User cannot proceed until the video is fully watched and all scenario steps have been viewed.
    if (!isVideoWatched || currentStepIndex < scenarioSteps.length - 1) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module8/credit-report-quiz');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module7/data-backup-part-two#DataBackupPlanHeading');
  };

  useEffect(() => {
    if (location.hash === '#CreditProfileHeading' && creditProfileRef.current) {
      creditProfileRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container">
      <button 
        className="back-arrow-button" 
        onClick={goBack} 
        aria-label="Go back to data backup section"
      >
        ←
      </button>

      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header centered-header">
              <h1 ref={creditProfileRef}>Credit Profile</h1>
            </div>
            <div className="video-container">
              <video
                ref={videoRef}
                src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FBoost%20Your%20Cybersecurity%20with%20a%20Strong%20Credit%20Profile.mp4?alt=media&token=8571129d-ef83-449f-908d-44c8de1132ff"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                playsInline
                aria-label="Credit Profile video"
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        {/* Interactive Scenario Section */}
        <section className="scenario-container">
          <h2>Scenario: Thwarting a Cyber Attack with a Robust Credit Profile (UK Edition)</h2>
          <div className="scenario-step">
            <h3>{scenarioSteps[currentStepIndex].title}</h3>
            <p>{scenarioSteps[currentStepIndex].text}</p>
          </div>
          <div className="scenario-navigation">
            {currentStepIndex > 0 && (
              <button 
                onClick={handleScenarioPrevious} 
                className="scenario-nav-button scenario-nav-button-C2M3" 
                aria-label="Previous Step"
              >
                &#8592;
              </button>
            )}
            {currentStepIndex < scenarioSteps.length - 1 && (
              <button 
                onClick={handleScenarioNext} 
                className="scenario-nav-button scenario-nav-button-C2M3" 
                aria-label="Next Step"
              >
                &#8594;
              </button>
            )}
            {currentStepIndex === scenarioSteps.length - 1 && (
              <p className="scenario-complete-message">You have completed the scenario.</p>
            )}
          </div>
        </section>

        <div className="start-quiz-button-container">
          <button 
            className="start-quiz-button1" 
            onClick={handleNextClick} 
            aria-label="Proceed to the credit report quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video and complete all scenario steps before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default CreditProfile;
