import { Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebase'; // Update path if needed
import { useEffect, useState } from 'react';

const UserRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [allowed, setAllowed] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const userProfileDoc = await getDoc(doc(firestore, 'users', user.uid));
          
          if (userProfileDoc.exists()) {
            const userProfileData = userProfileDoc.data();
            const accountType = userProfileData.profile?.accountType;

            // Allow access if the accountType is 'standard', 'manager', or 'admin'
            if (['standard', 'manager', 'admin'].includes(accountType)) {
              setAllowed(true);
            } else {
              throw new Error("Unauthorized access");  // Handle users with invalid account types
            }
          } else {
            throw new Error("User profile not found");
          }
        } catch (error) {
          console.error('Error fetching user profile or unauthorized access:', error);

          // Logout user and redirect to login if an error occurs
          await signOut(auth); 
          setAllowed(false);  // Ensure access is denied
        }
      } else {
        // No user is logged in, redirect to login
        setAllowed(false);
      }
      setLoading(false);  // Stop loading regardless of success or error
    });

    // Clean up subscription
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Loading...</div>; // Loading spinner or message
  }

  return allowed ? children : <Navigate to="/account-type" />;
};

export default UserRoute;
