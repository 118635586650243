import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const DataBackup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const dataBackupRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  // Scenario steps: How regular backups helped Sarah recover her personal data.
  const scenarioSteps = [
    {
      title: "Step 1: Regular Backups",
      text: "Sarah makes it a habit to back up her photos, videos, and documents to a secure cloud storage service and an external hard drive."
    },
    {
      title: "Step 2: The Cyber Attack",
      text: "One day, Sarah inadvertently clicks on a malicious link that triggers a ransomware attack, encrypting her files."
    },
    {
      title: "Step 3: Discovery",
      text: "When Sarah tries to open her files, she finds a ransom note demanding payment, and her data becomes inaccessible."
    },
    {
      title: "Step 4: Staying Calm",
      text: "Remembering her backup routine, Sarah refrains from paying the ransom and prepares to restore her data."
    },
    {
      title: "Step 5: Data Restoration",
      text: "Sarah initiates the recovery process, retrieving all her valuable memories and important documents from her backups."
    },
    {
      title: "Step 6: Lessons Learned",
      text: "This incident underscores the critical importance of maintaining regular backups and robust home cybersecurity measures—a practice that proves equally essential if her device were ever to break or go missing."
    }
  ];

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  // Prevent skipping ahead more than 2 seconds.
  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleScenarioNext = () => {
    if (currentStepIndex < scenarioSteps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
      setShowWarning(false);
    }
  };

  const handleScenarioPrevious = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
      setShowWarning(false);
    }
  };

  const handleNextClick = () => {
    // User cannot proceed until the video is fully watched and all scenario steps have been viewed.
    if (!isVideoWatched || currentStepIndex < scenarioSteps.length - 1) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module7/data-backup-part-two#DataBackupPlanHeading');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module6/public-wifi-part-two#BestPracticesPublicWiFiHeading');
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  // Scroll to the "Backing Up Your Data" section if the hash is present.
  useEffect(() => {
    if (location.hash === '#DataBackupHeading' && dataBackupRef.current) {
      dataBackupRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="cybersecurity-basics-container">
      <button 
        className="back-arrow-button" 
        onClick={goBack} 
        aria-label="Go back to best practices for public Wi-Fi section"
      >
        ←
      </button>

      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header centered-header">
              <h1 ref={dataBackupRef}>Backing Up Your Data</h1>
            </div>
            <div className="video-container">
              <video
                ref={videoRef}
                src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FSecure%20Your%20Data_%20The%20Power%20of%20Backups.mp4?alt=media&token=25eb8c96-b0aa-497e-afbc-9f9a3e03227f"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                playsInline
                aria-label="Backing Up Your Data video"
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        {/* Interactive Scenario Section */}
        <section className="scenario-container">
          <h2>Scenario: Data Backup Saves the Day</h2>
          <div className="scenario-step">
            <h3>{scenarioSteps[currentStepIndex].title}</h3>
            <p>{scenarioSteps[currentStepIndex].text}</p>
          </div>
          <div className="scenario-navigation">
            {currentStepIndex > 0 && (
              <button 
                onClick={handleScenarioPrevious} 
                className="scenario-nav-button scenario-nav-button-C2M3" 
                aria-label="Previous Step"
              >
                &#8592;
              </button>
            )}
            {currentStepIndex < scenarioSteps.length - 1 && (
              <button 
                onClick={handleScenarioNext} 
                className="scenario-nav-button scenario-nav-button-C2M3" 
                aria-label="Next Step"
              >
                &#8594;
              </button>
            )}
            {currentStepIndex === scenarioSteps.length - 1 && (
              <p className="scenario-complete-message">You have completed the scenario.</p>
            )}
          </div>
        </section>

        <div className="start-quiz-button-container">
          <button 
            className="start-quiz-button1" 
            onClick={handleNextClick} 
            aria-label="Proceed to data backup part two"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video and complete all scenario steps before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default DataBackup;
