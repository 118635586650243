import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../Page3/RecognizingThreatsPartTwo.css';

const SocialMediaSafety = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const socialMediaSafetyRef = useRef(null);

  // Video and skipping
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  // Warning message if the user hasn’t met conditions
  const [showWarning, setShowWarning] = useState('');

  // Keep track of which tabs have been visited
  const [visitedTabs, setVisitedTabs] = useState([]);
  const TOTAL_TABS = 4; // We have 4 items in our accordion

  // Determine if the user can proceed
  const canProceed = isVideoWatched && visitedTabs.length === TOTAL_TABS;

  // Record a tab visit if not already visited
  const handleTabVisit = (index) => {
    if (!visitedTabs.includes(index)) {
      setVisitedTabs((prev) => [...prev, index]);
    }
  };

  // "Start Game" button logic
  const goToNext = () => {
    if (!canProceed) {
      // If the user can’t proceed, show the relevant warning
      if (!isVideoWatched) {
        setShowWarning('You must watch the video before proceeding.');
      } else {
        setShowWarning('You must click through all the tabs before starting the game.');
      }
    } else {
      setShowWarning('');
      navigate('/beginnerscourse/page6/social-media-safety-quiz');
    }
  };

  // Scroll to section if hash is present
  useEffect(() => {
    if (location.hash === '#SocialMediaSafety' && socialMediaSafetyRef.current) {
      socialMediaSafetyRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  // Handle video ending
  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning('');
  };

  // Prevent skipping
  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  // Go back button
  const goBack = () => {
    navigate('/beginnerscourse/page5.5/checking-urls#VerifyLinks');
  };

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Checking URLs"
      >
        ←
      </button>

      <section className="module-section">
        <section className="module-section" style={{ textAlign: 'center' }}>
          <h1 id="SocialMediaSafety" ref={socialMediaSafetyRef} className="BasicsTitle">
            Social Media Safety
          </h1>
        </section>

        {/* Video Section */}
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FSocialMedia.mp4?alt=media&token=d9d2b1ff-25a0-4d7f-8b2a-93a2df86f8e3"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            aria-label="Educational video on social media safety"
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <section className="module-section" style={{ textAlign: 'center' }}>
          <h2 style={{ color: "#005f73" }}>Privacy Settings key points</h2>
        </section>

        {/* Accordion Tabs for Privacy Settings */}
        <div className="TabsSocialMedia">
          <PrivacySettingsAccordion onTabVisit={handleTabVisit} />
        </div>

        {/* Start Game Button */}
        <div className="button-container">
          <button
            className="next-button"
            onClick={goToNext}
            aria-label="Proceed to Social Media Safety Part Two"
          >
            Start Game
          </button>
          {showWarning && (
            <p className="warning-text22" role="alert">
              {showWarning}
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default SocialMediaSafety;

/* ------------------------------------------------------------------
   Accordion component for the 4 Privacy Settings tabs
   ------------------------------------------------------------------ */
const accordionData = [
  {
    title: 'Review Your Social Media Privacy Settings',
    content: (
      <>
        <p>
          Check whether your social media account is set to private or public.
          Keeping your account private can help protect you from identity theft,
          online harassment, and unauthorised access.
        </p>
        <br></br>
        <p>
          If your account is public, be mindful of the information you share
          to avoid potential risks.
        </p>
      </>
    )
  },
  {
    title: 'Adjust Privacy Settings',
    content: (
      <>
        <p>
          Restrict your profile visibility to friends only to enhance your
          online safety. Additionally, hide personal details such as phone
          numbers and email addresses from public view to reduce exposure
          to potential threats.
        </p>
        <br></br>
        <p>
          Taking these steps ensures that your sensitive information
          remains secure.
        </p>
      </>
    )
  },
  {
    title: 'Stay Vigilant Online',
    content: (
      <>
        <p>
          Avoid clicking on links in comment sections unless you have verified
          their authenticity. This precaution helps protect you from phishing
          attempts and malicious content.
        </p>
        <br></br>
        <p>
          Additionally, delete sensitive chat history to minimise risks in case
          your account is ever compromised.
        </p>
      </>
    )
  },
  {
    title: 'Manage Third-Party App Access',
    content: (
      <>
        <p>
          Regularly review and remove third-party apps that you no longer use
          or trust. This practice helps limit unnecessary access to your
          account and personal data.
        </p>
        <br></br>
        <p>
          For the apps you continue to use, ensure they only have the
          permissions necessary to function properly.
        </p>
      </>
    )
  }
];

const PrivacySettingsAccordion = ({ onTabVisit }) => {
  const [openIndices, setOpenIndices] = useState([]);

  // Toggle open/close for each tab
  const toggleItem = (index) => {
    if (openIndices.includes(index)) {
      // Close if open
      setOpenIndices(openIndices.filter((i) => i !== index));
    } else {
      // Open and notify parent that this tab was visited
      setOpenIndices([...openIndices, index]);
      if (onTabVisit) {
        onTabVisit(index);
      }
    }
  };

  return (
    <div>
      {accordionData.map((item, index) => {
        const isOpen = openIndices.includes(index);

        return (
          <div
            key={index}
            className={`accordion-item ${isOpen ? 'open' : ''}`}
            style={{
              margin: '1rem 0',
              border: '1px solid #ccc',
              borderRadius: '6px',
              overflow: 'hidden'
            }}
          >
            {/* Accordion Header */}
            <button
              className="accordion-header"
              onClick={() => toggleItem(index)}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                background: '#fff',
                border: 'none',
                cursor: 'pointer',
                padding: '1rem',
                fontSize: '1.1rem',
                fontWeight: 'bold',
                textAlign: 'left',
                outline: 'none',
                borderLeft: isOpen ? '4px solid red' : '4px solid transparent',
                transition: 'border-left 0.3s ease',
                color: 'black'
                
              }}
            >
              {item.title}
              <span style={{ fontSize: '1.5rem', color: 'black' }}>
                {isOpen ? '−' : '+'}
              </span>
            </button>

            {/* Accordion Content */}
            {isOpen && (
              <div
                className="accordion-content"
                style={{
                  padding: '0 1rem 1rem 1rem',
                  backgroundColor: 'white',
                  fontSize: '1.2rem'
                }}
              >
                {item.content}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
