import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './RecognizingThreats.css'; // Ensure this CSS file contains the KeyPoints styles
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';

const RecognizingThreats = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const threatsTitleRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [progress, setProgress] = useState(0);
  const [lastValidTime, setLastValidTime] = useState(0);

  const [showPhishingPopup, setShowPhishingPopup] = useState(false);
  const [showImpersonationPopup, setShowImpersonationPopup] = useState(false);

  const goToQuiz = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      setShowImpersonationPopup(true);
    }
  };

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
    setProgress(100);
    setShowPhishingPopup(true);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    const duration = videoRef.current.duration;

    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }

    const progressPercent = (currentTime / duration) * 100;
    setProgress(progressPercent);
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page2/cybersecurity-basics');
  };

  useEffect(() => {
    if (location.hash === '#ThreatsTitle' && threatsTitleRef.current) {
      threatsTitleRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div className="introduction-container2">
      <button className="back-arrow-button" onClick={goBack}>
        ←
      </button>

      <h1 id="ThreatsTitle" ref={threatsTitleRef} className="BasicsTitle">
        Recognising Cyber Threats
      </h1>

      <section className="module-section">
        <div className="content-layout">
          <div className="video-container">
            <LogoOverlay />
            <video
              ref={videoRef}
              src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FRecognisingCyberThreats.mp4?alt=media&token=623a0451-d105-45ab-bb39-b4a30b672061"
              width="100%"
              onEnded={handleVideoEnd}
              controls
              controlsList="nodownload noremoteplayback noplaybackrate"
              disablePictureInPicture
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="module-content">
          <div className="bordered-container">
            <h2>Key Points</h2>
            <br></br>
            <ul className="KeyPoints">
              <li><span className="number-circle">1</span> Phishing involves fraudulent emails or messages designed to trick individuals into revealing sensitive data.</li>
              <li><span className="number-circle">2</span> Social engineering manipulates individuals into sharing confidential information through deceptive requests.</li>
              <li><span className="number-circle">3</span> Impersonation where attackers pose as trusted individuals to induce unsafe actions.</li>
              <li><span className="number-circle">4</span> Malware and ransomware are threats that either steal information or lock files until a ransom is paid.</li>
            </ul>
          </div>
        </div>

        <div className="start-quiz-button-container">
          <button className="start-quiz-button" onClick={goToQuiz}>
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text22">
              You must watch the video before proceeding.
            </p>
          )}
        </div>
      </section>

      {showPhishingPopup && (
        <div className="popup phishing-popup">
           Phishing is the most predominant issue, impacting 84% of businesses and 83% of charities in the UK. (Source: Cyber Security Breaches Survey 2024)
          <br />
          <button onClick={() => setShowPhishingPopup(false)}>Dismiss</button>
        </div>
      )}

    </div>
  );
};

export default RecognizingThreats;
