import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import { getAuth, verifyPasswordResetCode, confirmPasswordReset } from "firebase/auth";
import "./ResetPasswordPage.css";

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const [oobCode, setOobCode] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("Validating reset request...");
  const [statusClass, setStatusClass] = useState("info-message");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const auth = getAuth();
  const newPasswordRef = useRef(null);
  const confirmPasswordRef = useRef(null);
  const submitButtonRef = useRef(null);

  // State variables for toggling password visibility
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{10,}$/;

  useEffect(() => {
    const oobCodeParam = searchParams.get("oobCode");
    const mode = searchParams.get("mode");

    if (mode === "resetPassword" && oobCodeParam) {
      setOobCode(oobCodeParam);
      validateResetCode(oobCodeParam);
    } else {
      setMessage("⚠️ Invalid or missing password reset code.");
      setStatusClass("error-message");
      setLoading(false);
    }
  }, [searchParams]);

  const validateResetCode = async (oobCode) => {
    try {
      console.log("🔍 Validating reset code:", oobCode);
      await verifyPasswordResetCode(auth, oobCode);
      setMessage("✅ Code verified. Please enter a new password.");
      setStatusClass("success-message");
      newPasswordRef.current?.focus();
    } catch (error) {
      console.error("❌ Reset Password Validation Error:", error.code, error.message);
      setMessage("⚠️ Password reset link is invalid or expired.");
      setStatusClass("error-message");
    }
    setLoading(false);
  };

  const handleResetPassword = async (event) => {
    event.preventDefault();

    if (!oobCode) {
      setMessage("⚠️ Password reset code is missing or invalid.");
      setStatusClass("error-message");
      return;
    }

    if (newPassword !== confirmPassword) {
      setMessage("⚠️ Passwords do not match.");
      setStatusClass("error-message");
      confirmPasswordRef.current?.focus();
      return;
    }

    if (!passwordRegex.test(newPassword)) {
      setMessage("⚠️ Password must be at least 10 characters long, include at least one uppercase letter and one special character.");
      setStatusClass("error-message");
      newPasswordRef.current?.focus();
      return;
    }

    setLoading(true);

    try {
      console.log("🔄 Resetting password with code:", oobCode);
      await confirmPasswordReset(auth, oobCode, newPassword);

      setMessage("✅ Password reset successful! Redirecting to login...");
      setStatusClass("success-message");
      setTimeout(() => navigate("/login"), 3000);
    } catch (error) {
      console.error("❌ Error resetting password:", error.code, error.message);
      setMessage("⚠️ Error resetting password. Please try again.");
      setStatusClass("error-message");
    }

    setLoading(false);
  };

  return (
    <main className="reset-password-page-container">
      <h1 tabIndex="0">Reset Password</h1>
      <p role="alert" aria-live="assertive" className={`reset-password-page-message ${statusClass}`}>
        {message}
      </p>

      {!loading && oobCode && (
        <form onSubmit={handleResetPassword} className="reset-password-page-form">
          <div className="reset-password-page-input-group">
            <label htmlFor="newPassword">New Password <span className="sr-only">(required)</span></label>
            <div className="password-input-container">
              <input
                id="newPassword"
                type={showNewPassword ? "text" : "password"}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
                placeholder="Enter new password"
                ref={newPasswordRef}
                aria-required="true"
                aria-describedby="passwordHelp"
              />
              <button
                type="button"
                className="toggle-password-button"
                onClick={() => setShowNewPassword(!showNewPassword)}
                aria-label={showNewPassword ? "Hide new password" : "Show new password"}
              >
                {showNewPassword ? "Hide" : "Show"}
              </button>
            </div>
            <small id="passwordHelp" className="reset-password-page-helper-text">
              Must be at least 10 characters long, include one uppercase letter and one special character.
            </small>
          </div>

          <div className="reset-password-page-input-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <div className="password-input-container">
              <input
                id="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                placeholder="Confirm new password"
                ref={confirmPasswordRef}
              />
              <button
                type="button"
                className="toggle-password-button"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                aria-label={showConfirmPassword ? "Hide confirm password" : "Show confirm password"}
              >
                {showConfirmPassword ? "Hide" : "Show"}
              </button>
            </div>
          </div>

          <button 
            type="submit" 
            className="reset-password-page-button"
            ref={submitButtonRef}
            disabled={loading}
            aria-live="polite"
          >
            {loading ? "Resetting..." : "Reset Password"}
          </button>
        </form>
      )}

      <button className="reset-password-page-back-button" onClick={() => navigate("/login")}>Back to Login</button>
    </main>
  );
};

export default ResetPasswordPage;
