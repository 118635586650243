import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import './WhatIsCyberSecurity.css';

const WhatIsCyberSecurity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const backArrowRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  // Prevents skipping ahead more than 2 seconds beyond the last valid time
  const handleTimeUpdate = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      if (currentTime > lastValidTime + 2) {
        videoRef.current.currentTime = lastValidTime;
      } else {
        setLastValidTime(currentTime);
      }
    }
  };

  const handleQuizClick = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module2/what-is-cyber-security-quiz');
    }
  };

  const goBack = () => {
    navigate('/courses-two');
  };

  // Scroll to the back arrow button if the hash is present
  useEffect(() => {
    if (location.hash === '#BackArrow' && backArrowRef.current) {
      backArrowRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  // Attach timeupdate event for preventing skips
  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
      return () => {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [lastValidTime]);

  return (
    <main className="cybersecurity-basics-container">
      <header className="cybersecurity-basics-header">
        <button
          className="back-arrow-button"
          onClick={goBack}
          ref={backArrowRef}
          aria-label="Go back to the previous section"
        >
          ←
        </button>
        <h1>What is Cybersecurity</h1>
      </header>

      <section className="module-section">
        <section className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FUnlocking%20the%20Secrets%20of%20Cybersecurity.mp4?alt=media&token=7497ac3f-7f0d-483c-a684-f8ffbccc13c3"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error('Video Error:', e)}
            controls              // Uses default browser controls
            playsInline
            aria-label="Introduction video explaining what cybersecurity is"
          >
            Your browser does not support the video tag.
          </video>
        </section>

        <section className="start-quiz-button-container">
          <button
            className="start-quiz-button"
            onClick={handleQuizClick}
            aria-label="Start the quiz"
            disabled={!isVideoWatched}
            tabIndex={!isVideoWatched ? -1 : 0}
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video before proceeding.
            </p>
          )}
        </section>
      </section>
    </main>
  );
};

export default WhatIsCyberSecurity;
