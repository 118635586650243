import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    console.log(`Scrolling to the top for route: ${pathname}`);

    // Use a small delay to ensure it runs after other components' effects
    setTimeout(() => {
      const scrollableElement = document.querySelector('.main-content'); // Replace with the correct selector if different
      if (scrollableElement) {
        scrollableElement.scrollTop = 0;
      } else {
        window.scrollTo(0, 0);
      }
    }, 10); // Delay slightly to give time for other scroll effects to complete
  }, [pathname]);

  return null;
};

export default ScrollToTop;
