import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../ModuleTwo/WhatIsCyberSecurity.css';

const HomeNetwork = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const homeNetworkRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const scenarioSteps = [
    {
      title: "Step 1: Weak Wi‑Fi Security",
      text: "A family sets up their home network with a weak Wi‑Fi password and leaves the router’s default settings unchanged, making it easy to guess."
    },
    {
      title: "Step 2: Network Scanning",
      text: "An attacker scans the area for available networks and quickly identifies the vulnerable home network."
    },
    {
      title: "Step 3: Exploiting a Vulnerable Device",
      text: "The attacker locates an IoT device, such as a smart thermostat, with outdated firmware and known vulnerabilities."
    },
    {
      title: "Step 4: Gaining Foothold",
      text: "By exploiting the vulnerable device, the attacker gains initial access and establishes a foothold within the network."
    },
    {
      title: "Step 5: Pivoting to the Router",
      text: "Using the compromised device, the attacker accesses the router—often using default administrative credentials—to change its settings."
    },
    {
      title: "Step 6: Changing DNS Settings",
      text: "The attacker alters the router’s DNS settings, redirecting web traffic to malicious servers and intercepting data."
    },
    {
      title: "Step 7: Data Interception & Malware Deployment",
      text: "With control over the router, the attacker intercepts sensitive data and deploys malware onto other connected devices."
    },
    {
      title: "Step 8: Undetected Monitoring",
      text: "For weeks, the attacker monitors the network without detection, gathering further information and compromising more devices."
    },
    {
      title: "Step 9: The Aftermath",
      text: "Eventually, unusual network activity alerts the family. They discover the breach, forcing them to secure their network and clean their devices."
    },
    {
      title: "",
      text: "This scenario emphasises the importance of using strong passwords, regularly updating device firmware, and changing default settings on routers and other network devices."
    }

  ];

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  // Prevent skipping ahead more than 2 seconds.
  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleScenarioNext = () => {
    if (currentStepIndex < scenarioSteps.length - 1) {
      setCurrentStepIndex(currentStepIndex + 1);
      setShowWarning(false);
    }
  };

  const handleScenarioPrevious = () => {
    if (currentStepIndex > 0) {
      setCurrentStepIndex(currentStepIndex - 1);
      setShowWarning(false);
    }
  };

  const handleNextClick = () => {
    // User cannot proceed until the video is fully watched and all scenario steps have been viewed.
    if (!isVideoWatched || currentStepIndex < scenarioSteps.length - 1) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module5/home-network-quiz');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module4/mobile-security');
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  // Scroll to the "Protect Your Home Network" section if the hash is present.
  useEffect(() => {
    if (location.hash === '#HomeNetworkHeading' && homeNetworkRef.current) {
      homeNetworkRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="cybersecurity-basics-container">
      <button 
        className="back-arrow-button" 
        onClick={goBack} 
        aria-label="Go back to mobile device security section"
      >
        ←
      </button>
      <section className="module-section">
        <div className="content-layout">
          <div className="module-content">
            <div className="cybersecurity-basics-header" ref={homeNetworkRef}>
              <h1>Protect Your Home Network</h1>
            </div>
            <div className="video-container" style={{ position: 'relative' }}>
              <LogoOverlay />
              <video
                ref={videoRef}
                src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FSecuring%20Your%20Home%20Network_%20A%20Cybersecurity%20Guide.mp4?alt=media&token=47a65dfe-17ff-4891-bfa0-75aa108e101e"
                width="100%"
                onEnded={handleVideoEnd}
                onError={(e) => console.error("Video Error:", e)}
                controls
                controlsList="nodownload noremoteplayback noplaybackrate"
                disablePictureInPicture
                playsInline
                aria-label="Home Network Security video"
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>

        {/* Interactive Scenario Section */}
        <section className="scenario-container">
          <h2>Scenario: How a Home Network Was Compromised</h2>
          <div className="scenario-step">
            <h3>{scenarioSteps[currentStepIndex].title}</h3>
            <p>{scenarioSteps[currentStepIndex].text}</p>
          </div>
          <div className="scenario-navigation">
            {currentStepIndex > 0 && (
              <button 
                onClick={handleScenarioPrevious} 
                className="scenario-nav-button scenario-nav-button-C2M3" 
                aria-label="Previous Step"
              >
                &#8592;
              </button>
            )}
            {currentStepIndex < scenarioSteps.length - 1 && (
              <button 
                onClick={handleScenarioNext} 
                className="scenario-nav-button scenario-nav-button-C2M3" 
                aria-label="Next Step"
              >
                &#8594;
              </button>
            )}
            {currentStepIndex === scenarioSteps.length - 1 && (
              <p className="scenario-complete-message">You have completed the scenario.</p>
            )}
          </div>
        </section>

        <div className="start-quiz-button-container">
          <button 
            className="start-quiz-button1" 
            onClick={handleNextClick} 
            aria-label="Proceed to the home network security quiz"
          >
            Start Quiz
          </button>
          {showWarning && (
            <p className="warning-text" role="alert">
              You must watch the entire video and complete all scenario steps before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default HomeNetwork;
