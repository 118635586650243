import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import '../page5/SafeBrowsingGame.css';

const scenarios = [
  // (Your scenarios remain the same)
  {
    text: "What is the primary purpose of two-factor authentication??"
    ,
    options: [
        { text: "To make accounts completely unhackable.", isCorrect: false },
        { text: "To simplify the login process.", isCorrect: false },
        { text: "To add an extra layer of defence against hackers.", isCorrect: true },
        { text: "To replace the need for strong passwords.", isCorrect: false }
    ],
    feedback: [
        "Incorrect. While two-factor authentication improves security, no system is entirely unhackable.",
        "Incorrect. Two-factor authentication adds a step to the login process, which may not simplify it.",
        "Correct! Two-factor authentication enhances security by requiring an additional verification step, making it harder for hackers to access accounts.",
        "Two-factor authentication complements strong passwords rather than replacing them."
    ]
},

{
    text: "What does 2FA require in addition to your password?",
    options: [
        { text: "Your national insurance number or ID.", isCorrect: false },
        { text: "A second factor, like a code from your phone.", isCorrect: true },
        { text: "Your date of birth or other personal info.", isCorrect: false },
        { text: "Your mother's maiden name or a secret question.", isCorrect: false }
    ],
    feedback: [
        "Incorrect. 2FA requires something only you have immediately.",
        "Correct! 2FA requires a second piece of information, like a code sent to your phone.",
        "Incorrect. 2FA requires something only you have immediately.",
        "Incorrect. 2FA requires something only you have immediately."
    ]
},
{
    text: "How often should you update your 2FA methods?",
    options: [
        { text: "Only when you change your password.", isCorrect: false },
        { text: "Never, once set up, it doesn't need updating.", isCorrect: false },
        { text: "Regularly, to ensure protection and adapt to new features.", isCorrect: true },
        { text: "Just once when you first set it up.", isCorrect: false }
    ],
    feedback: [
        "Incorrect. 2FA methods should be updated regularly.",
        "Incorrect. 2FA methods should be updated regularly.",
        "Correct! Regularly updating your 2FA methods ensures ongoing security.",
        "Incorrect. 2FA methods should be updated regularly."
    ]
},

{
    text: "What should you do if you lose access to your 2FA device?",
    options: [
        { text: "Disable 2FA immediately for safety.", isCorrect: false },
        { text: "Create a new account right away.", isCorrect: false },
        { text: "Ignore it and hope for the best.", isCorrect: false },
        { text: "Use the backup codes provided by 2FA.", isCorrect: true }
    ],
    feedback: [
        "Incorrect. Disabling 2FA should be a last resort.",
        "Incorrect. Creating a new account is not necessary.",
        "Incorrect. Ignoring the issue can lead to security risks.",
        "Correct! Backup codes are provided for such situations."
    ]
},
{
    text: "How do you update your 2FA settings?",
    options: [
        { text: "Follow platform-specific instructions.", isCorrect: true },
        { text: "Guess the new settings randomly.", isCorrect: false },
        { text: "Ask a friend to do it for you.", isCorrect: false },
        { text: "Use the same settings on all platforms.", isCorrect: false }
    ],
    feedback: [
        "Correct! Always follow platform-specific instructions to update your 2FA settings.",
        "Incorrect. You should follow specific instructions, not guess.",
        "Incorrect. It's important to understand and manage your own 2FA settings.",
        "Incorrect. Different platforms may have different security requirements."
    ]
}

  // Add other scenarios here
];

const TwoFactorAuthQuiz = () => {
  const [currentScenario, setCurrentScenario] = useState(0);
  const [feedback, setFeedback] = useState(null);
  const [score, setScore] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null); // To track selected option
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [showStar, setShowStar] = useState(false); // State to control star animation
  const location = useLocation();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user || null); // ✅ Allow unauthenticated users to take the quiz
    });
  
    return () => unsubscribe();
  }, []);
  

  // Scroll to top if #top hash is in the URL
  useEffect(() => {
    if (location.hash === '#top') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [location]);

  const handleOptionClick = (isCorrect, feedbackMessage, index) => {
    if (selectedOption === null) {
      setSelectedOption(index);
      setFeedback({ message: feedbackMessage, isCorrect });
      if (isCorrect) {
        setScore(score + 1);
      }
      setTimeout(() => {
        setFeedback(null);
        setSelectedOption(null);
        setCurrentScenario(currentScenario + 1);
      }, 3000);
    }
  };

  useEffect(() => {
    if (currentScenario >= scenarios.length && score >= 3) {
      if (user) {
        const updateProgress = async () => {
          try {
            const userDocRef = doc(firestore, 'users', user.uid);
            await updateDoc(userDocRef, {
              'progress.course1.module4': true,
            });
            setShowStar(true);
          } catch (error) {
            console.error('Error updating user profile: ', error);
          }
        };
  
        updateProgress();
      }
    }
  }, [currentScenario, score, user]);
  

  const resetGame = () => {
    setCurrentScenario(0);
    setScore(0);
    setFeedback(null);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page5/safe-browsing#BasicsTitle');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page4.5/two-factor-auth#BasicsTitle');
  };

  if (currentScenario >= scenarios.length) {
    return (
      <div className="safe-browsing-game-container-unique11">
        {showStar && <div className="falling-star" aria-hidden="true"></div>}
        <h2>Congratulations! You completed the quiz.</h2>
<p>You scored {score} out of {scenarios.length}</p>

{score >= 3 ? (
  <div className="completion-message">
    {user ? (
      <p>
        🎉 Well done! You've passed the quiz and learned about Two-Factor Authentication. Your progress has been saved.
      </p>
    ) : (
      <p>
        🎉 Well done! You have completed the quiz. To track your progress and save your results, 
        <strong> sign up for an account.</strong>
      </p>
    )}
    <button onClick={goToNext} aria-label="Go to the next section">Next</button>
  </div>
) : (
  <>
    <p>You need at least 3 correct answers to pass. Please try again.</p>
    <button onClick={resetGame} aria-label="Retake the quiz">Retake the Quiz</button>
  </>
)}

      </div>
    );
  }

  const { text, options, feedback: scenarioFeedback } = scenarios[currentScenario];

  return (
    <div className="safe-browsing-game-container-unique11">
      

      <h2>{text}</h2>
      <fieldset
        aria-describedby={feedback ? 'feedback' : undefined}
        className="quiz-fieldset"
      >
        
        {options.map((option, index) => (
          <button
            key={index}
            className={
              selectedOption === index
                ? feedback?.isCorrect
                  ? 'correct-unique11'
                  : 'incorrect-unique11'
                : ''
            }
            onClick={() => handleOptionClick(option.isCorrect, scenarioFeedback[index], index)}
            disabled={selectedOption !== null}
            aria-describedby={selectedOption === index && feedback ? 'feedback' : undefined}
            aria-pressed={selectedOption === index}
          >
            {option.text}
          </button>
        ))}
      </fieldset>
      <div
        id="feedback"
        className={`feedback-unique11 ${feedback?.isCorrect ? 'correct-feedback-unique11' : 'incorrect-feedback-unique11'}`}
        aria-live="polite"
      >
        {feedback?.message}
      </div>
    </div>
  );
};

export default TwoFactorAuthQuiz;
