import React, { useRef, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './Home.css'; // Import the CSS file
import Footer from './Footer/Footer';
import backgroundImage from "../assets/background121.jpg";


const Home = () => {
  const location = useLocation();
  const section1Ref = useRef(null);
  const videoRef = useRef(null); // Reference for the main video container
  const introVideoRef = useRef(null); // Reference for intro video within the container
  const [hoveredFeature, setHoveredFeature] = useState(null);
  const trackimage = "https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2Ftrack.jpg?alt=media&token=066273cc-358e-420d-ae5f-0821eb31310b";
  const threatreportimage = "https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2Fthreatreport.jpg?alt=media&token=23e596f7-e6ab-4b01-967a-59fc30488875";
  const graphsimage = "https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2Fgraphs.jpg?alt=media&token=1d26aae5-1e28-4ecf-a37b-83d83d891f98";
  const logoimage = "https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2Flogo.jpg?alt=media&token=4cc6cd23-923f-462d-9ac1-083b30eba24f";
  const happyimage = "https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2Fhappy.jpg?alt=media&token=ccb45a15-03a2-4a87-a8b5-3a7b66bfdb01";

    

  useEffect(() => {
    // Check if the URL hash is "#section-1-about" and scroll the referenced section into view:
    if (location.hash === '#section-1-about' && section1Ref.current) {
      section1Ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    const handleTimeUpdate = () => {
      if (introVideoRef.current.duration - introVideoRef.current.currentTime <= 1) {
        introVideoRef.current.pause();
      }
    };

    if (introVideoRef.current) {
      introVideoRef.current.addEventListener('timeupdate', handleTimeUpdate);
    }

    // Cleanup: remove event listener when component is unmounted
    return () => {
      if (introVideoRef.current) {
        introVideoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [location.hash]);

  const featureImages = [trackimage, threatreportimage, graphsimage, logoimage, happyimage];

  return (
    <>
      <div className="main-container-content-home2">
      <div className="content-container-about1">
    
          </div>

          <div className="intro-video-wrapper-home2">
        <video
          className="intro-video-home2"
          autoPlay
          muted
          ref={videoRef}
          disablePictureInPicture
          controlsList="nodownload"
        >
          <source src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FMainIntro.mp4?alt=media&token=b7e40b2a-26b1-4f5d-9fa1-746deade236d" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
          



         {/* Updated Course Section */}
         <section className="section section-2-home2new">
  <div className="content-wrapper-home2new">
  <div
  className="content-wrapper-home2new"
  style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
>
  <div className="content-container-home2new">
    <h1 className="section-title222-about22">
      Cybersecurity Awareness Training for Businesses
    </h1>
    <h2 className="section-title222-home2new">
      Improve your skills with expert-led training
    </h2>
    <div className="section-subtitle-home2new">
      <p className="section-subtitle-about">
        A cybersecurity training platform designed to educate employees, empower managers, and protect businesses. Our interactive courses, threat reporting system, and data-driven insights help organisations stay secure and proactive against cyber threats.
      </p>
    </div>
  </div>
  <div className="image-container-training">
    <img
      src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2Ftraining.jpg?alt=media&token=d19ee91e-d4a9-48f4-ad77-3931242a6c6f"
      alt="Cybersecurity Training"
      className="training-image"
    />
  </div>
</div>

    <h2 className="section-title222-home2new2">Complete all three courses to earn your cyber awareness certificate</h2>
    <div className="courses-grid">
      
      {/* Cyber Shield: Basics */}
      <div className="course-card">
        <h3>Cyber Shield: Basics</h3>
        <ul className="course-list">
          <li>Identify malware, verify links, and avoid scams.</li>
          <li>Use strong passwords and enable two-factor authentication (2FA).</li>
          <li>Secure social media, email, and practice safe browsing.</li>
        </ul>
        <br></br>
        <Link to="/courses-two#section-2-courses">
          <button className="course-button-course-card">Find out more</button>
        </Link>
      </div>

      {/* Cyber Shield: Intermediate */}
      <div className="course-card">
        <h3>Cyber Shield: Intermediate</h3>
        <ul className="course-list">
          <li>Learn about malware, phishing, ransomware, and protecting your credit profile.</li>
          <li>Strengthen mobile security, home networks, and public Wi-Fi safety.</li>
          <li>Understand the importance of backups and keeping your data safe.</li>
        </ul>
        <Link to="/courses-two#section-3-courses">
          <button className="course-button-course-card">Find out more</button>
        </Link>
      </div>

      {/* Cyber Shield: Business Academy */}
      <div className="course-card">
        <h3>Cyber Shield: Business Academy</h3>
        <ul className="course-list">
          <li>Recognise phishing, smishing, and vishing attacks.</li>
          <li>Learn best practices for protecting work devices and collaboration tools.</li>
          <li>Safeguard sensitive information and strengthen workplace cybersecurity.</li>
        </ul>
        <Link to="/courses-two#section-4-courses">
          <button className="course-button-course-card">Find out more</button>
        </Link>
      </div>

      {/* User Testimonial - Positioned to the Right */}
      <div className="testimonial-card">
        <p className="testimonial-text">
        "This course significantly improved my cybersecurity awareness, making me more vigilant against threats. Highly recommended for any business!"
    </p>
    <p className="testimonial-author">— Alex, Credit Control at Vivup</p>
      </div>

    </div>
  </div>
</section>


        <section className="section4-home2">
  <div className="content-wrapper-section4-home2">
    
    {/* Left Side - Text Content */}
    <div className="content-container-section4-home2">
      <h2 className="section-title22-section4-home2">Sign Up for Our Free Account</h2>
      <ul className="course-summary">
        <li>✔ Start with a free account. Sample the course, upgrade anytime.</li>
        <li>✔ Course 1 Included Free.</li>
      </ul>
      <a href="/free-user-signup" className="cta-button">Get Started</a>

      <h3 className="section-title22-section4-home2" style={{ marginTop: '2rem' }}>Upgrade to Personal Plan</h3>
      <ul className="course-summary">
        <li>✔ Access to All Courses</li>
        <li>✔ Progress Tracking</li>
        <li>✔ Certification on Completion of All Courses</li>
        <li>✔ Work at Your Own Pace</li>
        <li>✔ Linking Account to Manager (Optional)</li>
        <li>✔ Increasing Cyber Awareness</li>
      </ul>
      <a href="/account-type" className="cta-button">Get Started</a>
    </div>
    

    {/* Right Side - Image */}
    <div className="image-container-signup">
      <img src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2Fhappy.jpg?alt=media&token=ccb45a15-03a2-4a87-a8b5-3a7b66bfdb01" alt="Sign up illustration" className="signup-image" />
    </div>

  </div>
</section>


{/* Third Section: Manager's Account */}
<section className="section section-3-home2">
  <div className="manager-content-container-home2">
    <h2 className="section-title-manager-home2" style={{ color: 'black' }}>
      Manager's Account
    </h2>
    <p className="section-subtitle-home2newmanagers">For managers who want to streamline cybersecurity awareness, track employee progress, and stay ahead of threats—effortlessly</p>

    <div className="manager-features-home2">
      {[
        { title: "Track Employee Progress", description: "Stay in the loop as your team hones their cyber skills—track their progress and ensure every course is completed.", image: trackimage },
        { title: "Receive Threat Reports", description: "Get timely threat reports from employees and be prepared to tackle potential risks head-on.", image: graphsimage },
        { title: "Analyse Graphs & Statistics", description: "Visualise reported threats with insightful graphs and stats, making data-driven decisions a breeze.", image: threatreportimage },
        { title: "Add Your Logo", description: "Personalise your company's platform by adding your business logo—because every detail matters.", image: logoimage }
      ].map((feature, index) => (
        <div className="feature-home2" key={index}>
          <h3>{feature.title}</h3>
          <p>{feature.description}</p>
          <img src={feature.image} alt={feature.title} className="feature-image" />
        </div>
      ))}
      <a href="/account-type" className="cta-button">Get Started</a>
    </div>
  </div>
</section>






        {/* Footer */}
        <Footer />
      </div>
    </>
  );
};

export default Home;
