import React, { useEffect, useState } from 'react';
import { collection, query, where, getDocs, orderBy, deleteDoc, doc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthProvider';
import { firestore } from '../../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import './ThreatReports.css';

const ThreatReports = () => {
  const { currentUser } = useAuth();
  const [reports, setReports] = useState([]);
  const [error, setError] = useState(null);
  const location = useLocation();

  useEffect(() => {
    // Scroll to "Threat Reports" section if hash is set
    if (location.hash === '#ThreatReportsHeading') {
      const scrollTimeout = setTimeout(() => {
        const element = document.getElementById('ThreatReportsHeading');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);

      // Clear timeout on unmount
      return () => clearTimeout(scrollTimeout);
    }
  }, [location.hash]);

  useEffect(() => {
    const fetchReportsAndCleanup = async () => {
      if (!currentUser || !currentUser.uid) return;

      try {
        const threeMonthsAgo = new Date();
        threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3); // ✅ Change to 3 months ago

        const q = query(
          collection(firestore, 'threatReports'),
          where('managerId', '==', currentUser.uid),
          orderBy('timestamp', 'desc')
        );

        const querySnapshot = await getDocs(q);
        const reportsData = [];

        for (const docSnapshot of querySnapshot.docs) {
          const report = docSnapshot.data();
          const reportDate = report.timestamp.toDate();

          if (reportDate < threeMonthsAgo) { // ✅ Delete reports older than 3 months
            console.log(`🗑 Deleting old report: ${report.title}`);
            await deleteDoc(doc(firestore, 'threatReports', docSnapshot.id)); // Delete from Firestore
          } else {
            reportsData.push({ ...report, id: docSnapshot.id }); // Keep only valid reports
          }
        }

        setReports(reportsData);
      } catch (error) {
        console.error('Error fetching or deleting old reports:', error);
        setError('Unable to fetch reports. Please try again later.');
      }
    };

    fetchReportsAndCleanup();
  }, [currentUser]);

  const handleDelete = async (reportId) => {
    const confirmed = window.confirm('Are you sure you want to delete this report?');
    if (confirmed) {
      try {
        await deleteDoc(doc(firestore, 'threatReports', reportId));
        setReports(reports.filter((report) => report.id !== reportId));
      } catch (error) {
        console.error('Error deleting report:', error);
        setError('Failed to delete the report. Please try again.');
      }
    }
  };

  if (!currentUser) {
    return <div role="status" aria-live="polite">Loading...</div>;
  }

  return (
    <div className="threat-reports-container">
      <h1 id="ThreatReportsHeading">Threat Reports</h1>
      <p>These threat reports are used to populate the graphs on the 'Statistics' page</p>
      <br></br>
      <p>Most Recent First</p>
      <p>(A maximum of 1000 reports can be stored, with older reports automatically replaced by newer ones.)</p> 
      <br />

      {error && <p className="error-message" role="alert">{error}</p>}

      {reports.length > 0 ? (
        <ul className="threat-reports-list">
          {reports.map((report, index) => (
            <li key={report.id} className="threat-report-item">
              <h2>{report.title}</h2>
              <br></br>
              <p className="threat-report-type"><strong>Threat Type:</strong> {report.threatType}</p> 
              <br></br>
              <p className="threat-report-description"><strong>Description:</strong> {report.description}</p>
              <br></br>
              
              <p className="threat-report-email"><strong>Reported by: {report.reportedByEmail}</strong></p>
              <p className="threat-report-timestamp">
                <strong>Date: {new Date(report.timestamp.toDate()).toLocaleString()}</strong>
              </p>

              <button
                className="delete-button"
                onClick={() => handleDelete(report.id)}
                aria-label={`Delete report titled ${report.title}`}
              >
                <FontAwesomeIcon icon={faTrash} aria-hidden="true" /> Delete
              </button>
            </li>
          ))}
        </ul>
      ) : (
        <p>No reports available.</p>
      )}
    </div>
  );
};

export default ThreatReports;
