import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './FinisherPageTwo.css';
import Confetti from 'react-confetti';

const FinishingPageTwo = () => {
  const navigate = useNavigate();
  const [recycleConfetti, setRecycleConfetti] = useState(true);

  useEffect(() => {
    // Set a timer to stop the confetti recycling after 5 seconds
    const timer = setTimeout(() => {
      setRecycleConfetti(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module8/credit-profile#CreditProfileHeading');
  };

  return (
    <div className="FinishersPageTwo-introduction-container">
      <Confetti 
        width={window.innerWidth}
        height={window.innerHeight}
        recycle={recycleConfetti} 
        numberOfPieces={500} 
        aria-hidden="true" // Confetti is decorative, not for screen readers
      />

      <section className="FinishersPageTwo-story-introduction">
        <h2 role="alert">🎉 Congratulations, You Completed CyberShield Intermediate! 🎉</h2>

        <div className="FinishersPageTwo-introduction-content">
          <div className="FinishersPageTwo-introduction-text">
            <p>
              You've now gained valuable knowledge and tools to protect yourself against cyber attacks and strengthen your online security. Stay vigilant and continue safeguarding your digital world.
            </p>
            <p>
              Now, it's time to advance to the next module: <strong>Business Academy: Essential Cybersecurity Workplace Skills</strong>. Keep up the great work!
            </p>

            <button 
              className="FinishersPageTwo-start-button" 
              onClick={() => navigate('/')} 
              aria-label="Return to home page"
            >
              🏠 Home
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FinishingPageTwo;
