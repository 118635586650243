import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';
import '../Page3/RecognizingThreatsPartTwo.css';

const SocialMediaSafetyPartTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const task2Ref = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const goToNext = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourse/page6/social-media-drop-and-drag-game#DragAndDropGame');
    }
  };

  useEffect(() => {
    if (location.hash === '#Task2' && task2Ref.current) {
      task2Ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);

    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  const goBack = () => {
    navigate('/beginnerscourse/page6/social-media-safety#SocialMediaSafety');
  };

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to Social Media Safety overview"
      >
        ←
      </button>
      <section className="module-section">
        <div>
          <section className="module-section" style={{ textAlign: 'center' }} ref={task2Ref}>
            <h2>Task 2: Review App Permissions</h2>
          </section>

          <div className="bordered-container alt-color">
            <div className="grid-item text">
              <div className="task">
                <p>
                  <strong >Objective:</strong> Ensure third-party apps connected to your social media account have appropriate access.
                </p>
                <p>
                  <strong>Action:</strong> Check the apps connected to your account and remove any that you no longer use or trust. For the remaining apps, limit their access to only the necessary information.
                </p>
                <p>
                  <strong>Outcome:</strong> Your social media account is now more secure, with limited access to your personal information.
                </p>
              </div>
            </div>
          </div>

          <section className="module-section" style={{ textAlign: 'center' }}>
            <h2>Task 3: Be Cautious About Personal Information</h2>
          </section>

          <div className="bordered-container">
            <div className="grid-item text">
              <div className="task">
                <p>
                  <strong >Objective:</strong> Avoid sharing sensitive personal information that could be exploited.
                </p>
                <p>
                  <strong>Action:</strong> Before posting, think twice about sharing your home address, phone number, or travel plans. Share photos of your recent trip after returning, avoiding real-time updates that expose your location.
                </p>
                <p>
                  <strong>Outcome:</strong> By sharing wisely, you protect yourself from potential risks and maintain a positive online presence.
                </p>
              </div>
            </div>
          </div>

          <div className="video-container" style={{ position: 'relative' }}>
            <LogoOverlay />
            <video
              ref={videoRef}
              src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FPageSixPartTwo.mp4?alt=media&token=3fdb6084-ae60-49b1-9859-843d37dcdb1e"
              width="100%"
              onEnded={handleVideoEnd}
              onError={(e) => console.error("Video Error:", e)}
              controls
              controlsList="nodownload noremoteplayback noplaybackrate"
              disablePictureInPicture
              aria-label="Educational video on social media safety"
            >
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className="button-container">
          <button
            className="next-button"
            onClick={goToNext}
            aria-label="Proceed to Social Media Drag-and-Drop Game"
          >
            Start Game
          </button>
          {showWarning && (
            <p className="warning-text22" role="alert">
              You must watch the video before proceeding.
            </p>
          )}
        </div>
      </section>
    </div>
  );
};

export default SocialMediaSafetyPartTwo;
