import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom'; // Added useLocation import
import '../Page3/RecognizingThreatsPartTwo.css'; // Adjusted path

const SafeBrowsingPartTwo = () => {
  const [showQuiz, setShowQuiz] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Get location to handle URL hash

  const maliciouslink = "https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2Fmaliciouslink.png?alt=media&token=5e4c6b67-8a29-4f82-97f7-744f5df40af9";
  
  const toggleQuiz = () => {
    setShowQuiz(true);
  };

  const goToGame = () => {
    navigate('/beginnerscourse/page5/safe-browsing-game'); // Replace with the actual game route
  };

  const goBack = () => {
    navigate('/beginnerscourse/page5/safe-browsing#BasicsTitle'); // Replace with the actual previous page route
  };

  // Scroll to the AvoidingSuspiciousLinks section if hash is present
  useEffect(() => {
    if (location.hash === '#BasicsTitle') {
      const element = document.getElementById('BasicsTitle');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [location]);
  

  return (
    <div className={`introduction-container2 ${showQuiz ? 'hide-next-button' : ''}`}>
      {!showQuiz && (
        <div className="audio-controls" aria-hidden="true"></div>
      )}

      <section className="module-section">
        <button
          className="back-arrow-button"
          onClick={goBack}
          aria-label="Go back to Safe Browsing overview"
        >
          ←
        </button>
        {!showQuiz && (
          <>
            <div>
              {/* Updated h2 for consistency */}
              <h1 id="BasicsTitle" className="BasicsTitle">
                  Avoiding suspicious links   
                      </h1>
                 <div className="bordered-container alt-color">
                <p>
                  Beware of malicious links sent by unknown sources like the example below! Hackers often disguise harmful links in emails, messages, and pop-ups.{' '}
                  <strong>
                    <span style={{ color: 'rgb(201, 3, 3)' }}>Clicking on these could lead to trouble!</span>
                  </strong>
                </p>
                <hr></hr>
                <br></br>
                <p>
                  <strong>
                    <span style={{ color: 'rgb(201, 3, 3)' }}>Tip:</span>
                  </strong>{' '}
                  If you don't recognise the sender or the source, don't click! Think of it as avoiding a trap in a maze. Stay alert and keep your cyber defenses strong! Below is an example of a suspicious link
                </p>
                <div style={{ textAlign: 'center', border: '1px solid rgb(201, 3, 3)',  padding: '1rem', padding: '1rem', }}>
                  <img
                    src={maliciouslink}
                    alt="Example of a malicious link disguised as free accounting software"
                    className="thumbnail"
                  />
                </div>
                <br></br>
                <hr></hr>
              </div>


              <div className="module-content">
          <div className="bordered-container">
            <h2>Safe Browsing - Key Points</h2>
            <br />
            <ul className="KeyPoints">
              <li>
                <span className="number-circle">1</span> 
                Ensure the website uses https, but remember that this alone doesn't make the website safe. It's just one part of staying secure online.
              </li>
              <br />
              <li>
                <span className="number-circle">2</span> Hackers disguise harmful links in emails, messages, and pop-ups.
              </li>
              <br />
              <li>
                <span className="number-circle">3</span> Clicking on unknown links can lead to trouble.
              </li>
              <br></br>
              <li>
                <span className="number-circle">4</span> If you don't recognise the sender or source, don't click the link.
              </li>
              <br />
              <li>
                <span className="number-circle">5</span> It is good practice to go directly to the official website rather than through links.
              </li>
              <br />
              <li>
                <span className="number-circle">6</span> Avoid downloading music, games, software, and other content from unauthorised or illegitimate websites, as these sources are often infected with malware or viruses.
              </li>
        
            </ul>
          </div>
        </div>

            </div>
            <button
              className="start-quiz-button"
              onClick={goToGame}
              aria-label="Proceed to Safe Browsing drag-and-drop game"
            >
              Start Game
            </button>
          </>
        )}
      </section>
    </div>
  );
};

export default SafeBrowsingPartTwo;
