import React, { useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Poster.css';


const Poster = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const mobileDeviceSecurityRef = useRef(null);
  const mobile = "https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/images%2Fmobilephonesecurity.webp?alt=media&token=f5a6d17e-4872-4d69-a751-91b6a678833a";

  const goToNext = () => {
    navigate('/beginnerscourseparttwo/module4/mobile-security-quiz');
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module4/mobile-security');
  };

  // Scroll to Mobile Device Security section if hash is present
  useEffect(() => {
    if (location.hash === '#MobileDeviceSecurity' && mobileDeviceSecurityRef.current) {
      mobileDeviceSecurityRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="poster-container">
      <button 
        className="back-arrow-button" 
        onClick={goBack} 
        aria-label="Go back to mobile security section"
      >
        ←
      </button>
      
      <h1 ref={mobileDeviceSecurityRef}>Mobile Device Security</h1>
      <br></br>

      <div className="poster-content">
        <img 
          src={mobile} 
          alt="Infographic on Mobile Security" 
          className="poster-image"
        />
        
        <section>
          <h3>Why Mobile Device Security is Important</h3>
          <br></br>
          <p>
            Mobile devices store a wealth of personal information, including contacts, photos, 
            emails, and banking information. Securing your device helps protect this sensitive 
            data from unauthorised access and potential threats.
          </p>
        </section>

        <section>
          <h3>Tips to Secure Your Mobile Device</h3>
          <br></br>
          <ul>
            <li><strong>Use Strong Passwords:</strong> Enable a strong passcode or biometric authentication (fingerprint or facial recognition) to unlock your device.</li>
            <li><strong>Keep Your Software Updated:</strong> Regularly update your device's operating system and apps to protect against security vulnerabilities.</li>
            <li><strong>Install Apps from Trusted Sources:</strong> Only download apps from official app stores like Google Play or the Apple App Store.</li>
            <li><strong>Remember Login Credentials:</strong> Memorise your login details for services like iCloud or Samsung Cloud or the equivalent to quickly access your account and lock, locate, or erase data if your device is lost or stolen. Familiarise yourself with this process so you can act swiftly if needed.</li>
            <li><strong>Be Cautious with Public Wi-Fi:</strong> Avoid accessing sensitive information over public Wi-Fi networks. Use a VPN if necessary.</li>
            <li><strong>Back Up Your Data:</strong> Regularly back up your data to the cloud or a secure external drive.</li>
            <li><strong>Avoid Phishing Scams:</strong> Be wary of unsolicited messages asking for personal information or containing suspicious links.</li>
            <li><strong>Use Anti-Malware Software:</strong> Run a security scan using a reputable anti-malware app.</li>
          </ul>
        </section>

        <section>
          <h3>Actions to Take If Your Device is Compromised</h3>
          <br></br>
          <ul>
            <li><strong>Login to Your Cloud Account:</strong> Your cloud account should have a backup of all your data (If not, you should set this up as soon as possible). You can then wipe your device, making it unusable. This will ensure no one else can get hold of your private data.</li>
            <li><strong>Change Passwords:</strong> Immediately change passwords for all important accounts accessed through your mobile device.</li>
            <li><strong>Notify Your Bank:</strong> If you suspect financial information is at risk, contact your bank to secure your accounts.</li>
          </ul>
        </section>
      </div>

      <div className="button-container bottom">
        <button 
          className="nav-button next-button" 
          onClick={goToNext} 
          aria-label="Proceed to the mobile security quiz"
        >
          Quiz
        </button>
      </div>
    </div>
  );
};

export default Poster;
