import React, { useState, useEffect } from 'react';
import { getAuth, deleteUser, sendEmailVerification } from 'firebase/auth';
import { firestore } from '../../firebase';
import { useNavigate, useLocation } from 'react-router-dom';
import DOMPurify from 'dompurify';
import './Settings.css';
import { doc, deleteDoc, getDoc, updateDoc, arrayRemove, arrayUnion, query, collection, where, getDocs } from 'firebase/firestore';

const Settings = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(true);
  const [linkRequests, setLinkRequests] = useState([]);
  const [showWarning, setShowWarning] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [employeeWarning, setEmployeeWarning] = useState(false);
  const [userProfile, setUserProfile] = useState(null);
  const [emailVerified, setEmailVerified] = useState(false);
  const [nextPaymentDate, setNextPaymentDate] = useState('');
  const [standardUserEmail, setStandardUserEmail] = useState('');
  const [linking, setLinking] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;
  const location = useLocation(); // Track hash location

  // Redirect unauthorized users to the login page
  useEffect(() => {
    if (!user) {
      navigate('/login');
    }
  }, [user, navigate]);

  // Smooth scroll to Account Settings section if hash is present
  // Smooth scroll to Account Settings section if hash is present
  useEffect(() => {
    if (location.hash === '#AccountSettingsHeading') {
      const scrollTimeout = setTimeout(() => {
        const element = document.getElementById('AccountSettingsHeading');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        } else {
          // Retry scroll in case the element hasn't been rendered yet
          setTimeout(() => {
            const retryElement = document.getElementById('AccountSettingsHeading');
            if (retryElement) {
              retryElement.scrollIntoView({ behavior: 'smooth' });
            }
          }, 500); // Delay retry slightly
        }
      }, 300); // Slight delay ensures rendering is complete
  
      return () => clearTimeout(scrollTimeout);
    }
  }, [location.hash]);
  



  // Fetch manager's profile details and employees
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(firestore, 'users', user.uid);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists()) {
          const userData = userDoc.data();
          setUserProfile(userData.profile);
          setEmployees(userData.employees || []);
          setLinkRequests(userData.linkRequests || []);

          // Set the next payment date directly from Firestore
          if (userData.profile?.nextPaymentDate) {
            const paymentDate = new Date(userData.profile.nextPaymentDate); // nextPaymentDate is already stored as ISO string
            setNextPaymentDate(paymentDate.toLocaleDateString());
          }
        }
        setEmailVerified(user.emailVerified);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
      setLoading(false);
    };

    fetchUserData();
  }, [user]);

  // Sanitize inputs
  const sanitizeInput = (input) => {
    return DOMPurify.sanitize(input);
  };

  // Unlink employee from the manager's account
const unlinkEmployeeAccount = async (employeeId) => {
  try {
    // Update the employee document to remove the manager reference
    await updateDoc(doc(firestore, 'users', employeeId), {
      'profile.managerId': '', // Ensure that the managerId in the standard user's profile is set to an empty string
    });
    // Update the manager document to remove the employee from the employees list
    await updateDoc(doc(firestore, 'users', user.uid), {
      employees: arrayRemove(employeeId),
    });

    // Update the local state to reflect the changes
    setEmployees((prevEmployees) =>
      prevEmployees.filter((empId) => empId !== employeeId)
    );

    alert('Employee unlinked successfully.');
  } catch (error) {
    console.error('Error unlinking employee:', error);
    alert('Error unlinking employee.');
  }
};


  // Accept a manager link request
  const acceptLinkRequest = async (request) => {
    try {
      // Update the standard user's document to include the managerId
      await updateDoc(doc(firestore, 'users', request.userId), {
        'profile.managerId': user.uid,
      });
  
      // Update the manager's document to include the employee in their employees array
      await updateDoc(doc(firestore, 'users', user.uid), {
        employees: arrayUnion(request.userId),
        linkRequests: arrayRemove(request),
      });
  
      setLinkRequests((prevRequests) =>
        prevRequests.filter((r) => r.userId !== request.userId)
      );
  
      alert('Link request accepted.');
    } catch (error) {
      console.error('Error accepting link request:', error);
      alert('Error accepting link request.');
    }
  };
  

  // Reject a manager link request
  const rejectLinkRequest = async (request) => {
    try {
      await updateDoc(doc(firestore, 'users', user.uid), {
        linkRequests: arrayRemove(request),
      });

      setLinkRequests((prevRequests) =>
        prevRequests.filter((r) => r.userId !== request.userId)
      );

      alert('Link request rejected.');
    } catch (error) {
      console.error('Error rejecting link request:', error);
      alert('Error rejecting link request.');
    }
  };

  // Handle the manager's account deletion without deleting employees
  const handleDeleteAccount = async () => {
    try {
      for (const employeeId of employees) {
        await unlinkEmployeeAccount(employeeId);
      }
      await deleteDoc(doc(firestore, 'users', user.uid));
      await deleteUser(user);
      navigate('/login');
    } catch (error) {
      console.error('Error deleting account:', error);
      alert('Error deleting account. Please try again later.');
    }
  };

  const handleShowWarning = () => {
    setShowWarning(true);
  };

  const handleCancelDeletion = () => {
    setShowWarning(false);
  };

  const handleEmployeeWarning = (employeeId) => {
    setSelectedEmployee(employeeId);
    setEmployeeWarning(true);
  };

  const handleCancelEmployeeDeletion = () => {
    setSelectedEmployee(null);
    setEmployeeWarning(false);
  };

  const confirmEmployeeUnlink = () => {
    if (selectedEmployee) {
      unlinkEmployeeAccount(selectedEmployee);
    }
    setEmployeeWarning(false);
  };

  // Handle email verification
  const handleVerifyEmail = async () => {
    try {
      if (user && !user.emailVerified) {
        await sendEmailVerification(user);
        alert('Verification email has been sent to your email address. Please check your inbox.');
      } else {
        alert('Your email is already verified.');
      }
    } catch (error) {
      console.error('Error sending verification email:', error);
      alert('Error sending verification email. Please try again later.');
    }
  };

  // Send a link request to a standard user
  const linkStandardUser = async () => {
    setLinking(true);
    setError('');
    setSuccess('');

    // Sanitize and validate the email input
    const sanitizedEmail = sanitizeInput(standardUserEmail);
    if (!sanitizedEmail) {
      setError('Invalid email format.');
      setLinking(false);
      return;
    }

    try {
      // Query Firestore to find the standard user's document
      const standardUserQuerySnapshot = await getDocs(
        query(
          collection(firestore, 'users'),
          where('profile.email', '==', sanitizedEmail),
          where('profile.accountType', '==', 'standard')
        )
      );
      if (standardUserQuerySnapshot.empty) {
        setError('No standard user found with this email.');
      } else {
        const standardUserDoc = standardUserQuerySnapshot.docs[0];
        const standardUserId = standardUserDoc.id;

        // Create a link request for the standard user to approve
        const standardUserDocRef = doc(firestore, 'users', standardUserId);
        const managerProfile = userProfile;

        await updateDoc(standardUserDocRef, {
          linkRequests: arrayUnion({ userId: user.uid, userEmail: managerProfile.email })
        });

        setSuccess('Link request sent to standard user successfully!');
      }
    } catch (error) {
      console.error('Error linking standard user:', error);
      setError('An error occurred. Please try again later.');
    }
    setLinking(false);
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="settings-container-manager">
      <h1 className="settings-title" id="AccountSettingsHeading">Account Settings</h1>


      {userProfile && (
        <div className="settings-card user-info-card">
          <h2 className="settings-subheading">User Details</h2>
          <div className="settings-text-container">
            <p className="settings-text"><strong>Name:</strong> {userProfile.name}</p>
            <p className="settings-text"><strong>Email:</strong> {userProfile.email}</p>
            <p className="settings-text">
              <strong>Email Verified:</strong> {emailVerified ? 'Yes' : 'No'}
            </p>
            {!emailVerified && (
              <button className="settings-button" onClick={handleVerifyEmail}>
                Verify Email
              </button>
            )}
            {nextPaymentDate && (
              <p className="settings-text"><strong>Next Payment Date:</strong> {nextPaymentDate}</p>
            )}
            
          </div>
        </div>
      )}

      {/* Section for pending link requests */}
      <div className="settings-card settings-section">
        <h2 className="settings-subheading">Pending Link Requests</h2>
        {linkRequests.length === 0 ? (
          <p className="settings-text">No pending requests.</p>
        ) : (
          linkRequests.map((request) => (
            <div className="settings-card link-request-card" key={request.userId}>
              <p><strong>User Email:</strong> {request.userEmail}</p>
              <div className="settings-button-group">
                <button className="settings-button" onClick={() => acceptLinkRequest(request)}>
                  Accept
                </button>
                <button className="settings-danger-button2" onClick={() => rejectLinkRequest(request)}>
                  Reject
                </button>
              </div>
            </div>
          ))
        )}
      </div>

      {/* Section for linking a standard user */}
      <div className="settings-card settings-section">
        <h2 className="settings-subheading">Link Personal User</h2>
        <input
          type="email"
          placeholder="Enter Standard User's Email"
          value={standardUserEmail}
          className="settings-input"
          maxLength={100} // Limit email input length to 100 characters
          onChange={(e) => setStandardUserEmail(e.target.value)}
        />
       <button
  className="settings-button"
  onClick={linkStandardUser}
  disabled={linking}
  aria-label={linking ? 'Sending link request' : 'Send link request to manager'}
  aria-live="polite"
 >
  {linking ? 'Sending Request...' : 'Send Link Request'}
 </button>

        {error && <p className="settings-error">{error}</p>}
        {success && <p className="success-text">{success}</p>}
      </div>

      {/* Section for unlinking individual employees */}
      <div className="settings-card settings-section">
        <h2 className="settings-subheading">Manage Employee Accounts</h2>
        {employees.length === 0 ? (
          <p className="settings-text">You have no employees linked to your account.</p>
        ) : (
          <div className="settings-grid">
            {employees.map((employeeId) => (
              <EmployeeCard
                key={employeeId}
                employeeId={employeeId}
                handleEmployeeWarning={handleEmployeeWarning}
              />
            ))}
          </div>
        )}
      </div>

      {/* Section for deleting manager account */}
 <div className="settings-card">
  <h2 className="settings-subheading" id="delete-account-heading">Delete Your Account</h2>
  <p className="settings-text" id="delete-account-description">
    Deleting your account will unlink your employees but not delete their accounts.
    This action cannot be undone, and your data will be permanently lost.
  </p>
  <button
    className="settings-danger-button2"
    onClick={handleShowWarning}
    aria-describedby="delete-account-description"
    aria-label="Delete your account permanently"
  >
    Delete My Account
  </button>
 </div>

  {/* Section for requesting user data */}
  <div className="settings-card">
  <h2 className="settings-subheading" id="request-data-heading">Request Your Data</h2>
  <p className="settings-text">
    If you would like to request a copy of your personal data, please email
    <a href="mailto:contact@becyberaware.co.uk"> contact@becyberaware.co.uk</a>.
  </p>
  </div>

 {/* Warning Modal for Manager Account Deletion */}
{showWarning && (
  <div
    className="settings-warning-modal"
    role="dialog"
    aria-labelledby="delete-account-title"
    aria-describedby="delete-account-description"
    aria-modal="true"
  >
    <h3 className="settings-warning-title" id="delete-account-title">
      Are you sure you want to delete your account?
    </h3>
    <p className="settings-text" id="delete-account-description">
      This action cannot be undone. All employee accounts will remain, but they will be unlinked from your account.
    </p>
    <div className="settings-warning-buttons">
      <button
        className="settings-danger-button2"
        onClick={handleDeleteAccount}
        aria-label="Confirm permanent deletion of your manager account"
      >
        Yes, delete my account
      </button>
      <button
        className="settings-cancel-button"
        onClick={handleCancelDeletion}
        aria-label="Cancel account deletion and keep your account"
      >
        Cancel
      </button>
    </div>
  </div>
)}



      {/* Warning Modal for Employee Unlinking */}
      {employeeWarning && (
        <div className="settings-warning-modal">
          <h3 className="settings-warning-title">Are you sure you want to unlink this employee?</h3>
          <p className="settings-text">This action cannot be undone, but the employee's account will not be deleted.</p>
          <div className="settings-warning-buttons">
            <button className="settings-danger-button" onClick={confirmEmployeeUnlink}>
              Yes, unlink employee account
            </button>
            <button className="settings-cancel-button" onClick={handleCancelEmployeeDeletion}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
 };

     // Component to fetch and display employee details
    const EmployeeCard = ({ employeeId, handleEmployeeWarning }) => {
  const [employeeData, setEmployeeData] = useState(null);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        const employeeDoc = await getDoc(doc(firestore, 'users', employeeId));
        if (employeeDoc.exists()) {
          setEmployeeData(employeeDoc.data());
        }
      } catch (error) {
        console.error('Error fetching employee data:', error);
      }
    };

    fetchEmployeeData();
  }, [employeeId]);

  if (!employeeData) return null;

  return (
    <div className="settings-card employee-card">
      <div className="settings-text-container">
        <h3 className="settings-employee-email">{employeeData.profile.email}</h3>
        <h4 className="settings-employee-name">{employeeData.profile.name}</h4>
        <button className="settings-danger-button" onClick={() => handleEmployeeWarning(employeeId)}>
          Unlink Employee
        </button>
      </div>
    </div>
  );
};

export default Settings;
