import React, { useRef, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoOverlay from '../../components/LogoOverlayVideo/LogoOverlay';

const RecognizingCyberThreats = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const videoRef = useRef(null);
  const recognizingThreatsRef = useRef(null);
  const warningRef = useRef(null);

  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  const handleNextClick = () => {
    if (isVideoWatched) {
      navigate('/businessacademy/moduletwo/phishing-tasks#PhishingHeading');
    } else {
      setShowWarning(true);
      warningRef.current?.focus();
    }
  };

  const goBack = () => {
    navigate('/businessacademy/moduleone/employee-role#EmployeeRoleHeading');
  };

  useEffect(() => {
    if (location.hash === '#RecognizingCyberThreatsHeading' && recognizingThreatsRef.current) {
      recognizingThreatsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  useEffect(() => {
    const videoElement = videoRef.current;
    videoElement.addEventListener('timeupdate', handleTimeUpdate);
    return () => {
      videoElement.removeEventListener('timeupdate', handleTimeUpdate);
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container" role="main">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the previous page"
      >
        ←
      </button>

      <div className="cybersecurity-basics-header">
        <h1 ref={recognizingThreatsRef} id="RecognizingCyberThreatsHeading" tabIndex="0">
          Recognising Cyber Threats
        </h1>
      </div>

      <section className="module-section" role="region" aria-labelledby="RecognizingCyberThreatsHeading">
        <div className="video-container" style={{ position: 'relative' }}>
          <LogoOverlay />
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FRecognisingCyberThreats%20(1).mp4?alt=media&token=91e0438f-89c5-48f2-97bd-04cd3c13eeec"
            width="100%"
            onEnded={handleVideoEnd}
            onError={(e) => console.error("Video Error:", e)}
            controls
            controlsList="nodownload noremoteplayback noplaybackrate"
            disablePictureInPicture
            playsInline
            aria-label="Recognising Cyber Threats training video"
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <br /><br />

        <button
          className="start-quiz-button"
          onClick={handleNextClick}
         
          aria-disabled={!isVideoWatched}
        >
          Next
        </button>

        {showWarning && (
          <p
            className="warning-text"
            ref={warningRef}
            role="alert"
            tabIndex="0"
          >
            You must watch the entire video before proceeding.
          </p>
        )}
      </section>
    </div>
  );
};

export default RecognizingCyberThreats;
