import React, { useRef, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../ModuleTwo/WhatIsCyberSecurity.css';

const MobileSecurity = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const mobileSecurityRef = useRef(null);
  const videoRef = useRef(null);
  const [isVideoWatched, setIsVideoWatched] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [lastValidTime, setLastValidTime] = useState(0);

  // Navigate to the poster page only if the video has been fully watched.
  const goToPoster = () => {
    if (!isVideoWatched) {
      setShowWarning(true);
    } else {
      navigate('/beginnerscourseparttwo/module4/mobile-security-quiz');
    }
  };

  const goBack = () => {
    navigate('/beginnerscourseparttwo/module3/phishing#PhishingHeading');
  };

  // Called when the video finishes playing.
  const handleVideoEnd = () => {
    setIsVideoWatched(true);
    setShowWarning(false);
  };

  // Prevent the user from skipping ahead by more than 2 seconds.
  const handleTimeUpdate = () => {
    const currentTime = videoRef.current.currentTime;
    if (currentTime > lastValidTime + 2) {
      videoRef.current.currentTime = lastValidTime;
    } else {
      setLastValidTime(currentTime);
    }
  };

  // Scroll to the Mobile Security section if the URL hash is present.
  useEffect(() => {
    if (location.hash === '#MobileSecurityHeading' && mobileSecurityRef.current) {
      mobileSecurityRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  // Add the timeupdate event listener to the video.
  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.addEventListener('timeupdate', handleTimeUpdate);
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [lastValidTime]);

  return (
    <div className="cybersecurity-basics-container" ref={mobileSecurityRef}>
      <button 
        className="back-arrow-button" 
        onClick={goBack} 
        aria-label="Go back to the phishing section"
      >
        ←
      </button>
      <br></br>
      <br></br>

      <section className="module-section">
      <div className="cybersecurity-basics-header centered-header">
              <h1>Mobile Security</h1>
            </div>
        <div className="video-container" style={{ position: 'relative' }}>
          <video
            ref={videoRef}
            src="https://firebasestorage.googleapis.com/v0/b/safe-bytes.appspot.com/o/videos%2FMastering%20Mobile%20Device%20Security_%20Protect%20Your%20Digital%20Life.mp4?alt=media&token=75bb26e7-4c9c-43d4-b651-78fb0e4884ee"
            controls
            width="100%"
            playsInline
            onEnded={handleVideoEnd}
            aria-label="Video on Mastering Mobile Device Security: Protect Your Digital Life"
          >
            Your browser does not support the video tag.
          </video>
        </div>

        <button 
          className="start-quiz-button" 
          onClick={goToPoster} 
          aria-label="Go to mobile security quiz"
        >
         Start Quiz
        </button>
        {showWarning && (
          <p className="warning-text" role="alert">
            You must watch the full video before proceeding.
          </p>
        )}
      </section>
    </div>
  );
};

export default MobileSecurity;
