import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import "../ModuleOne/WhatIsCyberSecurity.css";

const VishingQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(12).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(12).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(12).fill(null));
  const [showStar, setShowStar] = useState(false);
  const auth = getAuth();

  const questions = [
    {
      question: "What is vishing?",
      options: [
        "Phishing attempts via SMS messages",
        "Phishing attempts via voice calls",
        "Phishing attempts via emails",
        "Phishing attempts via social media"
      ],
      correctAnswer: "Phishing attempts via voice calls"
    },
    {
      question: "What should you do if you receive an unexpected call asking for sensitive information?",
      options: [
        "Provide the information quickly",
        "Hang up and verify the request with the organisation directly",
        "Save the number for future reference so you can ignore the number if they call back"
      ],
      correctAnswer: "Hang up and verify the request with the organisation directly"
    },
    {
      question: "What is a common sign of vishing?",
      options: [
        "Receiving a friendly call from a known number",
        "Urgent or threatening language",
        "A caller asking for a callback at your convenience"
      ],
      correctAnswer: "Urgent or threatening language"
    },
    {
      question: "How should you react to a caller requesting verification codes sent via SMS or email?",
      options: [
        "Provide the codes immediately",
        "Request the caller's name and badge number",
        "Refuse to provide the codes and report the call",
        "Ignore the request"
      ],
      correctAnswer: "Refuse to provide the codes and report the call"
    },
    {
      question: "Which of the following is a good practice to avoid vishing?",
      options: [
        "Answering all calls to ensure no missed information",
        "Using call-blocking features to reduce unwanted calls",
        "Providing information only when asked by a friendly voice",
        "Keeping your phone number private at all costs"
      ],
      correctAnswer: "Using call-blocking features to reduce unwanted calls"
    },
    {
      question: "What should you do if a caller pressures you to act quickly?",
      options: [
        "Comply immediately to avoid trouble",
        "Calmly hang up and verify the situation independently",
        "Ask the caller for a supervisor",
        "Record the conversation for later"
      ],
      correctAnswer: "Calmly hang up and verify the situation independently"
    },
    {
      question: "Why is it important to verify the caller's identity independently?",
      options: [
        "To avoid making embarrassing mistakes",
        "To protect yourself from scams",
        "To impress the caller with your knowledge"
      ],
      correctAnswer: "To protect yourself from scams"
    },
    {
      question: "Which of these is a red flag for a vishing attempt?",
      options: [
        "A request for personal information from an unknown number",
        "An invitation to a family event",
        "A reminder from your doctor's office",
        "A friend or family member requesting money"
      ],
      correctAnswer: "A request for personal information from an unknown number"
    },
    {
      question: "What is caller ID spoofing?",
      options: [
        
        "A tool to protect your number from being shown",
        "A method used by scammers to display a fake caller ID",
        "A way to block calls from specific numbers"
      ],
      correctAnswer: "A method used by scammers to display a fake caller ID"
    },
    {
      question: "Which type of offer should raise your suspicion during a call?",
      options: [
        "Discounts from your favorite store",
        "Unbelievably good offers",
        "Regular updates from your service provider",
        "Friendly advice from a colleague"
      ],
      correctAnswer: "Unbelievably good offers"
    },
    {
      question: "What is a safe approach when dealing with unsolicited calls?",
      options: [
        "Hang up immediately and call the company using an official number",
        "Engage the caller to gather more information",
        "Save the caller's number for future reference",
        "Report the call to your family members"
      ],
      correctAnswer: "Hang up immediately and call the company using an official number"
    },
    {
      question: "Why is it important to stay calm during a vishing attempt?",
      options: [
        "To make a good impression on the caller",
        "To ensure you don't fall for pressure tactics",
        "To avoid unnecessary stress",
        "To record the conversation for later review"
      ],
      correctAnswer: "To ensure you don't fall for pressure tactics"
    }
  ];

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect =
        answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? "correct" : "incorrect";
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] =
          shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === shuffledQuestions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 8) {
      try {
        const user = auth.currentUser;
        if (user) {
          const userDocRef = doc(firestore, "users", user.uid);
          await updateDoc(userDocRef, {
            "progress.course3.module2_quiz2": true,
          });
          setShowStar(true);
        }
      } catch (error) {
        console.error("Error updating user progress:", error);
      }
    }
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(12).fill(null));
    setFeedback(Array(12).fill(null));
    setCorrectAnswers(Array(12).fill(null));
    setShowResults(false);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate("/businessacademy/moduletwo/social-engineering#SocialEngineeringHeading");
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && (
        <div className="falling-star" role="alert" aria-live="polite"></div>
      )}
      <section
        className="module-section2"
        role="region"
        aria-labelledby="quiz-title"
      >
        <h2 id="quiz-title" tabIndex="0">
          Recognising Vishing Attempts Quiz
        </h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={
                  feedback[currentQuestionIndex] ? "feedback" : undefined
                }
              >
                <legend
                  id={`question-${currentQuestionIndex}`}
                  tabIndex="0"
                  aria-live="polite"
                  aria-label={`Question ${
                    currentQuestionIndex + 1
                  }: ${shuffledQuestions[currentQuestionIndex]?.question}`}
                >
                  {shuffledQuestions[currentQuestionIndex]?.question}
                </legend>
                {shuffledQuestions[currentQuestionIndex]?.options.map(
                  (option, index) => (
                    <div key={index} className="quiz-option">
                      <label
                        className="quiz-option-label"
                        htmlFor={`option-${currentQuestionIndex}-${index}`}
                      >
                        <input
                          type="radio"
                          id={`option-${currentQuestionIndex}-${index}`}
                          name={`question-${currentQuestionIndex}`}
                          value={option}
                          checked={answers[currentQuestionIndex] === option}
                          onChange={() => handleAnswerChange(option)}
                          disabled={feedback[currentQuestionIndex] !== null}
                          aria-checked={
                            answers[currentQuestionIndex] === option
                          }
                        />
                        <span className="option-text">{option}</span>
                      </label>
                    </div>
                  )
                )}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  role="alert"
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === "correct"
                    ? "Correct!"
                    : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                </div>
              )}
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                    aria-label="Next Question"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                    aria-label="Submit Quiz"
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results" role="alert" aria-live="polite">
              <p tabIndex="0">Your score: {score} / 12</p>
              <br></br>
              {score >= 8 ? (
                <p tabIndex="0">
                  Congratulations! You passed the quiz and have a solid
                  understanding of recognising vishing attempts.
                </p>
              ) : (
                <p tabIndex="0">
                  You need to score at least 8 to pass. Please retake the quiz.
                </p>
              )}
              <div className="quiz-buttons">
                {score >= 8 ? (
                  <button
                    className="next-button"
                    onClick={goToNext}
                    aria-label="Next Lesson"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="retake-button"
                    onClick={handleRetake}
                    aria-label="Retake Quiz"
                  >
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default VishingQuiz;
