import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, deleteDoc, updateDoc, arrayRemove, arrayUnion } from 'firebase/firestore';
import { firestore } from '../firebase';
import './SettingsStandard.css';
import Footer from '../components/Footer/Footer';
import { useNavigate, useLocation } from 'react-router-dom';
import { query, collection, where, getDocs, writeBatch } from 'firebase/firestore';




// Sanitize input by escaping special characters
const sanitizeInput = (input) => {
  return input.replace(/&/g, "&amp;")
              .replace(/</g, "&lt;")
              .replace(/>/g, "&gt;")
              .replace(/\"/g, "&quot;")
              .replace(/'/g, "&#039;");
};

const SettingsStandard = () => {
  const auth = getAuth();
  const [user, setUser] = useState(null);
  const [linkRequests, setLinkRequests] = useState([]);
  const [managerName, setManagerName] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal
  const [timeLeftUntilDowngrade, setTimeLeftUntilDowngrade] = useState(null);


 

   // Smooth scroll to the Settings section if the hash is set
   useEffect(() => {
    if (location.hash === '#SettingsHeading') {
      const scrollTimeout = setTimeout(() => {
        const element = document.querySelector('.settings-title');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 200);

      // Clean up the timeout on unmount
      return () => clearTimeout(scrollTimeout);
    }
  }, [location.hash]);

  // Function to calculate time left before downgrade from payment date
const calculateTimeLeftFromPayment = (paymentDate) => {
  if (!paymentDate) return null; // Ensure paymentDate exists

  // Since paymentDate is stored as an ISO string, we can directly create a Date from it
  const baseDate = new Date(paymentDate);

  // Set downgrade date (one year after the payment date)
  const downgradeDate = new Date(baseDate);
  downgradeDate.setFullYear(downgradeDate.getFullYear() + 1);

  const now = new Date();
  const timeLeft = downgradeDate - now;

  // If timeLeft is negative, the account is already due for downgrade
  if (timeLeft <= 0) {
    return 'Your account is due for downgrade.';
  }

  // Convert time left into days
  const daysLeft = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  return `${daysLeft} days remaining until your account is downgraded to Free User.`;
};

  

// Fetch user data
useEffect(() => {
  const fetchUserData = async () => {
    const user = auth.currentUser;
    if (user) {
      const userDocRef = doc(firestore, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        setUser({ uid: user.uid, ...userData });
        setLinkRequests(userData.linkRequests || []);

        // Ensure createdAt exists and calculate downgrade time
        if (userData.profile?.paymentDate) {
          setTimeLeftUntilDowngrade(calculateTimeLeftFromPayment(userData.profile.paymentDate));
        }
        
        

        if (userData.profile?.managerId) {
          const managerDocRef = doc(firestore, 'users', userData.profile.managerId);
          const managerDocSnap = await getDoc(managerDocRef);
          if (managerDocSnap.exists()) {
            setManagerName(managerDocSnap.data().profile?.name || 'Unknown');
          }
        }
      }
    }
    setLoading(false);
  };
  fetchUserData();
}, [auth]);


   // Open the confirmation modal
   const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };



  // Proceed with account deletion
  const handleConfirmDelete = async () => {
    try {
      if (!user || !user.uid) {
        console.error('User data is missing.');
        return;
      }
  
      const userDocRef = doc(firestore, 'users', user.uid);
      
      // ✅ Step 1: Delete all threat reports created by this user
      const threatReportsQuery = query(
        collection(firestore, 'threatReports'),
        where('reportedBy', '==', user.uid)
      );
      const threatReportsSnapshot = await getDocs(threatReportsQuery);
      
      const batch = writeBatch(firestore);
      
      threatReportsSnapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
  
      await batch.commit(); // ✅ Execute batch deletion
  
      // ✅ Step 2: Delete the user document
      await deleteDoc(userDocRef);
      
      // ✅ Step 3: Delete Firebase Authentication User
      await auth.currentUser.delete();
  
      alert('Account and associated threat reports deleted successfully.');
      setIsModalOpen(false);
      navigate('/'); // Redirect after deletion
    } catch (error) {
      console.error('Error deleting account and reports:', error);
      alert('Error deleting account. Please try again.');
    }
  };
  
  if (loading) {
    return <div>Loading...</div>;
  }

  // Accept a manager link request
  const acceptLinkRequest = async (request) => {
    try {
      // Update the standard user's document to include the managerId
      await updateDoc(doc(firestore, 'users', user.uid), {
        'profile.managerId': request.userId,
        linkRequests: arrayRemove(request),
      });
  
      // Update the manager's document to include the employee in their employees array
      await updateDoc(doc(firestore, 'users', request.userId), {
        employees: arrayUnion(user.uid),
      });
  
      // Update the local state
      setLinkRequests((prevRequests) =>
        prevRequests.filter((r) => r.userId !== request.userId)
      );
  
      alert('Link request accepted.');
    } catch (error) {
      console.error('Error accepting link request:', error);
      alert('Error accepting link request.');
    }
  };

  // Reject a manager link request
  const rejectLinkRequest = async (request) => {
    try {
      await updateDoc(doc(firestore, 'users', user.uid), {
        linkRequests: arrayRemove(request),
      });

      setLinkRequests((prevRequests) =>
        prevRequests.filter((r) => r.userId !== request.userId)
      );

      alert('Link request rejected.');
    } catch (error) {
      console.error('Error rejecting link request:', error);
      alert('Error rejecting link request.');
    }
  };

  // Unlink from manager
  const handleUnlinkFromManager = async () => {
    try {
      // Update the user's document to remove the managerId
      await updateDoc(doc(firestore, 'users', user.uid), {
        'profile.managerId': '',
      });

      // Update the manager's document to remove the employee from the employees array
      await updateDoc(doc(firestore, 'users', user.profile.managerId), {
        employees: arrayRemove(user.uid),
      });

      // Update the local state
      setManagerName(null);
      setUser((prevUser) => ({
        ...prevUser,
        profile: { ...prevUser.profile, managerId: '' },
      }));

      alert('Successfully unlinked from manager.');
    } catch (error) {
      console.error('Error unlinking from manager:', error);
      alert('Error unlinking from manager. Please try again.');
    }
  };

  // Conditionally render the "Report Threat" button for standard users and admins
  const renderReportThreatButton = () => {
    if (user?.profile?.accountType === 'standard' || user?.profile?.accountType === 'admin') {
      return (
        <button
          className="report-threat-button"
          style={{ backgroundColor: 'red', color: 'white' }}
          onClick={() => navigate('/standard-report')}
        >
          Report Threat
        </button>
      );
    }
    return null;
  };

  const handleBackClick = () => {
    navigate('/profile'); // Navigate back to the profile page
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="settings-container">
        <button
          className="back-button-standardSettings"
          onClick={handleBackClick}
          style={{ alignSelf: 'flex-start', marginBottom: '1rem' }}
          aria-label="Go back to profile"
        >
          ← Back to Profile
        </button>
  
        <h1 className="settings-title-standard" id="settings-title" tabIndex="0">Settings</h1>
  
        {user?.profile && (
          <>
            {/* User Details Section */}
            {/* User Details Section */}
<div className="settings-card" role="region" aria-labelledby="user-details-heading">
  <h2 className="settings-subheading" id="user-details-heading">User Details</h2>
  <p className="settings-text"><strong>Name:</strong> {user.profile.name || 'Unknown'}</p>
  <p className="settings-text"><strong>Email:</strong> {user.profile.email || 'Not Available'}</p>
  
  {/* ✅ Add Account Type Display Here */}
  <p className="settings-text">
  <strong>Account Type:</strong>
  {user.profile.accountType === 'standard' ? ' Personal User' : 
   user.profile.accountType?.charAt(0).toUpperCase() + 
   user.profile.accountType?.slice(1).replace(/([a-z])([A-Z])/g, '$1 $2')}
</p>



  {user?.profile?.accountType === 'standard' && timeLeftUntilDowngrade !== null && (
    <p className="settings-text">
      <strong>Subscription Status:</strong> {timeLeftUntilDowngrade}
    </p>
  )}
  
  {managerName && (
    <>
      <p className="settings-text"><strong>Linked Manager:</strong> {managerName}</p>
      <button
        className="settings-danger-button"
        onClick={handleUnlinkFromManager}
        aria-label="Unlink from manager"
      >
        Unlink from Manager
      </button>
    </>
  )}
</div>

  
            {renderReportThreatButton()} {/* Render the report threat button conditionally */}
  
            {/* Pending Link Requests Section */}
            <div className="settings-card settings-section" role="region" aria-labelledby="pending-requests-heading">
              <h2 className="settings-subheading" id="pending-requests-heading">Pending Link Requests</h2>
              <div aria-live="polite">
                {linkRequests.length === 0 ? (
                  <p className="settings-text">No pending requests.</p>
                ) : (
                  linkRequests.map((request) => (
                    <div className="settings-card link-request-card" key={request.userId} role="region" aria-labelledby={`request-${request.userId}`}>
                      <p id={`request-${request.userId}`}><strong>Manager Email:</strong> {request.userEmail}</p>
                      <div className="settings-button-group">
                        <button
                          className="settings-button"
                          onClick={() => acceptLinkRequest(request)}
                          aria-label={`Accept link request from ${request.userEmail}`}
                        >
                          Accept
                        </button>
                        <button
                          className="settings-danger-button"
                          onClick={() => rejectLinkRequest(request)}
                          aria-label={`Reject link request from ${request.userEmail}`}
                        >
                          Reject
                        </button>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
  
             {/* Delete Account Section */}
        <div className="settings-card" role="region" aria-labelledby="delete-account-heading">
          <h2 className="settings-subheading" id="delete-account-heading">Delete Account</h2>
          <button
            className="settings-danger-button2"
            onClick={handleOpenModal}
            aria-label="Delete your account permanently"
          >
            Delete Account
          </button>

          <p className="settings-text">Free User accounts are deleted after 2 years of inactivity.</p>

        </div>

        {/* Confirmation Modal */}
        {isModalOpen && (
          <div className="modal-overlay">
            <div className="modal">
              <h3>Are you sure you want to delete your account?</h3>
              <div className="modal-buttons">
                <button className="confirm-button" onClick={handleConfirmDelete}>
                  Yes
                </button>
                <button className="cancel-button" onClick={handleCloseModal}>
                  No
                </button>
              </div>
            </div>
          </div>
          )}
  
            {/* Download Data Section */}
            <div className="settings-card" role="region" aria-labelledby="download-data-heading">
              <h2 className="settings-subheading" id="download-data-heading">Download Your Data</h2>
              <p className="settings-text">
                If you would like to request a copy of your personal data, please email
                <a href="mailto:contact@becyberaware.co.uk"> contact@becyberaware.co.uk</a>.
              </p>
            </div>
          </>
        )}
      </div>
    
    </>

  
  );
};

export default SettingsStandard;
