import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import './WhatIsCyberSecurity.css';

const SocialMediaSafetyQuiz = () => {
  const navigate = useNavigate();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState(Array(7).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [score, setScore] = useState(0);
  const [feedback, setFeedback] = useState(Array(7).fill(null));
  const [shuffledQuestions, setShuffledQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(Array(7).fill(null));
  const [user, setUser] = useState(null);
  const [authChecked, setAuthChecked] = useState(false);
  const [showStar, setShowStar] = useState(false);

  const questions = [
    {
      question: "What should you set your profile visibility to for better privacy?",
      options: [
        "Public",
        "Friends only",
        "Friends of friends",
      ],
      correctAnswer: "Friends only"
    },
    {
      question: "Is it a good idea to post your home address publicly on social media?",
      options: [
        "Yes, it's safe",
        "No, it can be risky",
        "Only if you trust your followers",
        "It's fine during the day"
      ],
      correctAnswer: "No, it can be risky"
    },
    
    {
      question: "Why should you update your privacy settings to hide personal details?",
      options: [
        "To reduce spam",
        "To protect your personal information",
        "To save storage space as it's limited",
        "To make new friends"
      ],
      correctAnswer: "To protect your personal information"
    },
    {
      question: "Should you accept friend requests from unknown people?",
      options: [
        "Yes, to make new friends as you want to be more popular",
        "It is advised not too without confirmation on who they are",
        "Only if they have mutual friends",
        "It's fine if they look trustworthy"
      ],
      correctAnswer: "It is advised not too without confirmation on who they are"
    },
    {
      question: "Is clicking on links in the comment section safe?",
      options: [
        "Yes, as it is on social media it is fine",
        "No, it can be risky",
        "Only if the comment is positive",
        "It's fine on verified accounts"
      ],
      correctAnswer: "No, it can be risky"
    },
    {
      question: "Why should you avoid sharing sensitive personal information publicly?",
      options: [
        
        "It can be exploited",
        "It looks unprofessional",
        "It takes up too much space"
      ],
      correctAnswer: "It can be exploited"
    },
    {
      question: "What should you do before posting on social media?",
      options: [
        "Think twice",
        "Post immediately",
        "Ask a friend",
        "Ignore the content"
      ],
      correctAnswer: "Think twice"
    },
    
  ];

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user || null); // ✅ Allow unauthenticated users to take the quiz
      setAuthChecked(true);
    });
  
    return () => unsubscribe();
  }, []);
  

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect = answer === questions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? 'correct' : 'incorrect';
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] = questions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  };

  const handleSubmit = async () => {
    let newScore = 0;
    answers.forEach((answer, index) => {
      if (answer === questions[index].correctAnswer) {
        newScore++;
      }
    });
    setScore(newScore);
    setShowResults(true);

    if (newScore >= 7) {
      if (user) {
        const updateProgress = async () => {
          try {
            const userDocRef = doc(firestore, 'users', user.uid);
            await updateDoc(userDocRef, {
              'progress.course1.module6': true,
            });
            setShowStar(true);
          } catch (error) {
            console.error('Error updating user profile:', error);
          }
        };
    
        updateProgress();
      }
    }
    
  };

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers(Array(7).fill(null));
    setFeedback(Array(7).fill(null));
    setCorrectAnswers(Array(7).fill(null));
    setShowResults(false);
    setShowStar(false);
    setScore(0);
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page7/email-security#EmailSecurity');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page6/social-media-safety#SocialMediaSafety');
  };

  if (!authChecked || shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      
      <br />
      {showStar && <div className="falling-star" aria-hidden="true"></div>}
      <section className="module-section2" role="region" aria-labelledby="quiz-title">
        <h2 id="quiz-title">Social Media Safety Quiz</h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={feedback[currentQuestionIndex] ? 'feedback' : undefined}
              >
                <legend className="quiz-legend">
                  {shuffledQuestions[currentQuestionIndex].question}
                </legend>
                {shuffledQuestions[currentQuestionIndex].options.map((option, index) => (
                  <div key={index} className="quiz-option">
                    <label className="quiz-option-label">
                      <input
                        type="radio"
                        name={`question-${currentQuestionIndex}`}
                        value={option}
                        checked={answers[currentQuestionIndex] === option}
                        onChange={() => handleAnswerChange(option)}
                        disabled={feedback[currentQuestionIndex] !== null}
                        aria-pressed={answers[currentQuestionIndex] === option}
                      />
                      <span className="option-text">{option}</span>
                    </label>
                  </div>
                ))}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === 'correct'
                    ? 'Correct!'
                    : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                </div>
              )}
              <div className="quiz-buttons">
                {currentQuestionIndex < shuffledQuestions.length - 1 ? (
                  <button
                    className="next-button"
                    onClick={handleNextQuestion}
                    aria-label="Go to the next question"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="submit-button"
                    onClick={handleSubmit}
                    aria-label="Submit your answers"
                  >
                    Submit
                  </button>
                )}
              </div>
            </>
          ) : (
            <div className="quiz-results" role="region" aria-labelledby="results-title">
              <h2 id="results-title">Results</h2>
              <p>Your score: {score} / 7</p>
              <br></br>
              {score >= 5 ? (
  <div className="completion-message">
    {showStar && <div className="falling-star" aria-hidden="true"></div>}
    {user ? (
      <p>
        🎉 Well done! You passed the quiz and learned about social media safety. Your progress has been saved.
      </p>
    ) : (
      <p>
        🎉 Well done! You have completed the quiz. To track your progress and save your results, 
        <strong> sign up for an account.</strong>
      </p>
    )}
    
  </div>
) : (
  <>
    <h2>Oops! You didn't pass the quiz.</h2>
    <p>You need at least 5 correct answers to pass.</p>
    <p>Your score: {score} out of {questions.length}</p>
    <button className="retake-button" onClick={handleRetake} aria-label="Retake the quiz">
      Retake Quiz
    </button>
  </>
)}

              <div className="quiz-buttons">
                {score >= 5 ? (
                  <button
                    className="next-button"
                    onClick={goToNext}
                    aria-label="Go to the next section"
                  >
                    Next
                  </button>
                ) : (
                  <button
                    className="retake-button"
                    onClick={handleRetake}
                    aria-label="Retake the quiz"
                  >
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default SocialMediaSafetyQuiz;
