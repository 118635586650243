import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase';
import '../ModuleOne/WhatIsCyberSecurity.css';

  const questions = [
    {
      question: "What is the primary objective of data protection?",
      options: [
        "To enhance system performance",
        "To prevent unauthorised access",
        "To increase data storage capacity",
        "To allow free access to all data"
      ],
      correctAnswer: "To prevent unauthorised access"
    },
    {
      question: "Which of the following is NOT a key principle of GDPR?",
      options: [
        "Lawfulness, Fairness, and Transparency",
        "Data Minimisation",
        "Purpose Limitation",
        "Unlimited Data Retention"
      ],
      correctAnswer: "Unlimited Data Retention"
    },
    {
      question: "What should be done with data that is no longer needed?",
      options: [
        "Stored indefinitely for future use as it may be needed again",
        "Sold to third-party vendors",
        "Securely destroyed according to retention policies",
        "Archived in unsecured locations"
      ],
      correctAnswer: "Securely destroyed according to retention policies"
    },
    {
      question: "What must businesses do before processing personal data?",
      options: [
        "Obtain explicit consent from individuals",
        "Share data with external partners",
        "Ensure data is stored in the cloud",
        "Allow open access to all employees"
      ],
      correctAnswer: "Obtain explicit consent from individuals"
    },
    {
      question: "What is a best practice for secure file sharing?",
      options: [
        "Use unencrypted email for convenience",
        "Utilise secure file transfer protocols (SFTP)",
        "Post files on public forums",
        "Send files via social media"
      ],
      correctAnswer: "Utilise secure file transfer protocols (SFTP)"
    },
    {
      question: "How long should data be retained?",
      options: [
        "As long as possible to ensure availability",
        "Only as long as necessary for its intended purpose",
        "Indefinitely, regardless of use",
        "Until storage space runs out"
      ],
      correctAnswer: "Only as long as necessary for its intended purpose"
    },
    {
      question: "Which of the following is a requirement under GDPR for businesses?",
      options: [
        "Ignore data breach notifications",
        "Report data breaches to the ICO within 72 hours",
        "Allow free access to all company data",
        "Delete all personal data upon collection"
      ],
      correctAnswer: "Report data breaches to the ICO within 72 hours"
    },
    {
      question: "What is the purpose of encryption in data protection?",
      options: [
        "To improve system speed",
        "To protect sensitive data",
        "To allow unauthorised access",
        "To make data easily shareable"
      ],
      correctAnswer: "To protect sensitive data"
    },
    {
      question: "What does the 'Integrity and Confidentiality' principle of GDPR require?",
      options: [
        "Data must be stored in plain text, this takes upless storage space",
        "All data should be accessible to all employees to ensure fairness and transparency within the organisation",
        "Data must be processed securely to protect against unauthorised access",
        "Data must be freely available online"
      ],
      correctAnswer: "Data must be processed securely to protect against unauthorised access"
    },
    {
      question: "What could be a consequence of non-compliance with GDPR?",
      options: [
        "Enhanced customer trust",
        "Significant fines",
        "Increased data sharing opportunities",
        "No legal repercussions"
      ],
      correctAnswer: "Significant fines"
    },
  ];

  const DataProtectionQuiz = () => {
    const navigate = useNavigate();
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState(Array(questions.length).fill(null));
    const [showResults, setShowResults] = useState(false);
    const [score, setScore] = useState(0);
    const [feedback, setFeedback] = useState(Array(questions.length).fill(null));
    const [shuffledQuestions, setShuffledQuestions] = useState([]);
    const [correctAnswers, setCorrectAnswers] = useState(Array(questions.length).fill(null));
    const [showStar, setShowStar] = useState(false);
    const auth = getAuth();

  useEffect(() => {
    const shuffleArray = (array) => {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
    };

    const shuffled = questions.map((question) => {
      const options = [...question.options];
      shuffleArray(options);
      return { ...question, options };
    });

    setShuffledQuestions(shuffled);
  }, []);

  const handleAnswerChange = (answer) => {
    if (feedback[currentQuestionIndex] === null) {
      const newAnswers = [...answers];
      newAnswers[currentQuestionIndex] = answer;
      setAnswers(newAnswers);

      const isCorrect =
        answer === shuffledQuestions[currentQuestionIndex].correctAnswer;
      const newFeedback = [...feedback];
      newFeedback[currentQuestionIndex] = isCorrect ? "correct" : "incorrect";
      setFeedback(newFeedback);

      if (!isCorrect) {
        const newCorrectAnswers = [...correctAnswers];
        newCorrectAnswers[currentQuestionIndex] =
          shuffledQuestions[currentQuestionIndex].correctAnswer;
        setCorrectAnswers(newCorrectAnswers);
      }
    }
  };

  const handleNextQuestion = () => {
  if (currentQuestionIndex < shuffledQuestions.length - 1) {
    setCurrentQuestionIndex(currentQuestionIndex + 1);
  } else {
    handleSubmit(); // If it's the last question, submit the quiz
  }
};

const handleSubmit = async () => {
  let newScore = answers.reduce((acc, answer, index) => {
    return answer === shuffledQuestions[index].correctAnswer ? acc + 1 : acc;
  }, 0);

  setScore(newScore);
  setShowResults(true);

  // User must score at least 8 to pass
  if (newScore >= 8) {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(firestore, "users", user.uid);
        await updateDoc(userDocRef, {
          "progress.course3.module6_quiz1": true,
        });
        setShowStar(true);
      }
    } catch (error) {
      console.error("Error updating user progress:", error);
    }
  }
};

  

  const handleRetake = () => {
    setCurrentQuestionIndex(0);
    setAnswers([null]);
    setFeedback([null]);
    setCorrectAnswers([null]);
    setShowResults(false);
    setScore(0);
    setShowStar(false);
  };

  const goToNext = () => {
    navigate("/businessacademy/modulesix/privacy#PrivacyHeading");
  };

  if (shuffledQuestions.length === 0) {
    return <div>Loading...</div>;
  }

  return (
    <div className="cybersecurity-basics-container">
      {showStar && <div className="falling-star" role="alert" aria-live="polite"></div>}
      <section
        className="module-section2"
        role="region"
        aria-labelledby="quiz-title"
      >
        <h2 id="quiz-title" tabIndex="0">
          Data Protection Quiz
        </h2>
        <div className="module-content2">
          {!showResults ? (
            <>
              <fieldset
                className="quiz-fieldset"
                aria-describedby={
                  feedback[currentQuestionIndex] ? "feedback" : undefined
                }
              >
                <legend
                  id={`question-${currentQuestionIndex}`}
                  tabIndex="0"
                  aria-live="polite"
                  aria-label={`Question ${
                    currentQuestionIndex + 1
                  }: ${shuffledQuestions[currentQuestionIndex]?.question}`}
                >
                  {shuffledQuestions[currentQuestionIndex]?.question}
                </legend>
                {shuffledQuestions[currentQuestionIndex]?.options.map(
                  (option, index) => (
                    <div key={index} className="quiz-option">
                      <label
                        className="quiz-option-label"
                        htmlFor={`option-${currentQuestionIndex}-${index}`}
                      >
                        <input
                          type="radio"
                          id={`option-${currentQuestionIndex}-${index}`}
                          name={`question-${currentQuestionIndex}`}
                          value={option}
                          checked={answers[currentQuestionIndex] === option}
                          onChange={() => handleAnswerChange(option)}
                          disabled={feedback[currentQuestionIndex] !== null}
                          aria-checked={
                            answers[currentQuestionIndex] === option
                          }
                        />
                        <span className="option-text">{option}</span>
                      </label>
                    </div>
                  )
                )}
              </fieldset>
              {feedback[currentQuestionIndex] && (
                <div
                  id="feedback"
                  className={`feedback ${feedback[currentQuestionIndex]}`}
                  role="alert"
                  aria-live="polite"
                >
                  {feedback[currentQuestionIndex] === "correct"
                    ? "Correct!"
                    : `Incorrect! The correct answer is: ${correctAnswers[currentQuestionIndex]}`}
                </div>
              )}
               <div className="quiz-buttons">
                {feedback[currentQuestionIndex] && ( // ✅ Only show the Next/Submit button if the user has answered
                currentQuestionIndex < shuffledQuestions.length - 1 ? (
                <button className="next-button" onClick={handleNextQuestion}>
                  Next Question
               </button>
               ) : (
                 <button className="submit-button" onClick={handleSubmit}>
                  Submit
               </button>
                )
               )}
              </div>

            </>
          ) : (
            <div className="quiz-results">
              <p>Your score: {score} / 10</p>
              <br></br>
              {score >= 8 ? (
                          <p>
                            🎉 Congratulations! You passed the quiz, you now have a solid
                            understanding of data protection.
                           </p>
                  ) : (
                           <p>❌ You need to score at least 8 to pass. Please retake the quiz.</p>
                 )}

              <div className="quiz-buttons">
                {score >= 1 ? (
                  <button className="next-button" onClick={goToNext}>
                    Next
                  </button>
                ) : (
                  <button className="retake-button" onClick={handleRetake}>
                    Retake Quiz
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default DataProtectionQuiz;
