import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './EmailSecurity.css';

const EmailSecurityTwo = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const emailSecurityRef = useRef(null);
  const [isAnswered, setIsAnswered] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);

  const handleAnswer = (answer) => {
    setIsAnswered(true);
    setIsCorrect(answer === 'illegitimate');
  };

  const goToNext = () => {
    navigate('/beginnerscourse/page7/email-security-three#EmailSecurity');
  };

  const goBack = () => {
    navigate('/beginnerscourse/page7/email-security#EmailSecurity');
  };

  useEffect(() => {
    if (location.hash === '#EmailSecurity' && emailSecurityRef.current) {
      emailSecurityRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [location.hash]);

  return (
    <div className="introduction-container2">
      <button
        className="back-arrow-button"
        onClick={goBack}
        aria-label="Go back to the previous Email Security page"
      >
        ←
      </button>

      <section className="module-section2">
        <section
          className="module-section"
          style={{ textAlign: 'center' }}
          ref={emailSecurityRef}
        >
          
        </section>

        <div className="module-content2">
        <h1 id="EmailSecurity">Email Security</h1>
          <div className="bordered-container2">
            <div className="task-instruction">
              <p>
                <strong>
                  <span style={{ color: 'black' }}>
                    Your task is to decide if each email is legitimate or illegitimate.
                  </span>
                </strong>
              </p>
            </div>
          </div>
        </div>

        <div className="email-example-box">
          <h3>Example Email One</h3>
          <div className="email-content">
            <p>
              <strong>From:</strong> support@amaz0n.com
            </p>
            <p>
              <strong>Subject:</strong> Urgent: Update Your Payment Information
            </p>
            <p>Dear Customer,</p>
            <p>
              We have noticed unusual activity on your account. Please{' '}
              <strong>
                <span
                  className="tooltip-link"
                  aria-describedby="tooltip-link"
                  role="button"
                  tabIndex={0}
                >
                  <span style={{ color: 'blue' }}>Click Here</span>
                </span>
              </strong>{' '}
              to update your payment information immediately to avoid any
              interruptions in your service.
            </p>
            <p>Thank you for your prompt attention to this matter.</p>
            <p>Sincerely,</p>
            <p>Amazon Support Team</p>
          </div>

          <div className="answer-buttons">
            <button
              onClick={() => handleAnswer('legitimate')}
              aria-label="Select if the email is legitimate"
            >
              Legitimate
            </button>
            <button
              onClick={() => handleAnswer('illegitimate')}
              aria-label="Select if the email is illegitimate"
            >
              Illegitimate
            </button>
          </div>

          {isAnswered && (
            <div
              className={`feedback ${isCorrect ? 'correct' : 'incorrect'}`}
              role="alert"
              aria-live="assertive"
            >
              {isCorrect ? 'Well done!' : 'Wrong answer.'}
            </div>
          )}
        </div>

        {isAnswered && (
          <div className="phishing-signs">
            <h4>Signs of a Phishing Attempt:</h4>
            <br></br>
            <ul>
              <li>
                <strong>Email Address:</strong> The sender's email address
                (support@amaz0n.com) contains a slight misspelling.
              </li>
              <br></br>
              <li>
                <strong>Urgency:</strong> The email creates a sense of urgency,
                pressuring you to act quickly.
              </li>
              <br></br>
              <li>
                <strong>Suspicious Link:</strong> The link provided does not lead to the official Amazon website.
              </li>
              <br></br>
              <li>
                <strong>Generic Greeting:</strong> The email uses a generic greeting like "Dear Customer" instead of your name.
              </li>
            </ul>
          </div>
        )}

        <button
          className="next-button"
          onClick={goToNext}
          aria-label="Proceed to the next Email Security page"
        >
          Next
        </button>
      </section>
    </div>
  );
};

export default EmailSecurityTwo;
