import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { firestore } from '../../firebase'; // Assuming your firebase instance is correctly configured here
import './CookiePolicy.css';
import Footer from '../Footer/Footer';

const CookiePolicy = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [consent, setConsent] = useState({
    necessary: true,
    functional: true,
    analytics: true,
    marketing: true,
  });

  const [user, setUser] = useState(null);
  const auth = getAuth();
  const location = useLocation();

  // Scroll to the Cookie Policy section based on URL hash
  useEffect(() => {
    if (location.hash === '#CookiePolicyHeading') {
      const element = document.querySelector('.cookie-policy-container h1');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location.hash]);

  // Fetch user consent from Firestore when the modal opens
  useEffect(() => {
    const fetchUserConsent = async () => {
      const currentUser = auth.currentUser;
      if (currentUser) {
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          if (userData.consent) {
            setConsent(userData.consent); // Set the user's consent preferences
          }
          setUser(currentUser);
        }
      }
    };

    if (isModalOpen) {
      fetchUserConsent();
    }
  }, [isModalOpen, auth]);

  const openCookiePreferenceModal = () => {
    setIsModalOpen(true);
  };

  const closeCookiePreferenceModal = () => {
    setIsModalOpen(false);
  };

  // Update consent history when user confirms changes, including timestamp
  const handleConfirmPreferences = async () => {
    if (user) {
      const timestamp = Date.now(); // Use current timestamp as key for consentHistory
      const consentDate = new Date().toLocaleString(); // Get readable date and time

      const userDocRef = doc(firestore, 'users', user.uid);
      await updateDoc(userDocRef, {
        [`consentHistory.${timestamp}`]: {
          ...consent,
          dateGiven: consentDate // Include the date when consent was given
        }
      });

      setIsModalOpen(false);
    }
  };

  const handleCheckboxChange = (category) => {
    setConsent((prevConsent) => ({
      ...prevConsent,
      [category]: !prevConsent[category],
    }));
  };

  return (
    <>
      <div className="cookie-policy-container">
        <h1>Cookie Policy</h1>

        <p>
          Welcome to Be Cyber Secure's Cookie Policy. This page explains what cookies are, the types of cookies we use, and how you can manage your cookie preferences.
        </p>

        <h2>What Are Cookies?</h2>
        <p>
          Cookies are small text files that are stored on your device (computer, smartphone, or other electronic devices) when you visit our website. Cookies help us understand how you use our website and provide you with a better user experience.
        </p>
        <br></br>

        <h2>Types of Cookies We Use</h2>
        <br></br>

        <h3>Essential Cookies</h3>
        <p>
          These cookies are necessary for our website to function and cannot be switched off. They are usually set in response to actions made by you, such as logging in or filling out forms.
        </p>
        <p>
          <strong>Examples:</strong> Session management, login functionality, security cookies.
        </p>

        <h3>Performance Cookies</h3>
        <p>
          These cookies help us measure how often you visit our website and how you use it. They provide information about which pages are visited most often and detect issues like page load errors.
        </p>
        <p>
          <strong>Examples:</strong> Google Analytics.
        </p>

        <h3>Functionality Cookies</h3>
        <p>
          These cookies enable our website to remember choices you make and provide enhanced features. For example, they allow us to remember your preferences such as language or theme settings.
        </p>
        <p>
          <strong>Examples:</strong> Language settings, theme settings.
        </p>

        <h3>Targeting/Marketing Cookies</h3>
        <p>
          These cookies are used to deliver advertisements that are more relevant to you and your interests. They may also be used to track the effectiveness of advertising campaigns.
        </p>
        <p>
          <strong>Examples:</strong> Facebook Pixel, Google Ads.
        </p>

        <h3>Third-Party Cookies</h3>
        <p>
          Our website may contain content or links to other websites and services, which may result in cookies being placed by third-party services. For example, embedded videos or social media sharing buttons.
        </p>
        <p>
          <strong>Examples:</strong> YouTube, Twitter, LinkedIn.
        </p>

        <h2>Managing Cookies</h2>
        <p>
          You have the right to choose whether to accept or reject cookies. Most web browsers allow control over cookies through their settings. However, if you reject cookies, some features or services on our website may not function properly.
        </p>
        <ul>
          <li><strong>Browser Settings:</strong> You can configure your browser to block cookies or alert you when cookies are being set.</li>
          <li><strong>Consent Banner:</strong> On your first visit to our website, a consent banner will appear, allowing you to select which types of cookies you wish to accept.</li>
          <li><strong>Changing Your Preferences:</strong> You can change your cookie preferences at any time by 
            <button className="link-button" onClick={openCookiePreferenceModal}>
              clicking here
            </button>.
          </li>
        </ul>

        <h2>Contact Us</h2>
        <p>
          If you have any questions about our use of cookies, please contact us at contact@be-cyber-secure.com.
        </p>
      </div>

      {/* Modal for customizing cookie preferences */}
      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Customize Cookie Preferences</h3>
            <p>You can select which cookies to enable or disable.</p>
            <ul>
              <li>
                <label>
                  <input type="checkbox" checked={consent.necessary} readOnly /> Necessary (Always Enabled)
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="checkbox"
                    checked={consent.functional}
                    onChange={() => handleCheckboxChange('functional')}
                  /> Functional Cookies
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="checkbox"
                    checked={consent.analytics}
                    onChange={() => handleCheckboxChange('analytics')}
                  /> Performance Cookies
                </label>
              </li>
              <li>
                <label>
                  <input
                    type="checkbox"
                    checked={consent.marketing}
                    onChange={() => handleCheckboxChange('marketing')}
                  /> Targeting/Marketing Cookies
                </label>
              </li>
            </ul>

            <div className="modal-buttons">
              <button onClick={handleConfirmPreferences}>Confirm</button>
              <button onClick={closeCookiePreferenceModal}>Close</button>
            </div>
          </div>
        </div>
      )}

      <Footer />
    </>
  );
};

export default CookiePolicy;
